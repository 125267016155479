<template>
  <!--  about program Start -->
  <section id="program">

      <div class="accelerator-landing-about accelerator-landing-about--grey accelerator-landing-about--landing-page">

        <div class="glue-page">
          <div class="accelerator-landing-about--title">
            <p><span class="decorated-text underline">About the program</span></p>
            <div class="line-title-center-white"></div>
          </div>
          <div class="container glue-grid">
            <div class="glue-grid__col glue-grid__col--span-6
                  glue-mod-order-3 glue-mod-order-1-sm
                  accelerator-landing-about--flipped-content">
              <div class="accelerator-landing-about--heading mobile-show">
                <p><span class="decorated-text underline">About</span> the program</p>
              </div>
              <div class="accelerator-landing-about--image-container overlap-image-container glue-grid ">
                <div class="overlap-image-bottom floater" data-speed="0.15" style="transform: translate3d(0px, -10px, 0px);">
                  <img src="@/assets/images/Accelerator_About_D.jpg" class="image-overlap-bottom-image" alt="About section image-1">
                </div>
                <div class="overlap-image-top floater" data-speed="0.1" style="transform: translate3d(0px, 0px, 0px);">
                  <img src="@/assets/images/GFSSummit2020.png" class="image-overlap-top-image" alt="About section image-2">
                </div>

              </div>
            </div>
            <div class="glue-grid__col glue-grid__col--span-1 glue-grid__col--span-0-md
                  glue-mod-order-2">
            </div>
            <div class="glue-grid__col glue-grid__col--span-5 glue-grid__col--span-6-md
                  glue-mod-order-1 glue-mod-order-2-sm">
              <div class="accelerator-landing-about--body ">
                <h2>A global series of programs</h2>
                <p>Each accelerator features a cohort of 10-15 top startups that come together to tackle specific technical challenges that can help grow their businesses through a mix of remote and in-person, 1-to-1, group learning sessions, and sprint projects.</p>
                <h2>Tailored, expert help</h2>
                <p>Founders outline the top technical challenges for their startup, and are then paired with relevant experts from both Google and the industry to solve those challenges and grow their business.</p>
                <h2>Specialist deep dives</h2>
                <p>In addition to mentorship and technical project support, Google for Startups accelerators include deep dives and workshops focused on product design, customer acquisition, and leadership development for founders.</p>
              </div>
              <div class="cta-link-wrapper glue-mod-spacer-5-top">
                <a href="#" class="smooth-scroll-link glue-button
                        glue-button--medium-emphasis glue-button--reversed" data-smooth-scroll-id="#accelerators" aria-label="Find out more" role="button" data-category="cta" data-action="more stories" data-label="body">
                  Find an Accelerator
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


  </section>
  <!--  about program End -->

</template>

<script>
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "ProgramApp",
 mounted() {
   /*gsap.to(".floater", {
     duration: 1.5,
     x: 100,
   });*/

  // gsap.registerPlugin(ScrollTrigger);

   gsap.set(".overlap-image-bottom", {});

   gsap.utils.toArray("[data-speed]").forEach(el => {

     gsap.to(el, {
       scrollTrigger: {
         trigger:el,
         scrub: true,
         start: 'top center',
       },
       duration: 2,
       y: 70,
       stagger: 0.15,
       animation:"easeInOut"
     });


     gsap.to(el, {
       scrollTrigger: {
         trigger:el,
         scrub: true,
         start: 'bottom center',
       },
       duration: 2,
       y: -70,
       stagger: 0.15,
       animation:"easeInOut"
     });

   });

   gsap.set(".overlap-image-top", {});

   gsap.utils.toArray("[data-speed]").forEach(el => {

     gsap.to(el, {
       scrollTrigger: {
         trigger:el,
         scrub: true,
         start: 'top center',
       },
       duration: 1.2,
       y: 40,
       stagger: 0.15,
       animation:"easeInOut"
     });


     gsap.to(el, {
       scrollTrigger: {
         trigger:el,
         scrub: true,
         start: 'bottom center',
       },
       duration: 1.2,

       y: -40,
       stagger: 0.15,
       animation:"easeInOut"
     });

   });

 }
}
</script>

<style scoped>
.line-title-center-white{
  width: 80px;
  height: 2px;
  background-color: white;
  margin: 15px calc(50% - 40px) 10px;
}
</style>