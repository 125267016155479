<template>
  <!-- ***** Team Area Section Start ***** -->
  <section id="our_team">
    <div class="container">
      <div class="row" data-aos="fade-up">
        <div class="col-md-12">
          <!-- Heading Text  -->
          <div class="section-heading text-center">
            <h2>Meet Our Team</h2>
            <div class="line-title-center"></div>
          </div>
        </div>
      </div>

      <div class="row text-center">
        <div class="col-lg-3 col-md-6 col-12 wow rollIn ">
        <div class="team-member">
          <div class="team-box">
            <img src="@/assets/images/our-team/team-1.jpg" alt="img">
            <div class="team-overlay">
              <div class="team-member-content">
                <ul>
                  <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                  <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="team-box-name">
            <h4>Johan Smit</h4>
            <h6>Designer</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 wow rollIn ">
      <div class="team-member">
        <div class="team-box">
          <img src="@/assets/images/our-team/team-3.jpg" alt="img">
          <div class="team-overlay">
            <div class="team-member-content">
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="team-box-name">
          <h4>Johan Smit</h4>
          <h6>Designer</h6>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-12 wow rollIn ">
    <div class="team-member">
      <div class="team-box">
        <img src="@/assets/images/our-team/team-2.jpg" alt="img">
        <div class="team-overlay">
          <div class="team-member-content">
            <ul>
              <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
              <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="team-box-name">
        <h4>Johan Smit</h4>
        <h6>Designer</h6>
      </div>
    </div>
    </div>
    <div class="col-lg-3 col-md-6 col-12 wow rollIn ">
    <div class="team-member">
      <div class="team-box">
        <img src="@/assets/images/our-team/team-3.jpg" alt="img">
        <div class="team-overlay">
          <div class="team-member-content">
            <ul>
              <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
              <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="team-box-name">
        <h4>Johan Smit</h4>
        <h6>Designer</h6>
      </div>
    </div>
    </div>
    </div>
    </div>
  </section>
  <!-- ***** Team Area Section End ***** -->
</template>

<script>
export default {
  name: "TeamApp"
}
</script>

<style scoped>

.team-member {
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #ececec;
  border-radius: 2px;
}


.team-box img {
  display: block;
  width: 100%;
  height: auto;
}

.team-overlay {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
}

.team-member-content {
  white-space: nowrap;
  color: #fff;
  position: absolute;
  overflow: hidden;
  top: 70%;
  left: 50%;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

.team-member-content ul li {
  margin-bottom: 5px;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
}

.team-member-content ul li:hover {
  margin-right: 7px;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  background-color: #fff;

}

.team-member-content ul li a i {
  height: 40px;
  font-size: 13px;
  text-align: center;
  color: #fff;
}

.team-member-content ul li:hover i{
  color: #41c9a2;
}

.team-box-name {
  padding: 18px 0;
}

.blog-box, .team-box-name {
  position: relative;
  overflow: hidden;
}

.team-box-name h4 {
  /* color: #333; */
  font-size: 20px;
  font-weight: 500;
}

.team-box-name h6 {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 3px;
}

.team-box:hover .team-overlay {
  opacity: 0.7;
  background: -webkit-linear-gradient(0deg, #43cea2 0%, #185a9d 100%);


}

.team-member:hover .team-box-name{
  color: white;
  opacity: 0.7;
}
.team-member:hover .team-overlay{
  opacity: 0.7;
  background: -webkit-linear-gradient(0deg, #43cea2 0%, #185a9d 100%);
}
#our_team{
  background-color: var(--section-color);
}

</style>