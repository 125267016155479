<template>
  <ContactFooter/>
</template>

<script>
import ContactFooter from "@/components/sections/ContactFooter";
export default {
  name: "TechnicalView",
  components: {ContactFooter}
}
</script>

<style scoped>

</style>