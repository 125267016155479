<template>

  <section>
    <div class="layer1">
  <div class="container ">
      <p data-aos="fade-up" class="pl-2 pr-2 pb-3">We are different by design</p>
      <h2 data-aos="fade-up" class="pl-2 pr-2  pb-3">Think big. Explore broadly. Collaborate constantly.</h2>
      <p class="bef" data-aos="fade-up">Our mission is said simply, yet it is quite ambitious.
        We want to better understand the biology that controls aging and lifespan…and we want to use the knowledge we gain to discover and develop interventions that enable people to live longer and healthier lives.</p>
      <p  data-aos="fade-up"  class="bef pl-2 pr-2  pb-3">We are asking difficult questions about how we age and the diseases associated with the aging process. To find answers requires a long-term perspective, a relentless focus on understanding the basic biology of aging, utilizing or inventing state-of-the-art technology, enlisting advanced computing capabilities and nurturing a culture and values that guide the big and small decisions we make every day.</p>
    </div>
    </div>


    <div class="layer2">

      <div class="layer3" data-aos="fade-up">

        <nav id="chapternav" class="chapternav mb-2" >
          <div class="chapternav-wrapper">
            <ul class="chapternav-items">
              <li id="item1"  class="chapternav-item">
                <router-link to="/iter">
                  <a v-bind:class="this.$route.name ==='iter' ?  'chapternav-link current' : 'chapternav-link' " >
                    <div class="box" >
                      <div class="box-overlay"></div>
                      <img src="@/assets/images/our-team/team-2.jpg" alt="img">

                      <div class="box-text">
                        <hr class="myHr"/>
                        <h4>ITER</h4>
                        <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque.</span>
                      </div>

                    </div>

                  </a>
                </router-link>
              </li>

              <li id="item2"  class="chapternav-item">
                <router-link  to="/iter_student">
                  <a v-bind:class=" this.$route.name ==='iter_student' ?  'chapternav-link current' : 'chapternav-link'">
                    <div class="box" >
                      <div class="box-overlay"></div>
                      <img src="@/assets/images/our-team/team-2.jpg" alt="img">

                      <div class="box-text">
                        <hr class="myHr"/>
                        <h4>ITER</h4>
                        <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque.</span>
                      </div>

                    </div>
                  </a>
                </router-link>
              </li>

              <li id="item3"   class="chapternav-item position-relative">
                <router-link to="/iter_teacher">
                  <a v-bind:class=" this.$route.name ==='iter_teacher' ?  'chapternav-link current' : 'chapternav-link'" >
                    <div class="box" >
                      <div class="box-overlay"></div>
                      <img src="@/assets/images/our-team/team-2.jpg" alt="img">

                      <div class="box-text">
                        <hr class="myHr"/>
                        <h4>ITER</h4>
                        <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque.</span>
                      </div>

                    </div>
                  </a>
                </router-link>
              </li>


              <!-- End Items-->

            </ul>

          </div>
        </nav>

      </div>

  <div class="container">
      <p class="pl-2 pr-2  pb-3" data-aos="fade-up">How we work</p>
      <h2 class="pl-2 pr-2  pb-3" data-aos="fade-up">Science. Freedom. Community.</h2>
      <p class="pl-2 pr-2  pb-3" data-aos="fade-up">
        We are curious by nature. We love science and have the sense of wonder that is required to succeed in an uncharted area such as aging. We’re excited and humbled by the opportunity.
        </p>
      <p class="pl-2 pr-2  pb-3" data-aos="fade-up">
        We are deliberate about creating a community in which Calico scientists and engineers have the freedom to explore, take chances and follow their own scientific pursuits and passions, wherever they may lead. We focus intensely on interdisciplinary collaboration within Calico, and with our external partners, because we believe that breakthroughs occur when curious people work together to solve what others believe are intractable challenges.
      </p>
      </div>
    </div>

  </section>

</template>

<script>
export default {
  name: "ThinkBigApp"
}
</script>

<style scoped>
.layer1{
  background: var(--section-color);
  padding-top: 50px;
  padding-bottom: 230px;
  padding-left: 30%;
  padding-right: 30%;
}
.container:first-child {
  color: var(--main-color3);
}
.container > h2 {
  color: var(--main-color2);
}

.layer1 .bef {

  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--main-color2);
}

.layer1 .bef:before {
  content: '';
  border-inline-start: 2px solid #212121;
  height: 100%;
  position: absolute;
  left: -10px;
  right: -10px;
}




.layer2{
  background: var(--section-color2);
  padding-top: 300px;
  padding-bottom: 50px;
  padding-left: 30%;
  padding-right: 30%;
  position: relative;
}

.layer3{
  position: absolute;
  top: -150px;
  left: 0;
  right: 0;
  width: 100%;

}


.myHr{
  background-color: white;
  width: 40px;
  height: 1.5px;
}




@media (max-width:1200px) {
  .layer1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .layer2 {
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
  }




}



.chapternav {
  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 100%;
  height: 360px;
  z-index: 1;
  overflow-y: hidden;
  -ms-overflow-style: none;


}

.chapternav-wrapper {
  position: relative;
  height: 100%;
  z-index: 1;
  width: 100%;
  overflow-y: hidden;
  -ms-overflow-style: none;
}

.chapternav-items {
  list-style: none;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  color: white;
}

.chapternav-item {
  height: auto;
  display: inline-block;
  vertical-align: top;
  margin: 0 10px;
  padding: 0 0px;
  cursor: pointer;
  white-space: normal;

}
.chapternav-item .box{
  position: relative;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
}


.chapternav-item .box .box-overlay{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  z-index:5 ;
}

.chapternav-item a :hover .box-overlay{
  background-color: black;
  opacity: 30%;

}


.chapternav-item .box-text{
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  justify-content: flex-end;
  padding: 20px 45px;
  align-content: flex-start;
  color: white;
  z-index:10 ;

}
.chapternav-item .box h4{
  text-align: start;
  font-size: 18px;
}
.chapternav-item .box span{
  text-align: start;
  font-size: 14px;
}



.chapternav-item:first-child {
  margin: 0 10px;
  padding: 0 0px;
}

.chapternav-item:last-child {
  margin: 0 10px;
  padding: 0 0px;
}
.chapternav-item img{
  width: 250px;
  height: 350px;
}



</style>