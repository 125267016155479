<template>
  <!-- ***** Client Testimonial Area Start ***** -->

  <section class="clients_testimonial_area" id="testimonial">
    <div class="container">

      <div class="row">
        <div class="col-12">

          <div class="section-heading text-center" data-aos="fade-up">
            <h2>Testimonials</h2>
            <div class="line-title-center"></div>
            <div class="client-testimonial-text mt-4 text-center">
              <div class="client">
                <i class="fa fa-quote-left" aria-hidden="true"></i>
              </div>
              <div class="client-description text-center">
                <p>“ Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. ”</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div class="row justify-content-center">

        <div class="col-12 col-md-10">

          <swiper
              :spaceBetween="30"
              :centeredSlides="true"
              :loop="true"
              :speed="1000"
              :autoplay="{
                  delay: 2500,
                 disableOnInteraction: false,
               }"
              :pagination="{
      clickable: true,
    }"

              :modules="modules"
              class="mySwiper_client"
          >


            <swiper-slide>
              <div class="client-testimonial-text text-center">
                <div class="star-icon text-center">
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                </div>
                <div class="client-name text-center">
                  <h5>Aigars Silkalns</h5>
                  <p>Ceo Colorlib</p>
                </div>
                <div class="client-thumbnail">
                  <img src="@/assets/images/bg-img/testimonial.png" alt="Image">
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="client-testimonial-text text-center">
                <div class="star-icon text-center">
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                </div>
                <div class="client-name text-center">
                  <h5>Aigars Silkalns</h5>
                  <p>Ceo Colorlib</p>
                </div>
                <div class="client-thumbnail">
                  <img src="@/assets/images/bg-img/testimonial.png" alt="Image">
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="client-testimonial-text text-center">
                <div class="star-icon text-center">
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                </div>
                <div class="client-name text-center">
                  <h5>Aigars Silkalns</h5>
                  <p>Ceo Colorlib</p>
                </div>
                <div class="client-thumbnail">
                  <img src="@/assets/images/bg-img/testimonial.png" alt="Image">
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="client-testimonial-text text-center">
                <div class="star-icon text-center">
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                  <i class="ion-ios-star"></i>
                </div>
                <div class="client-name text-center">
                  <h5>Aigars Silkalns</h5>
                  <p>Ceo Colorlib</p>
                </div>
                <div class="client-thumbnail">
                  <img src="@/assets/images/bg-img/testimonial.png" alt="Image">
                </div>
              </div>
            </swiper-slide>

          </swiper>



        </div>

      </div>


    </div>
  </section>



  <!-- ***** Client testimonial Area End ***** -->

</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination,Autoplay, } from "swiper";


export default {
  name: "ClientApp",

  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      Pagination: {
        clickable: true
      },
      modules: [Pagination,Autoplay],
    };
  },

}
</script>

<style scoped>
.clients_testimonial_area {
background-color: var(--section-color2);
}
.clients_testimonial_area .client-name > h5 {
  color: #41c9a2;
  font-size: 22px;
  margin-top: 5px;
}

.clients_testimonial_area .star-icon > i {
  font-size: 18px;
  color: #f1b922;
}

.clients_testimonial_area .client-description > p {
  max-width: 1010px;
  margin: auto;
  margin-bottom: 15px;
  font-size: 16px;
}

.clients_testimonial_area .client > i {
  font-size: 36px;
  color: #41c9a2;
  margin-bottom: 30px;
}

.clients_testimonial_area .client-name > p {
  color: #b5aec4;
}

.clients_testimonial_area ul>li{
  color: #f8f9fa;
}

.clients_testimonial_area .slick-dots {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.clients_testimonial_area .slick-dots li button {
  width: 12px;
  height: 12px;
  text-indent: -999999999999999999999999px;
  border: 3px solid #a49fba;
  background-color: #fff;
  border-radius: 50%;
  margin: 5.5px;
  outline: none;
}

.clients_testimonial_area .slick-dots li.slick-active button {
  background-color: #41c9a2;
  border-color: #41c9a2;
}

.clients_testimonial_area .client-thumbnail {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 160px;
  position: relative;
}


.clients_testimonial_area  img {
  display: block;
  width: 80px !important;
  height: 80px !important;
  border-radius: 50%;
  border: 5px solid #fff;
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

}

.clients_testimonial_area .slick-slide.slick-current img {
  width: 100px !important;
  height: 100px !important;
}

.clients_testimonial_area .slick-arrow {
  position: absolute;
  width: 30px;
  background-color: #f8f9fa;
  height: 100%;
  text-align: center;
  font-size: 24px;
  color: #b5aec4;
  display: block;
  line-height: 550%;
  left: 0;
  z-index: 90;
  cursor: pointer;
}

.clients_testimonial_area .slick-arrow.fa-angle-right {
  left: auto;
  right: 0;
  top: 0;
}
</style>