<template>
<div class="contact-view">
<!--  <ContactApp/>-->
  <ContactFooter/>
</div>
</template>

<script>
/*import ContactApp from "@/components/sections/Contact";*/
import ContactFooter from "@/components/sections/ContactFooter";
export default {
  name: "ContactView",
  components: {ContactFooter/*, ContactApp*/}
}
</script>

<style scoped>

</style>