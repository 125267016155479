<template>

  <!-- solutions Start-->
  <section id="solutions">
    <div class="container">
      <div class="row justify-content-center text-center" data-aos="fade-up">
        <div class="col-12">
          <!-- Heading Text -->
          <div class="section-heading text-center">
            <h2>We resolve effciently business challenges by building unique tech solutions</h2>
            <div class="line-title-center"></div>
          </div>
        </div>
      </div>

      <div class="row d-lg-flex justify-content-lg-center align-items-lg-center"  data-aos="fade-up">

        <div class="col-md-6 d-flex d-sm-flex justify-content-center justify-content-md-end " >
          <div class="card myCard">
            <div class="card-body">
              <div class="d-table-row"><i class="icon ion-android-desktop d-inline-block"></i>
                <h4 class="d-table-cell">Website Design and Development</h4>
              </div>
              <p class="card-text">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
            </div>
          </div>
        </div>

        <div class="col-md-6 d-flex justify-content-center justify-content-md-start" data-aos="fade-up">
          <div class="card myCard">
            <div class="card-body">
              <div class="d-table-row"><i class="fas fa-code d-inline-block"></i>
                <h4 class="d-table-cell">Custom Software and Applications Programming<br></h4>
              </div>
              <p class="card-text">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
            </div>
          </div>
        </div>

        <div class="col-md-6 d-flex justify-content-center justify-content-md-end" data-aos="fade-up">
          <div class="card myCard">
            <div class="card-body">
              <div class="d-table-row"><i class="fas fa-database d-inline-block"></i>
                <h4 class="d-table-cell">Database&nbsp;Design and Maintenance<br></h4>
              </div>
              <p class="card-text">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
            </div>
          </div>
        </div>

        <div class="col-md-6 d-flex justify-content-center justify-content-md-start" data-aos="fade-up">
          <div class="card myCard">
            <div class="card-body">
              <div class="d-table-row"><i class="fas fa-server d-inline-block"></i>
                <h4 class="d-table-cell">Server and Network Administration<br></h4>
              </div>
              <p class="card-text">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- solutions End-->

</template>

<script>



export default {
  name: "SolutionsApp",

}
</script>

<style scoped>


/* solutions */

#solutions{
  background: var(--section-color2);
}


.card.myCard {
  /*box-shadow: 0px 0px 70px rgba(207,207,207,0.1);*/
  -webkit-box-shadow: 0 20px 45px rgba(0,0,0,0.09);
  box-shadow:0 20px 45px rgba(0,0,0,0.09);
  border: none;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
  min-height: 260px;
  max-width: 512px;
  width: 70%;
  background-color: var(--section-color);
}

@media (max-width:820px) {
  .card.myCard {
    -webkit-box-shadow: 0 20px 45px rgba(0,0,0,0.09);
    box-shadow:0 20px 45px rgba(0,0,0,0.09);
    border: none;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 5px;
    width: 100%;
    min-height: 300px;
  }
}

@media (max-width:820px) {
  .myCard h4 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 16px;
  }
}

@media (max-width:820px) {
  .myCard p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.myCard i {
  color: var(--main-color);
  margin-right: 10px;
  margin-left: 10px;
  background-color: #00c9a433;
  padding: 5px;
  border-radius: 5px;
  font-size: 24px;
}

.myCard p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}

</style>