<template>

  <!-- ***** footer-Area-start ***** -->
  <footer id="footer" class="footer-dark">
    <div class="container">
      <div class="row" >

        <div class="col-md-4 col-xxl-4 text mx-auto">
          <a href="/"> <h2 id="ezarTitle"  data-aos="fade-up">{{ $t('title_ezar') }}</h2></a>
          <p class="text-start"  data-aos="fade-up">The pain itself is the love of the main, the adipisic Receive the pwned enhanced, accepted they take care of their necessities by reason, and when they are chosen for the advantageous flight of the happy present. complete or similar he hates to open up to the pains and hardships of the body.<br></p>
          <div>
            <div class="col  item social"  data-aos="fade-up">
              <a data-bss-hover-animate="pulse" href="#"><i class="icon ion-social-facebook"></i></a>
              <a data-bss-hover-animate="pulse" href="#"><i class="fab fa-linkedin"></i></a>
              <a data-bss-hover-animate="pulse" href="#"><i class="icon ion-social-twitter"></i></a>
              <a data-bss-hover-animate="pulse" href="#"><i class="icon ion-social-youtube"></i></a>
              <a data-bss-hover-animate="pulse" href="#"><i class="fas fa-envelope"></i></a>
            </div>
          </div>
        </div>

        <div class="col text-start item mx-auto">
          <h2  data-aos="fade-up">About EZAR</h2>
          <ul>
            <li  data-aos="fade-up"><a href="/news">News</a></li>
            <li  data-aos="fade-up"><a href="/jobs">Job</a></li>
            <li  data-aos="fade-up"><a href="/events">Events</a></li>
            <li  data-aos="fade-up"><a href="/agents">Agents</a></li>
            <li  data-aos="fade-up"><a href="/branches">Branches</a></li>
          </ul>
        </div>

        <div class="col-sm-6 col-md-2 text-start item mx-auto">
          <h2  data-aos="fade-up">Products</h2>
          <ul>

            <li  data-aos="fade-up"><a href="/iter">ITER</a></li>
            <li  data-aos="fade-up"><a href="/iter_learning">ITER LEARNING</a></li>

            <li  data-aos="fade-up"><a href="/engz">ENGZ</a></li>

            <li  data-aos="fade-up"><a href="/b2bstore">B2B STORE</a></li>

          </ul>
        </div>

        <div class="col-sm-6 col-md-2 text-start item mx-auto">
          <h2  data-aos="fade-up">Business</h2>
          <ul>
            <li  data-aos="fade-up"><a href="#">Company 1</a></li>
            <li  data-aos="fade-up"><a href="#">Company 2</a></li>
            <li  data-aos="fade-up"><a href="#">Link</a></li>
            <li  data-aos="fade-up"><a href="#">Company contact</a></li>
          </ul>
        </div>

        <div class="col-sm-6 col-md-2 text-start item mx-auto">
          <h2  data-aos="fade-up">Communication</h2>
          <ul>
            <li  data-aos="fade-up"><a href="/technical">Technical support</a></li>
            <li  data-aos="fade-up"><a href="/questions">Common questions</a></li>
            <li  data-aos="fade-up"><a href="/contact">Contact Us</a></li>
            <li  data-aos="fade-up"> <a href="whatsapp://send?phone=+9647838397779">Whatsapp sell</a> </li>
            <li  data-aos="fade-up"> <a href="whatsapp://send?phone=+9647838397779">Whatsapp support</a> </li>
          </ul>
        </div>
      </div>

      <div class="d-grid d-sm-flex justify-content-md-center justify-content-xl-center pt-5"  >
        <p class="copyright">Copyright © {{ year }} All rights reserved |&nbsp;
           <a href="/">{{ $t('title_ezar') }}</a>&nbsp;|</p>

        <div class="dropup dropdown-language d-flex justify-content-center ">

          <a href="#" class="dropdown-toggle  btn-sm " data-toggle="dropdown">
            <i class="fa fa-language"></i> &nbsp;{{ $t("language") }}</a>

          <div class="dropdown-menu">
            <a class="dropdown-item" @click="changeLanguage('en')">English</a>
            <a class="dropdown-item" @click="changeLanguage('ar')">Arabic</a>
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>

export default {
  name: "FooterAPP",
  methods:{
    async changeLanguage(lang){
     await window.location.reload();

      localStorage.setItem('lang',lang);
      this.$i18n.locale=lang;
      document.documentElement.setAttribute("lang", lang);
      //alert(lang);
    }
  }
  ,data(){

   return{year:0};
  },
  mounted() {
    this.year=new Date().getFullYear();


  }
}
</script>

<style scoped>
.footer-dark {
  background: #333333;
  padding: 50px 0 50px;
  color: white;
}

.footer-dark .item{
  margin-top: 30px;
}
.footer-dark h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
  color: white;
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 2 !important;
}

.footer-dark ul a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  opacity: 1;
  font-size: 15px;
}

.footer-dark ul a:hover {
  opacity: 0.95;
  color: var(--main-color);
}

@media (max-width:767px) {
  .footer-dark .item:not(.social) {
    padding-bottom: 20px;
  }
}

.footer-dark .item.text {
  margin-bottom: 40px;

}
.text-start{
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}

.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
  font-size: 8px;
}

.footer-dark .item.social {
  text-align: start;
  padding: inherit;
  margin-top: 20px;
  margin-bottom:20px;
}

@media (max-width:991px) {
  .footer-dark .item.social {
    text-align: start;
    padding: inherit;
    margin-top: 20px;
  }
}

.footer-dark .item.social > a {
  font-size: 20px;
  width: 34px;
  height: 34px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 0 1px var(--main-color);
  margin: 2px 3px;
  color: var(--main-color);
}

.footer-dark .item.social > a:hover {
  opacity: 0.75;
  transform: scale(1.05);
  transition: all ease-in 0.2s;
}

.footer-dark .copyright {
  text-align: center;
  padding-top: 4px;
  opacity: 1;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  color: white;
}

.footer-dark .copyright a {
  color: var(--main-color);
}

#ezarTitle {
  color: var(--main-color);
  font-size: 18px;
}
#ezarTitle:hover {
  color: var(--main-color);
  opacity: 80%;
  text-shadow: 0px 1px 1px 0.5px white;
}

.col-md-4.col-xxl-4.text {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  line-height: 2 !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}


/* dropdown-language */
.dropdown-language{
  display: inline;
  text-decoration: none;
  font-weight: 400;
}
.dropdown-language a{
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.dropdown-menu{
  background: white;
}
.dropdown-menu .dropdown-item {
  line-height: 2;
  color: #383838;
}
.dropdown-menu .dropdown-item:focus,.dropdown-menu .dropdown-item:hover {
  background: var(--main-color);
  color: white;
  text-decoration: none;
  cursor: pointer;
}
a{
  text-decoration: none;
}
</style>