<template>
  <div>
    <BannerApp/>
    <CriteriaLeft/>
    <ServicesApp/>
  </div>
</template>

<script>

import BannerApp from "@/components/sections/BannerApp";
import ServicesApp from "@/components/sections/ServicesApp";
import CriteriaLeft from "@/components/sections/CriteriaLeft";
export default {
  name: "ServicesView",
  components: {CriteriaLeft, ServicesApp, BannerApp },

}
</script>

<style scoped>
/*
.maintenance {
  height: 800px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
*/

</style>