<template>
<div class="b2bstore">
  <WaitApp :title="title" :description="description"/>
  <SoonApp/>
</div>
</template>

<script>
import WaitApp from "@/components/sections/Wait";
import SoonApp from "@/components/sections/Soon";
export default {
  name: "B2BStoreView",
  components: {SoonApp, WaitApp},
  data() {
    return {
      title: 'B2B Store', description: "Soon the service will be launched, wait for us."
    }
  }
}
</script>

<style scoped>

</style>