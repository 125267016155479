<template>

  <div class="support-container">
    <div class="header-support">
      <div class="header-content">
        <h2 class="header-title">What can we do for you?</h2>
      </div>
      <img src="@/assets/images/contact/contact-background-mobile.jpg" class="header-image responsive-image hidden-from-mobile-landscape hidden-from-tablet-on" alt="">
      <img src="@/assets/images/contact/contact-background-tablet.jpg" class="header-image responsive-image visible-from-tablet-on hidden-from-desktop-on" alt="">
      <img src="@/assets/images/contact/contact-background-desktop.jpg" class="header-image responsive-image visible-from-desktop-on" alt="">

    </div>
    <div class="contact-information container-fluid">

      <!-- BEGIN: product assistance module-->
      <div class="product-assistance contact-information-module">
        <h2 class="main-title">Product Support</h2>
        <div class="support-item live-chat ">
          <div class="support-item">
            <i class="icon ion-social-whatsapp"></i>
            <h3>WhatsApp Us</h3>
            <p>
              Send us the message and receive a quick reply.<br>
              <a title="New window opens" target="_blank" data-omni-type="support_content" data-omni="contact us:Whats App" href="whatsapp://send?phone=+9647838397779"><b>WhatsApp Now</b><i class="fa fa-send-o"></i></a></p>

            <p></p>
          </div>
          <div class="support-item live-chat">
            <i class="fa fa-comments"></i>
            <h3>Live Chat</h3>
            <p>Need a faster response?<br>
              Chat with our experienced <br>

              technical team online	Sunday ~ <br>
              Thursday, From 08:00 ~ 17:00
              <br>
              <a href="#livechat" data-omni-type="support_content" data-omni="contact us:live chat" target="_blank" title="New window opens"><strong>Start chat</strong><span class="icon-link-play"></span></a></p>
          </div>
          <div class="support-item">
            <i class="fa fa-envelope"></i>
            <h3>Email Support</h3>
            <p>Send an email, and get a next response.<br>
              <a href="#" data-omni-type="support_content" data-omni="&#39;contact us:email support" onclick="window.open();" title="New window opens"><strong>Message us</strong><span class="icon-link-play"></span></a></p>
          </div>
          <div class="support-item">
            <i class="fa fa-phone"></i>
            <h3>Phone Support</h3>
            <p>Call to speak to a customer service rep.</p>
            <div class="expander">
              <div class="expandable-content" style="display: none;">
                <div itemscope="" itemtype="">
                  <h3>To contact our Customer Contact Center by phone:</h3>
                  <p><strong>Jordan:</strong><br>
                    Phone: <a href="tel:+9647838397779" data-omni-type="contact" data-omni="call:+9647838397779"><strong>+9647838397779</strong></a> or<br>
                    <a href="tel:+9647838397779" data-omni-type="contact" data-omni="call:+9647838397779"><strong>+9647838397779</strong></a> or<br>
                    <a href="tel:+9647838397779" data-omni-type="contact" data-omni="call:+9647838397779">
                      <strong>+9647838397779</strong></a> (WhatsApp).<br>
                    Address: Building # 5, King Hussein Business Park, King Abdulla II Street- Amman-Jordan</p>
                  <p class="com-text"> <span class="txt">24 Hours Hotline number</span> </p>
                  <p><strong>Lebanon: </strong><br>
                    Phone: <a href="tel:+9647838397779" data-omni-type="contact" data-omni="call:+9647838397779"><strong>+9647838397779</strong></a><br>
                    Address: Akhem Plaza, 5th Floor, Sin El Fil, Beirut, Lebanon.</p>
                  <p><strong>Iraq:</strong> <br>
                    Phone: <a href="tel:+9647838397779" data-omni-type="contact" data-omni="call:+9647838397779"><strong>+9647838397779</strong></a>.
                    Address: Erbil English Village Villa  No.303</p>
                  <p class="com-text"> <span class="txt">24 Hours Hotline number</span> </p>
                  <p><strong>For other Levant countries:</strong>
                    <br>
                    <a href="tel:+9647838397779" data-omni-type="contact" data-omni="call:+9647838397779"><strong>+9647838397779</strong></a>.</p>


                  <h3>Customer Service and Technical Support Operation hours.</h3>
                  <p>Saturday – Thursday: 8:00 Am – 7:00 PM</p>
                </div>
              </div>
              <a href="#none" data-omni-type="view_more" data-omni="show more:Phone Support" class="toggler expander_contact" data-text="Show More +" data-alt-text="Show Less -">
                <span class="text-toggler  btn_more" style="display: none;"><strong>Show More +</strong></span>
                <span class="text-toggler btn_hide" style="display: inline-block;"><strong>Show Less -</strong></span>
              </a>
            </div>
          </div>
        </div>
        <!-- END: product assistance module-->
      </div>
    </div>
  </div>

</template>

<script>
import $ from 'jquery'
export default {
  name: "ContactFooter",
  mounted() {

    $('.support-item .expander_contact .btn_more').toggle();
    $('.support-item .expander_contact .btn_hide').toggle();
    $('.support-item .expander_contact .btn_more').focus();

    $('.support-item .expander_contact .btn_hide').click(function(){
        var content = $('.support-item .expandable-content');

        content.slideToggle(300, function(){
          $('.support-item .expander_contact .btn_more').toggle();
          $('.support-item .expander_contact .btn_hide').toggle();
          $('.support-item .expander_contact .btn_more').focus();

        });


      });

      $('.support-item .expander_contact .btn_more').click(function(){
        var content = $('.support-item .expandable-content');
        content.slideToggle(300, function(){

          $('.support-item .expander_contact .btn_more').toggle(100);
          $('.support-item .expander_contact .btn_hide').toggle(100);

          if($('.support-item .expandable-content p a').length > 0) {
            $('.support-item .expandable-content p a').focus();
          } else {
            $('.support-item .expander_contact .btn_hide').focus();
          }

        });


      });

  }
}
</script>

<style scoped>
.support-container [class*="icon-"]{font-family:'samsungIcon';
  font-style:normal;
  font-weight:normal;
  line-height:1;
  -webkit-font-smoothing:antialiased;
  speak:none;
  font-variant:normal;
  text-transform:none;

}

.icon-link-play:before{content:'\34';}
.support-container .btn_hide{display:none;}

.support-container .header-support{position:relative;}
.support-container .header-support .header-content{position:absolute;bottom:30px;left:30px;width:90%;}
.support-container .header-support .header-content:lang(ar){position:absolute;bottom:30px;right:30px;width:90%;}

.support-container .header-support .header-content .header-title{font-weight:normal;font-size:42px;line-height:40px;letter-spacing:-1px;color:#fff;}
.support-container .header-support .responsive-image{width:100%;}
.support-container .header-support .header-image{display:none;}
.support-container .header-support .visible-from-desktop-on{display:block;}

.support-container .contact-information{clear:both;overflow:hidden;width:100%;box-sizing:border-box;}
.support-container .contact-information > div{float:left;width:30%;margin:20px 0 20px 2%;}

.support-container .contact-information > div:lang(ar){float:right;width:30%;margin:20px 2% 20px 0;}




.support-container .contact-information .main-title{margin-bottom:10px;font-size:32px;font-weight:normal;color:#292929;}
.support-container .contact-information .simple-list-column p{line-height:30px;}
.support-container .contact-information .simple-list-column a{font-size:18px;color:var(--main-color);}
.support-container .contact-information .simple-list-column a:hover{color:#000;}

.support-container .contact-information-module .support-item{
  clear:both;
  position:relative;
  width:87%;
  padding:10px 0 10px 50px;
  word-wrap:break-word;}

.support-container .contact-information-module .support-item:lang(ar){
  clear:both;
  position:relative;
  width:87%;
  padding:10px 50px 10px 0;
  word-wrap:break-word;}
.support-container .contact-information-module h2+.support-item{padding-top:0;}

.support-container .contact-information-module .support-item i{
  position:absolute;
  left:0;
  font-size:35px;
  top: 7px;
  color:var(--main-color);
}

.support-container .contact-information-module .support-item i:lang(ar){
  position:absolute;
  right:0;
  font-size:35px;
  top: 7px;
  color:var(--main-color);
}

.support-container .contact-information-module.product-assistance p .icon-link-play{display:inline-block;font-size:16px;margin-top:5px;}
.support-container .contact-information-module .support-item h3{font-weight:normal;}
.support-container .contact-information-module .support-item>p{width:90%;}
.support-container .contact-information-module .support-item a{font-size:17px;color:var(--main-color);}
.support-container .contact-information-module .support-item a:hover{color:#000;}
.support-container .contact-information-module.product-assistance .expandable-content h3{clear:both;width:88%;margin-top:12px;padding-top:10px;font-weight:bold;font-size:14px;}
.support-container .contact-information .expandable-content p{width:88%;margin-top:8px;font-size:17px;word-wrap:break-word;}
.support-container .contact-information-module.more-help .support-item{padding-left:0;}
.support-container .contact-information-module .support-item .expandable-content{margin-bottom:30px;

}
.support-container .expandable-content{display:none;}

@media screen and (max-width: 1024px) {
  .support-container .header-support .visible-from-desktop-on{display:none;}
  .support-container .header-support .visible-from-tablet-on{display:block;}
  .support-container .contact-information > div{width:48%;}
}

@media screen and (max-width: 768px) {
  .support-container .header-support .visible-from-tablet-on{display:none;}
  .support-container .header-support .hidden-from-tablet-on{display:block;}
  .support-container .contact-information > div{width:90%;}
  /*.isDesktop{display:none;}*/
  .support-container .contact-information .simple-list-column a{font-size:22px;}
}
.support-item .com-text{
  display: block;
  padding: 12px 20px 11px;
  margin: 12px 0px;
  background: #dadada;
  text-align: center;
  font-size: 14px !important;
  color: #000;
  font-family: 'SamsungOne700', Arial;
}
.support-container .contact-information-module .support-item .icon-whatsapp {
  position: absolute;
  left: 0;
  font-size: 32px;
}

@media (max-width:768px) {
  .support-container .contact-information > div{float:left;width:100%;margin:20px 0 20px 1%;
  padding: 0;
  }

  .support-container .contact-information > div:lang(ar){float:right;width:100%;margin:20px 1% 20px 0;
  padding: 0;}
  .support-container .contact-information-module .support-item{
    clear:both;
    position:relative;
    width:90%;
    padding:10px 0 10px 35px;
    word-wrap:break-word;}

  .support-container .contact-information-module .support-item:lang(ar){
    clear:both;
    position:relative;
    width:90%;
    padding:10px 35px 10px 0;
    word-wrap:break-word;}

  .support-container .contact-information-module .support-item i{
    position:absolute;
    left:0;
    font-size:30px;
    top: 7px;
    color:var(--main-color);
  }

  .support-container .contact-information-module .support-item i:lang(ar){
    position:absolute;
    right:0;
    font-size:30px;
    top: 7px;
    color:var(--main-color);
  }
}


</style>