<template>
  <!-- *****Main Container***** -->
  <section id="main_container" class="main_banner_area text-white position-relative">

    <div class="container">
        <WavesApp/>
      <div class="over-canvas" ></div>
        <div class="row info-intro">
          <div class="col-12 col-lg-5 col-md-6 mr-md-auto ml-md-auto">
            <div class="info">
              <h1 class="display-4 mb-3 bold">{{ $t("title_ezar") }}</h1>
              <p class="mb-5">{{$t('des')}}</p>

              <button type="submit" class="btn">Learn More fasd</button>
            </div>
          </div>
          <div class="col-12 col-lg-5 col-md-6">
            <div class="app-img">
              <img class="wow bounceInUp " data-wow-duration="1.7s" data-wow-delay="1s" src="@/assets/images/bg-img/main-bg-img.png" alt="image">
            </div>
          </div>
        </div>
      </div>

  </section>

</template>

<script>

import WavesApp from "@/components/3d_shapes/Waves";
export default {
  name: "MainContainerAPP",
  components: {WavesApp},
  props:[],




}
</script>

<style scoped>
/*#canvas {
  top:-30px;
  display: block;
  width: 100%;
  height: 100%;
  bottom:-20px;
  right: 0px;
  left:0px;
  margin: 0 auto;
  position: absolute;
  opacity: 0.7;
  background: transparent;
}*/
.over-canvas{
  top:0;
  display: block;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0px;
  left:0px;
  margin: 0 auto;
  position: absolute;
  z-index: 10;
  opacity: 40%;
  background-image: -moz-linear-gradient(0deg, rgba(67, 206, 162, 0.95) 0%, rgba(24, 90, 157, 0.9) 100%);
  background-image: -webkit-linear-gradient(0deg, rgba(67, 206, 162, 0.95) 0%, rgba(24, 90, 157, 0.9) 100%);
  background-image: -ms-linear-gradient(0deg, rgba(67, 206, 162, 0.95) 0%, rgba(24, 90, 157, 0.9) 100%);
  /* background-image: linear-gradient(0deg, rgba(67, 206, 162, 0.95) 0%, rgb(48,113,167) 100%); */
  background-image:transparent;
}
.info-intro{
  width: 100%;
  height: 100%;
  position: relative;
  background:transparent;
  z-index: 20;
}
.main_banner_area{
  padding-top: 100px;

  /* min-height: 640px; */
  min-height: calc(100vh );
  position: relative;
  z-index: 1;
  background-position: bottom center;
  background-size: cover;
}
.main_banner_area:after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: -moz-linear-gradient(0deg, #43cea2 0%, #185a9d 100%);
  background-image: -webkit-linear-gradient(0deg, #43cea2 0%, #185a9d 100%);
  background-image: -ms-linear-gradient(0deg, #43cea2 0%, #185a9d 100%);
/*  background-image: -linear-gradient(0deg, #43cea2 0%, #185a9d 100%);*/
  opacity: 1;
}

.main_banner_area .info{
  padding: 50px 0;
}

.info > p{
  color: #f1f2f3;
}

.info button{
  color: #fff;
  padding: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 0 !important;
}

.info button:hover {
  background-color: #ffffff;
  color: #43cea2;
  border: 1px solid #ffffff;
  border-radius: 0 !important;
}


.main_banner_area .info{
  padding: 50px 0;
}

.info > p{
  color: #f1f2f3;
}

.info button{
  color: #fff;
  padding: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 0 !important;
}

.info button:hover {
  background-color: #ffffff;
  color: #43cea2;
  border: 1px solid #ffffff;
  border-radius: 0 !important;
}
/*@media (min-width:1440px) {
  .main_banner_area{
    padding: 150px 0 0px;
    min-height: 830px;
    position: relative;
    z-index: 1;
    background-position: bottom center;
    background-size: cover;
  }
}*/
@media (min-width:1920px) {
  .main_banner_area{
    padding: 200px 0 0px;
    min-height: 750px;
    position: relative;
    z-index: 1;
    background-position: bottom center;
    background-size: cover;
  }
}


</style>