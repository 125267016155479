<template>
  <div>
  <BannerApp/>
  <QuestionsApp/>
  </div>
</template>

<script>
import QuestionsApp from "@/components/sections/Questions";
import BannerApp from "@/components/sections/BannerApp";
export default {
  name: "CommonQuestionsView",
  components: {BannerApp, QuestionsApp}
}
</script>

<style scoped>

</style>