<template>
  <section class="blog_list_area">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="container">
            <article  class="blog_list_item row m0">
              <div class="col-md-6">
                <div class="blog_list_img">
                  <img src="@/assets/images/blog-img/blog-list-1.jpg" alt="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="blog_list_content">
                  <a href="#"><h3>List Post Type Blog News</h3></a>
                  <h6>Posted by <a href="#">admin</a>  at 04 Dec, 2017</h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                  <div class="pull-left">
                    <a class="read_more" href="#">READ MORE</a>
                  </div>
                  <div class="pull-right">
                    <a href="#"><i class="fa fa-eye"></i>206</a>
                    <a href="#"><i class="fa fa-comment-o"></i>19</a>
                    <a href="#"><i class="fa fa-heart-o"></i>206</a>
                  </div>
                </div>
              </div>
            </article>
            <article class="blog_list_item row m0">
              <div class="col-md-6">
                <div class="blog_list_img">
                  <img src="@/assets/images/blog-img/blog-list-2.jpg" alt="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="blog_list_content">
                  <a href="#"><h3>List Post Type Blog News</h3></a>
                  <h6>Posted by <a href="#">admin</a>  at 04 Dec, 2017</h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                  <div class="pull-left">
                    <a class="read_more" href="#">READ MORE</a>
                  </div>
                  <div class="pull-right">
                    <a href="#"><i class="fa fa-eye"></i>206</a>
                    <a href="#"><i class="fa fa-comment-o"></i>19</a>
                    <a href="#"><i class="fa fa-heart-o"></i>206</a>
                  </div>
                </div>
              </div>
            </article>
            <article class="blog_list_item row m0">
              <div class="col-md-6">
                <div class="blog_list_img">
                  <img src="@/assets/images/blog-img/blog-list-3.jpg" alt="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="blog_list_content">
                  <a href="#"><h3>List Post Type Blog News</h3></a>
                  <h6>Posted by <a href="#">admin</a>  at 04 Dec, 2017</h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                  <div class="pull-left">
                    <a class="read_more" href="#">READ MORE</a>
                  </div>
                  <div class="pull-right">
                    <a href="#"><i class="fa fa-eye"></i>206</a>
                    <a href="#"><i class="fa fa-comment-o"></i>19</a>
                    <a href="#"><i class="fa fa-heart-o"></i>206</a>
                  </div>
                </div>
              </div>
            </article>
            <article class="blog_list_item row m0">
              <div class="col-md-6">
                <div class="blog_list_img">
                  <img src="@/assets/images/blog-img/blog-list-4.jpg" alt="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="blog_list_content">
                  <a href="#"><h3>List Post Type Blog News</h3></a>
                  <h6>Posted by <a href="#">admin</a>  at 04 Dec, 2017</h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                  <div class="pull-left">
                    <a class="read_more" href="#">READ MORE</a>
                  </div>
                  <div class="pull-right">
                    <a href="#"><i class="fa fa-eye"></i>206</a>
                    <a href="#"><i class="fa fa-comment-o"></i>19</a>
                    <a href="#"><i class="fa fa-heart-o"></i>206</a>
                  </div>
                </div>
              </div>
            </article>
            <article class="blog_list_item row m0">
              <div class="col-md-6">
                <div class="blog_list_img">
                  <img src="@/assets/images/blog-img/blog-list-5.jpg" alt="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="blog_list_content">
                  <a href="#"><h3>List Post Type Blog News</h3></a>
                  <h6>Posted by <a href="#">admin</a>  at 04 Dec, 2017</h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                  <div class="pull-left">
                    <a class="read_more" href="#">READ MORE</a>
                  </div>
                  <div class="pull-right">
                    <a href="#"><i class="fa fa-eye"></i>206</a>
                    <a href="#"><i class="fa fa-comment-o"></i>19</a>
                    <a href="#"><i class="fa fa-heart-o"></i>206</a>
                  </div>
                </div>
              </div>
            </article>
            <article class="blog_list_item row m0">
              <div class="col-md-6">
                <div class="blog_list_img">
                  <img src="@/assets/images/blog-img/blog-list-6.jpg" alt="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="blog_list_content">
                  <a href="#"><h3>List Post Type Blog News</h3></a>
                  <h6>Posted by <a href="#">admin</a>  at 04 Dec, 2017</h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                  <div class="pull-left">
                    <a class="read_more" href="#">READ MORE</a>
                  </div>
                  <div class="pull-right">
                    <a href="#"><i class="fa fa-eye"></i>206</a>
                    <a href="#"><i class="fa fa-comment-o"></i>19</a>
                    <a href="#"><i class="fa fa-heart-o"></i>206</a>
                  </div>
                </div>
              </div>
            </article>
            <article class="blog_list_item row m0">
              <div class="col-md-6">
                <div class="blog_list_img">
                  <img src="@/assets/images/blog-img/blog-list-7.jpg" alt="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="blog_list_content">
                  <a href=" #"><h3>List Post Type Blog News</h3></a>
                  <h6>Posted by <a href="#">admin</a>  at 04 Dec, 2017</h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                  <div class="pull-left">
                    <a class="read_more" href="#">READ MORE</a>
                  </div>
                  <div class="pull-right">
                    <a href="#"><i class="fa fa-eye"></i>206</a>
                    <a href="#"><i class="fa fa-comment-o"></i>19</a>
                    <a href="#"><i class="fa fa-heart-o"></i>206</a>
                  </div>
                </div>
              </div>
            </article>
            <article class="blog_list_item row m0">
              <div class="col-md-6">
                <div class="blog_list_img">
                  <img src="@/assets/images/blog-img/blog-list-8.jpg" alt="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="blog_list_content">
                  <a href="#"><h3>List Post Type Blog News</h3></a>
                  <h6>Posted by <a  href="#">admin</a>  at 04 Dec, 2017</h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                  <div class="pull-left">
                    <a class="read_more" href="#">READ MORE</a>
                  </div>
                  <div class="pull-right">
                    <a href="#"><i class="fa fa-eye"></i>206</a>
                    <a href="#"><i class="fa fa-comment-o"></i>19</a>
                    <a href="#"><i class="fa fa-heart-o"></i>206</a>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <nav aria-label="Page navigation" class="blog_pagination">
            <ul class="pagination">
              <li class="active"><a href="#">1</a></li>
              <li><a href="#">2</a></li>
              <li>
                <a href="#" aria-label="Next">
                  <span aria-hidden="true">»</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-4">
          <div class="blog_right_sidebar">
            <aside class="right_widget search_widget">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search for...">
                <span class="input-group-btn">
                                        <button class="btn btn-default" type="button"><i class="fa fa-search"></i></button>
                                    </span>
              </div>
            </aside>
            <aside class="right_widget category_widget">
              <div class="sidebar_title">
                <h3>Categories</h3>
              </div>
              <ul>
                <li><a href="#">Brasserie and Bistro <span>(15)</span></a></li>
                <li><a href="#">Buffet and Smorgasbord <span>(9)</span></a></li>
                <li><a href="#">Free Keyword Suggestion <span>(19)</span></a></li>
                <li><a href="#">Barbecue <span>(12)</span></a></li>
                <li><a href="#">Cafe <span>(26)</span></a></li>
                <li><a href="#">Destination Restaurant <span>(29)</span></a></li>
                <li><a href="#">Coffee House <span>(26)</span></a></li>
                <li><a href="#">Tabletop Cooking <span>(20)</span></a></li>
                <li><a href="#">Fast Food <span>(13)</span></a></li>
                <li><a href="#">Pub <span>(30)</span></a></li>
                <li><a href="#">Mongolian Barbecue <span>(20)</span></a></li>
              </ul>
            </aside>
            <aside class="right_widget recent_widget">
              <div class="sidebar_title">
                <h3>RECENT POST</h3>
              </div>
              <div class="recent_widget_inner">
                <div class="media">
                  <div class="media-left">
                    <img src="@/assets/images/blog-img/recent-post-1.jpg" alt="">
                  </div>
                  <div class="media-body">
                    <a href="#"><h4>Susan et services can now be accessed online through a mobile app</h4></a>
                    <h5>November 18, 2017</h5>
                  </div>
                </div>
                <div class="media">
                  <div class="media-left">
                    <img src="@/assets/images/blog-img/recent-post-2.jpg" alt="">
                  </div>
                  <div class="media-body">
                    <a href="#"><h4>Susan et services can now be accessed online through a mobile app</h4></a>
                    <h5>November 18, 2017</h5>
                  </div>
                </div>
                <div class="media">
                  <div class="media-left">
                    <img src="@/assets/images/blog-img/recent-post-3.jpg" alt="">
                  </div>
                  <div class="media-body">
                    <a href="#"><h4>Susan et services can now be accessed online through a mobile app</h4></a>
                    <h5>November 18, 2017</h5>
                  </div>
                </div>
                <div class="media">
                  <div class="media-left">
                    <img src="@/assets/images/blog-img/recent-post-4.jpg" alt="">
                  </div>
                  <div class="media-body">
                    <a href="#"><h4>Susan et services can now be accessed online through a mobile app</h4></a>
                    <h5>November 18, 2017</h5>
                  </div>
                </div>
              </div>
            </aside>
            <aside class="right_widget tags_widget">
              <div class="sidebar_title">
                <h3>Tags</h3>
              </div>
              <ul>
                <li><a href="#">Entertainment</a></li>
                <li><a href="#">Games</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Pub</a></li>
                <li><a href="#">Marketing</a></li>
                <li><a href="#">Apps</a></li>
                <li><a href="#">News</a></li>
                <li><a href="#">Business</a></li>
                <li><a href="#">WordPress</a></li>
                <li><a href="#">Productivity</a></li>
              </ul>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlogListApp"
}
</script>

<style scoped>
/* Blog List area css
============================================================================================ */
.blog_list_area {
  padding: 100px 0px;
}
/*@media (max-width: 767px){
  .blog_list_area .blog_list_item {
    overflow: hidden;
  }
}*/
.blog_list_area .blog_list_item {
  position: relative;
  padding: 50px 0 50px 0;
}

.blog_list_area .blog_list_item:before {
  content: "";
  height: 1px;
  background: #dfdfdf;
  max-width: 750px;
  margin: auto;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 15px;
}

.blog_list_area .blog_list_item .blog_list_img img {
  max-width: 100%;
}

.blog_list_area .blog_list_item:first-child {
  padding-top: 0px;
}

.blog_list_area .blog_list_item:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}

.blog_list_area .blog_list_item:last-child:before {
  display: none;
}

.blog_list_area .blog_list_content {
  overflow: hidden;
  clear: both;
}

@media (max-width: 991px){
  .blog_list_area .blog_list_content {
    margin-top: 15px;
  }
}

.blog_list_area .blog_list_content h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #222222;
  padding-bottom: 22px;
}

.blog_list_area .blog_list_content h3:hover {
  /*color: #f8395d;*/
  color: var(--main-color);
}

.blog_list_area .blog_list_content h6 {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #696969;
  padding-bottom: 22px;
}

.blog_list_area .blog_list_content h6 a {
 /* color: #f8395d;*/
}

.blog_list_area .blog_list_content p {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  line-height: 26px;
  color: #696969;
  padding-bottom: 40px;
}

.blog_list_area .blog_list_content .pull-left {
  height: 40px;
}

.blog_list_area .blog_list_content .pull-left .read_more {
  width: 130px;
  border-radius: 0px;
  line-height: 40px;
  height: 40px;
  padding: 0px;
}

.blog_list_area .blog_list_content .pull-left .read_more:hover {
  /*color: #f8395d;*/
  color: var(--main-color);
}

.blog_list_area .blog_list_content .pull-right {
  padding-top: 8px;
}

.blog_list_area .blog_list_content .pull-right a {
  color: #696969;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding-left: 20px;
}

.blog_list_area .blog_list_content .pull-right a i {
  padding-right: 5px;
}

.blog_list_area.purple_blog .blog_list_content h3 {
  color: #222222;
}

.blog_list_area.purple_blog .blog_list_content h3:hover {
 /* color: #a640d0;*/
  color: var(--main-color);
}

.blog_list_area.purple_blog .blog_list_content h6 {
  color: #696969;
}

.blog_list_area.purple_blog .blog_list_content h6 a {
 /* color: #a640d0;*/
  color: var(--main-color);
}

.blog_list_area.purple_blog .blog_list_content .pull-left {
  height: 40px;
}

.blog_list_area.purple_blog .blog_list_content .pull-left .read_more {
  width: 130px;
  border-radius: 0px;
  line-height: 40px;
  height: 40px;
  padding: 0px;
}

.blog_list_area.purple_blog .blog_list_content .pull-left .read_more:hover {
  /*color: #a640d0;*/
  color: var(--main-color);
}

.blog_pagination .pagination {
  margin-top: 60px;
  margin-bottom: 0px;
}

.blog_pagination .pagination li {
  margin-right: 7px;
  display: inline-block;
}

.blog_pagination .pagination li:lang(ar) {
  margin-left: 7px;
  display: inline-block;
}
.blog_pagination .pagination li a {
  border: 1px solid #dfdfdf;
  border-radius: 0px;
  padding: 0px;
  height: 40px;
  width: 40px;
  line-height: 38px;
  text-align: center;
  display: block;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #222222;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}

.blog_pagination .pagination li:hover a, .blog_pagination .pagination li.active a {
  /*background: #f8395d;*/
  background: var(--main-color);;
  color: #fff;
}

.blog_pagination .pagination li:last-child {
  margin-right: 0px;
}
.blog_pagination .pagination li:last-child:lang(ar) {
  margin-left: 0px;
}
.blog_pagination .pagination li:first-child:lang(ar) {
  margin-left: 0px;
}
.category_widget ul li a span:lang(ar){
float: left;
}
.blog_pagination.purple_pagination .pagination li a {
  color: #222222;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}

.blog_pagination.purple_pagination .pagination li:hover a, .blog_pagination.purple_pagination .pagination li.active a {
 /* background: #a640d0;*/
  background:  var(--main-color);;
  color: #fff;
}

/* widget css */
.right_widget {
  margin-bottom: 55px;
}

.right_widget:last-child {
  margin-bottom: 0px;
}

.sidebar_title {
  padding-bottom: 35px;
}

.sidebar_title h3 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #222222;
  text-transform: uppercase;
}

.search_widget .input-group input {
  border: 1px solid #dfdfdf;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 0px;
  height: 60px;
  background: transparent;
  color: #696969;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}

.search_widget .input-group input.placeholder {
  color: #696969;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}

.search_widget .input-group input:-moz-placeholder {
  color: #696969;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}

.search_widget .input-group input::-moz-placeholder {
  color: #696969;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}

.search_widget .input-group input::-webkit-input-placeholder {
  color: #696969;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}

.search_widget .input-group input:focus {
  border: 1px solid  var(--main-color);
  border-right: 0px;
}

.search_widget .input-group-btn .btn-default {
  height: 60px;
  padding: 0px;
  width: 65px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border: 1px solid  var(--main-color);
  background:  var(--main-color);
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  outline: none !important;
  font-size: 18px;
}

.search_widget.purple_search .input-group input {
  color: #696969;
}

.search_widget.purple_search .input-group input:focus {
  border: 1px solid  var(--main-color);
  border-right: 0px;
}

.search_widget.purple_search .input-group-btn .btn-default {
  border: 1px solid  var(--main-color);
  background:  var(--main-color);
  color: #fff;
}

.category_widget ul li {
  border-bottom: 1px solid #dfdfdf;
}

.category_widget ul li a {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #696969;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  padding: 15px 0px;
  display: inline-block;
}

.category_widget ul li:first-child a {
  padding-top: 0px;
}

.category_widget ul li:hover a {
  color: var(--main-color);
}

.category_widget.purple_category ul li a {
  color: #696969;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}

.category_widget.purple_category ul li:hover a {
  color:var(--main-color);
}

.recent_widget .recent_widget_inner .media {
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dfdfdf;
  margin-top: 0px;
}

.recent_widget .recent_widget_inner .media .media-left {
  padding-right: 15px;
}
.recent_widget .recent_widget_inner .media .media-left:lang(ar) {
  padding-left: 15px;
}

.recent_widget .recent_widget_inner .media .media-body h4 {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  line-height: 20px;
  line-height: 24px;
  color: #222222;
  padding-bottom: 6px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  margin-top: -5px;
}

.recent_widget .recent_widget_inner .media .media-body h5 {
  color: #696969;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}

.recent_widget .recent_widget_inner .media:first-child {
  padding-top: 0px;
}

.recent_widget .recent_widget_inner .media:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}

.recent_widget .recent_widget_inner .media:hover .media-body h4 {
  color: var(--main-color);
}

.recent_widget.purple_recent .recent_widget_inner .media .media-body h4 {
  color: #222222;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}

.recent_widget.purple_recent .recent_widget_inner .media:hover .media-body h4 {
  color: var(--main-color);
}

.tags_widget ul {
  margin-right: -10px;
  margin-top: -10px;
}

.tags_widget ul li {
  display: inline-block;
  margin-right: 7px;
  margin-top: 10px;
}

.tags_widget ul li a {
  line-height: 34px;
  border: 1px solid #dfdfdf;
  font-family: "Lato", sans-serif;
  color: #696969;
  display: block;
  padding: 0px 15px;
  font-size: 14px;
  border-radius: 5px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}

.tags_widget ul li:hover a {
  border-color: var(--main-color);
  color: var(--main-color);
}

.tags_widget.purple_tags ul li a {
  color: #696969;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}

.tags_widget.purple_tags ul li:hover a {
  border-color: var(--main-color);
  color: var(--main-color);
}

/* End Blog List area css
============================================================================================ */
/* Read more css
============================================================================================ */
.read_more {
  width: 150px;
  border: 1px solid var(--main-color);
  border-radius: 22.5px;
  display: inline-block;
  text-align: center;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  overflow: hidden;
}

.read_more i {
  padding-right: 14px;
  font-size: 24px;
}

.read_more:before {
  content: '';
  position: absolute;
  top: 0;
  right: -50px;
  bottom: 0;
  left: 0;
  border-right: 50px solid transparent;
  border-bottom: 80px solid var(--main-color);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  z-index: -1;
}

.read_more:hover {
  color: var(--main-color);
}

.read_more:hover:before {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* End Read more css
============================================================================================ */




</style>