<template>
<div class="error-view">
  <MainContainerAPP :title="title" :description="description" />
</div>
</template>

<script>
import MainContainerAPP from "@/components/sections/MainContainer";
export default {
  name: "ErrorView",
  components: {MainContainerAPP},
  data(){
    return{
      title:"404",
      description:"Page not found sorry"

    }
  }
}
</script>

<style scoped>

</style>