<template>
  <!-- ***** states Start ***** -->
  <section id="states"  >
    <div class="container">
      <!-- Heading Text  -->
      <div class="section-heading text-center" data-aos="fade-up">
        <h2>Statistics provided by Ezar</h2>
        <div class="line-title-center"></div>
      </div>
    </div>

    <div class="stats" id="stats">
      <div class="container" data-aos="fade-up">
        <div class="box" >
          <i class="far fa-user fa-2x fa-fw"></i>
          <span class="number" data-goal="200">0</span>
          <span class="text">Clients</span>
        </div>
        <div class="box">
          <i class="fas fa-code fa-2x fa-fw"></i>
          <span class="number" data-goal="57">0</span>
          <span class="text">Projects</span>
        </div>

        <div class="box">
          <i class="icon-globe fa-2x fa-fw"></i>
          <span class="number" data-goal="50">0</span>
          <span class="text">Countries</span>
        </div>
  
        <div class="box" style="margin-bottom: 24px;">
          <i class="icon-emotsmile fa-2x fa-fw"></i>
          <span class="number" data-goal="170">0</span>
          <span class="text">Happy customer</span>
        </div>
      </div>
    </div>

  </section>

</template>

<script>
 
export default {
  name: "StatesApp",
  created() {
    let started = false; // Function Started ? No

    window.addEventListener("scroll", function () {
      let statsSection = document.querySelector(".stats");
      let nums = document.querySelectorAll(".stats .number");
      if (statsSection !== null) {
        if ((window.scrollY + 300) >= statsSection.offsetTop) {
          if (!started) {
            nums.forEach((num) => startCount(num));
          }
          started = true;
        }
      }
    });
    function startCount(el) {
      let goal = el.dataset.goal;
      let count = setInterval(() => {
        el.textContent++;
        if (el.textContent == goal) {
          clearInterval(count);
        }
      }, 2000 / goal);
    }


  }


}
</script>

<style scoped>
#states{
  background: var(--section-color2);
}
.stats {
  padding-top:20px;
  padding-bottom: 20px;
  position: relative;
  background-size: cover;
  min-height: 300px;
}

.stats::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.stats h2 {
  font-weight: bold;
  font-size: 24px;
  width: fit-content;
  margin: 0 auto 50px;
  position: relative;
  color: var(--main-color);
}

.stats .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.stats .box {
  background-color: var(--section-color2);
  padding: 30px 15px;
  text-align: center;
  position: relative;
  opacity: 0.8;
  /*box-shadow: 1px 1px 4px 1px #dddddd;*/
  -webkit-box-shadow: 0 20px 45px rgba(0,0,0,0.09);
  box-shadow:0 20px 45px rgba(0,0,0,0.09);
  transition: var(--main-transition);
  max-width: 100%;
  width: 100%;
}

.stats .box::before, .stats .box::after {
  content: "";
  position: absolute;
  width: 2px;
  background-color: var(--main-color);
  transition: 1s;
  height: 0;
}

.stats .box::before {
  top: 0;
  right: 0;
}

.stats .box::after {
  left: 0;
  bottom: 0;
}

.stats .box:hover {
  opacity: 1;
  -webkit-box-shadow: 0 20px 45px rgba(0,0,0,0.09);
  box-shadow:0 20px 45px rgba(0,0,0,0.09);
}

.stats .box:hover::before, .stats .box:hover::after {
  height: 100%;
}

.stats .box .number {
  display: block;
  font-size: 34px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  color:var(--main-color2);
  background-color: var(--section-color);
}


.stats .box .text {
  color: var(--main-color);
  font-style: normal;
  font-size: 20px;
  font-weight: 600;
}

/* is dark mood */
.is-dark .stats .box .number {
    background-color: var(--section-color);

}
.is-dark .stats .box {
    background-color: var(--section-color);

} 


</style>