import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import store from "./store/index";
import routes from "@/routes/index";
import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css";
import "bulma";
import Lightbox from "vue-easy-lightbox";
window.$ = window.jQuery = require("jquery");
import "jquery/dist/jquery.min";
import "@/assets/css/style.css";
import "@/assets/css/css-variables/_all.scss";
import i18n from "@/i18n";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active-link",
  linkExactActiveClass: "active-link",
});

export const app = createApp(App).use(i18n);

app.AOS = new AOS.init({
  /* disable: window.innerWidth < 350
,*/
  delay: 0,
  duration: 1000,
});

app.use(router);
app.use(Lightbox);
app.use(store);
app.use(AOS).mount("#app");
