<template>

  <!-- choose Start -->
  <section class="features-boxed">
    <div class="container">

      <div class="row justify-content-center text-center">
        <div class="col-12">
          <!-- Heading Text -->
          <div class="section-heading text-center" data-aos-delay="150" data-aos-duration="1000"  data-aos="fade-up">
            <h2>Why choose us at work?</h2>
            <div class="line-title-center"></div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center features">
        <div class="col-sm-6 col-md-6 col-lg-4 item" data-aos-delay="150" data-aos-duration="1000"  data-aos="fade-up">
          <div class="box">
            <img class="rounded-circle img-fluid" src="@/assets/images/choose/Group1.png">
            <h3 class="name">Career Counselling<br></h3>
            <p class="description">Maiores voluptas laboriosam non<br>dolorum perferendis fuga repellat<br>aut. Blanditiis quos in minus.<br></p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 item" data-aos-delay="150" data-aos-duration="1000"  data-aos="fade-up">
          <div class="box">
            <img class="rounded-circle img-fluid" src="@/assets/images/choose/Group2.png">
            <h3 class="name">University Admissions<br></h3>
            <p class="description">Maiores voluptas laboriosam non<br>dolorum perferendis fuga repellat<br>aut. Blanditiis quos in minus.<br></p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6  col-lg-4 item" data-aos-delay="150" data-aos-duration="1000"  data-aos="fade-up">
          <div class="box">
            <img class="rounded-circle img-fluid" src="@/assets/images/choose/Group3.png">
            <h3 class="name">Visa Consultancy<br></h3>
            <p class="description">Maiores voluptas laboriosam non<br>dolorum perferendis fuga repellat<br>aut. Blanditiis quos in minus.<br></p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 item" data-aos-delay="150" data-aos-duration="1000"  data-aos="fade-up">
          <div class="box">
            <img class="rounded-circle img-fluid" src="@/assets/images/choose/Group4.png">
            <h3 class="name">Accommodation<br></h3>
            <p class="description">Maiores voluptas laboriosam non<br>dolorum perferendis fuga repellat<br>aut. Blanditiis quos in minus.<br></p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 item" data-aos-delay="150" data-aos-duration="1000"  data-aos="fade-up">
          <div class="box">
            <img class="rounded-circle img-fluid" src="@/assets/images/choose/Group5.png">
            <h3 class="name">Pre-Departure Briefing<br></h3>
            <p class="description">Maiores voluptas laboriosam non<br>dolorum perferendis fuga repellat<br>aut. Blanditiis quos in minus.<br></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- choose End -->

</template>

<script>
export default {
  name: "ChooseApp",

}
</script>

<style scoped>
.features{
  padding-bottom: 40px;
}
@media (min-width:767px) {
  .features {
    margin-left: 100px;
    margin-right: 100px;
  }
}

.features-boxed {
  /* color: #313437; */
  background-color: var(--section-color2);
}

.features-boxed h2 {
  margin-bottom: 1px;
  padding-top: 15px;
  font-size: 24px;
  color: var(--main-color);
}

@media (max-width:767px) {
  .features-boxed h2 {
    margin-bottom: 10px;
    padding-top: 10px;
    font-size: 24px;
    color: var(--main-color);
  }
}

.features-boxed .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0px auto;
}

.features-boxed .features {
}

.features-boxed .item {
  text-align: center;
  margin-bottom: 40px;
}

.features-boxed .item .box {
  text-align: start;
  padding: 15px;
  background-color: var(--section-color);
  margin-bottom: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0 20px 45px rgba(0,0,0,0.09);
  box-shadow:0 20px 45px rgba(0,0,0,0.09);

  height: 100%;
}
@media (max-width:767px) {
  .features-boxed .item .box {
    text-align: start;
    padding: 15px;
    background-color: var(--section-color);
    border-radius: 5px;
    -webkit-box-shadow: 0 20px 45px rgba(0,0,0,0.09);
    box-shadow:0 20px 45px rgba(0,0,0,0.09);
    /*max-width: 300px;*/
    height: 100%;
    /*width: 75%;*/
    margin: auto;
  }
}
@media (min-width:767px) {
  .features-boxed .item .box {
    text-align: start;
    padding: 15px;
    background-color: var(--section-color);
    margin-bottom: 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0 20px 45px rgba(0,0,0,0.09);
    box-shadow:0 20px 45px rgba(0,0,0,0.09);
    max-width: 300px;
    height: 100%;
  }
}

.features-boxed .item img {
  margin-top: 5px;
  margin-bottom: 0px;
  max-width: 76px;
  max-height: 76px;
}

.features-boxed .item .name {
  font-size: 20px;
  margin-bottom: 0px;
  margin-top: 10px;
  color: var(--main-color3);
}

.features-boxed .item .description {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.learn-more {
  text-decoration: none;
}

.text-center.myTitle.title-work {
  margin-bottom: 35px;
}


</style>