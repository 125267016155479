<template>


      <div id="main_container" class="swiper mySwiper_intro main_banner_area_iter">
        <div class="swiper-wrapper">


          <div class="swiper-slide">
            <div class="container">
              <div class="row info-intro">
                <div class="over-canvas" ></div>
        <div class="col-12 col-lg-5 col-md-6 mr-md-auto">

              <div class="info">
                <h1 class="display-4 mb-3 bold">{{ $t("title_IterStudent") }}</h1>
                <p class="mb-5">"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.</p>
                <div class="slid-btn">
                  <div class="app-download-area">
                  <a   href="#"><img class="img-fluid" src="@/assets/images/img-download/download.png"></a>
                  <a   href="#"><img class="img-fluid" src="@/assets/images/img-download/downloadAppStore.png"></a>
                  </div>
                  </div>

              </div>
        </div>
        <div class="col-12 col-lg-5 col-md-6">
          <div class="video-my wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.5s" >
            <div class="app-img">
              <img src="@/assets/images/scr-img/app-5.png"  alt="image">
            </div>
            <div class="video-play-btn">
              <a class="video_btn glightbox"><i class="icon-control-play" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
            </div>
          </div>

          <div class="swiper-slide">
            <div class="container">
            <div class="row info-intro">

              <div class="over-canvas" ></div>
              <div class="col-12 col-lg-5 col-md-6 mr-md-auto">

                <div class="info">
                  <h1 class="display-4 mb-3 bold">{{ $t("title_IterTeacher") }}</h1>
                  <p class="mb-5">"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.</p>
                  <div class="slid-btn">
                    <div class="app-download-area">
                    <a   href="#"><img class="img-fluid" src="@/assets/images/img-download/download.png"></a>
                    <a  href="#"><img class="img-fluid" src="@/assets/images/img-download/downloadAppStore.png"></a>
                    </div>
                    </div>
                </div>
              </div>
              <div class="col-12 col-lg-5 col-md-6">
                <div class="video-my wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.5s" >
                  <div class="app-img">
                    <img src="@/assets/images/scr-img/app-5.png"  alt="image">
                  </div>
                  <div class="video-play-btn">
                    <a class="video_btn glightbox"><i class="icon-control-play" aria-hidden="true"></i></a>
                  </div>
                </div>
              </div>

            </div>
            </div>
          </div>



          <div class="swiper-slide">
            <div class="container">
              <div class="row info-intro">

                <div class="over-canvas" ></div>
                <div class="col-12 col-lg-5 col-md-6 mr-md-auto">

                  <div class="info">
                    <h1 class="display-4 mb-3 bold">{{ $t("title_IterAdmin") }}</h1>
                    <p class="mb-5">"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.</p>

                    <div class="slid-btn">
                      <div class="app-download-area">
                      <a   href="#"><img class="img-fluid" src="@/assets/images/img-download/download.png"></a>
                      <a   href="#"><img class="img-fluid" src="@/assets/images/img-download/downloadAppStore.png"></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-5 col-md-6">
                  <div class="video-my wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.5s" >
                    <div class="app-img">

                      <div class="device">
                        <img class="macbook" src="@/assets/images/bg-img/macbook.png" >
                        <img class="screen" src="@/assets/images/bg-img/screenweb.png" >
                      </div>
                    </div>
                    <div class="video-play-btn">
                      <a class="video_btn glightbox"><i class="icon-control-play" aria-hidden="true"></i></a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
<!--    </div>

  </section>-->

</template>

<script>
import Swiper, { Navigation ,Autoplay ,EffectCoverflow} from 'swiper';
Swiper.use([Navigation]);
Swiper.use([Autoplay]);
Swiper.use([EffectCoverflow]);
import 'swiper/css';
import "swiper/css/navigation";

import  "glightbox/dist/css/glightbox.css";
import  "glightbox/dist/js/glightbox.js";
import GLightbox from 'glightbox';
export default {
  name: "IntroSlider",
  components: {

  },
  mounted(){
    //lightbox settings
    this.lightbox = GLightbox({
      selector: ".glightbox",
      'touchNavigation': true,
      'href': 'https://www.youtube.com/watch?v=DbSPTfPUyoE',
      'type': 'video',
      'source': 'youtube', //vimeo, youtube or local
      'width': 900,
      'autoPlayVideos': 'true',
    });

    var mySwiper_intro = new Swiper(".mySwiper_intro", {
      /*direction: "vertical",*/
      watchSlidesProgress:true,
      loop:true,
      speed:1500,
      spaceBetween: 4000,
      allowTouchMove:false,
      autoplay: {
        delay:7000,
      },
      effect: 'coverflow',
      coverflowEffect: {
        rotate: 60,
        slideShadows: false,
        depth:1000,
      },



    });
    return mySwiper_intro;

  }
}
</script>

<style scoped>
/*.swiper-slide {
  opacity: .25;
}*/
/*.swiper-slide-visible {
  opacity: 1;
}*/
.over-canvas{
  top:0;
  display: block;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0px;
  left:0px;
  margin: 0 auto;
  position: absolute;
  z-index: 0;
  opacity: 0%;
  background: #F1EFEB;
}
.info-intro{
  width: 100%;
  height: 100%;
  position: relative;
  background:transparent;
  z-index: 20;
}
.main_banner_area_iter{
  padding: 100px 0 0;
  min-height: 640px;
  position: relative;
  z-index: 1;
  background-position: bottom center;
  background-size: cover;
}
.main_banner_area_iter:after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
 /* background-color: #f8f9fa!important;*/
  background: #F1EFEB;
  /*  background-image: -linear-gradient(0deg, #43cea2 0%, #185a9d 100%);*/
  opacity: 1;
}

.info{
  padding: 50px 0px;
}

.info > p{
  color: #636d75;
}
.info > h1{
/*  color: #f1f2f3;*/
}

/*.info button{
  color: #fff;
  padding: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 0 !important;
}

.info button:hover {
  background-color: #ffffff;
  color: #43cea2;
  border: 1px solid #ffffff;
  border-radius: 0 !important;
}*/
.app-img{
  width: 100%;
  display: flex;
  justify-content: center;
}
.video-my {

   position: relative;
  z-index: 1;
  height: 500px;
  width: 100%;
  border-radius: 2px;
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.main_banner_area .video-my {
  margin-top: 0px;
  position: relative;
  z-index: 1;
  height: 500px;
  width: 100%;
  border-radius: 2px;

}

.video-my:after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  /* background-color: rgba(67, 206, 162, 0.50); */
  opacity: .6;
  border-radius: 30px;
}

.video-my .video-play-btn a {
  width: 80px;
  height: 80px;
  background-color: #41c9a2;
  color: #fff;
  text-align: center;
  display: block;
  font-size: 36px;
  line-height: 86px;
  padding-left: 5px;
  border-radius: 50%;
  top: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
}


.video-my .video-play-btn a:hover {
  background-color: #fff;
  color: #41c9a2;
  cursor: pointer;
}





.info button:hover {
  background-color: #ffffff;
  color: #43cea2;
  border: 1px solid #ffffff;
  border-radius: 0 !important;

}
@media (min-width:1440px) {
  .main_banner_area{
    padding: 150px 0 0px;
    min-height: 830px;
    position: relative;
    z-index: 1;
    background-position: bottom center;
    background-size: cover;
  }
}
@media (min-width:1920px) {
  .main_banner_area{
    padding: 200px 0 0px;
    min-height: 750px;
    position: relative;
    z-index: 1;
    background-position: bottom center;
    background-size: cover;
  }
}
.slid-btn {
  margin-top: 25px;
  padding: 0;
}

.img-fluid{
  max-width: 100%;
  height: auto;
}
.app-download-area {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 1;
  flex-basis: 1px;
  margin: 45px 0 30px;
  align-items: flex-start;
  justify-content:flex-start ;
}

.slid-btn a{
  padding: 0;
  margin-inline-end: 15px;
  -webkit-box-shadow: 0 20px 45px rgba(0,0,0,0.2);
  box-shadow:0 15px 45px rgba(0,0,0,0.1);
}
.slid-btn>a img{width:120px}
.slid-btn img {
  width: 120px;
}
.device{
  display: inline-grid;
  position: relative;
  width: 100%;
  height: 400px;

}

.macbook{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.screen {
  position: absolute;
  top: 7%;
  left: 12.5%;
  right: 12.5%;
  z-index: 2;
  height: 85%;
  width: 75%;
}
@media(max-width:575px){
  .slid-btn{margin-top:30px;margin-bottom:50px}
}
@media(max-width:480px){
 .slid-btn{margin-bottom:15px}
}
@media (max-width: 1366px){
  .slid-btn>a img {
    width: 120px;
  }

}
@media (max-width: 1199px) {

  .slid-btn img{
    width: 120px;
  }
}
</style>