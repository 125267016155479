<template>
  <div class="iter_learning">
    <WaitApp :title="title" :description="description"/>
    <SoonApp/>
  </div>
</template>

<script>
import WaitApp from "@/components/sections/Wait";
import SoonApp from "@/components/sections/Soon";
export default {
  name: "IterLearningView",
  components: {SoonApp, WaitApp},
  data() {
    return {
      title: 'Iter Learning', description: "Soon the service will be launched, wait for us."
    }
  }
}
</script>

<style scoped>

</style>