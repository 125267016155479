<template>
  <section id="CriteriaRight">
    <div class="container">

      <div class="row">
       <div class="col-12 col-lg-5">
          <div class="criteriaRight-box">
            <h2>Entry criteria</h2>
            <p class="mt-3 mb-3">Lorem ipsum dolor sit amet, consectetur Proin gravida nibh vel velit auctor aliquet.</p>
            <ul>
              <li>
                <figure class="criteria-icon">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M30.3964 2.01275C29.5527 1.306 28.0919 0.0958016 27.8149 0.00221321C27.4402 -0.126874 23.8702 5.43357 23.7253 5.50779C23.719 5.51102 23.6561 5.58525 23.5427 5.72079C23.4326 5.85633 23.2689 6.05642 23.0642 6.30491C22.6549 6.81158 22.0725 7.53124 21.3705 8.40258C19.9727 10.1485 18.0585 12.4947 16.1098 15.0054C15.137 16.2608 14.1547 17.5517 13.2229 18.8167C12.9836 19.1427 12.7758 19.4137 12.4956 19.8301C12.2501 20.198 12.0391 20.54 11.8376 20.8757C11.4378 21.5437 11.0726 22.1859 10.7326 22.7958C10.2195 23.7156 9.75981 24.5676 9.35684 25.3227C9.31276 25.2517 9.27184 25.1807 9.23406 25.1194C8.85627 24.5224 8.71145 24.3417 8.68942 24.3191C8.60126 24.2384 8.26755 23.8737 7.79847 23.3186C7.32938 22.7668 6.72177 22.031 6.07009 21.2984C5.42785 20.5691 4.68172 19.8526 4.1843 19.3879C3.668 18.8974 3.2965 18.5553 3.22095 18.5069C2.91557 18.3133 1.95536 17.3387 1.32572 17.3064C1.01089 17.2967 0.0223499 18.068 0.000312277 18.4198C-0.0122806 18.6263 0.35921 19.1814 0.532362 19.4525C0.551252 19.4783 1.29109 20.4109 2.69205 22.1762C3.33114 22.9895 4.04893 24.0899 4.94618 25.5034C5.176 25.8552 5.41526 26.2199 5.66082 26.5975C5.86861 26.9137 6.08268 27.2816 6.31565 27.685C6.54862 28.0917 6.80048 28.5402 7.10586 29.0211C7.18456 29.1437 7.26642 29.2663 7.34512 29.389C7.42383 29.5084 7.52142 29.6472 7.65365 29.815C7.78587 29.9828 7.96532 30.1796 8.17625 30.3571C8.38719 30.5346 8.61701 30.6799 8.82794 30.7928C9.6874 31.2478 10.7389 30.9283 11.2237 30.0796L11.2458 30.0441C11.7715 29.1147 12.3508 28.0917 12.9647 27.0073C13.5723 25.9423 14.2208 24.8193 14.8946 23.7059C15.2283 23.1605 15.584 22.5893 15.9051 22.1375L16.0216 21.9761L16.1727 21.7793L16.4781 21.3791L17.092 20.582C17.9137 19.5202 18.7448 18.4714 19.5539 17.4613C21.191 15.4443 22.7116 13.5661 23.9237 12.0719C25.1389 10.568 26.0361 9.43528 26.4108 8.9157C28.5516 5.95637 30.6703 3.06481 30.9883 2.71628C31.0733 2.61946 30.6766 2.24834 30.3964 2.01275Z" fill="#F591AF"/>
                  </svg>
                </figure>

                Deeply technical, preferably leveraging technologies like Machine Learning and AI
              </li>
              <li>
                <figure class="criteria-icon">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M30.3964 2.01275C29.5527 1.306 28.0919 0.0958016 27.8149 0.00221321C27.4402 -0.126874 23.8702 5.43357 23.7253 5.50779C23.719 5.51102 23.6561 5.58525 23.5427 5.72079C23.4326 5.85633 23.2689 6.05642 23.0642 6.30491C22.6549 6.81158 22.0725 7.53124 21.3705 8.40258C19.9727 10.1485 18.0585 12.4947 16.1098 15.0054C15.137 16.2608 14.1547 17.5517 13.2229 18.8167C12.9836 19.1427 12.7758 19.4137 12.4956 19.8301C12.2501 20.198 12.0391 20.54 11.8376 20.8757C11.4378 21.5437 11.0726 22.1859 10.7326 22.7958C10.2195 23.7156 9.75981 24.5676 9.35684 25.3227C9.31276 25.2517 9.27184 25.1807 9.23406 25.1194C8.85627 24.5224 8.71145 24.3417 8.68942 24.3191C8.60126 24.2384 8.26755 23.8737 7.79847 23.3186C7.32938 22.7668 6.72177 22.031 6.07009 21.2984C5.42785 20.5691 4.68172 19.8526 4.1843 19.3879C3.668 18.8974 3.2965 18.5553 3.22095 18.5069C2.91557 18.3133 1.95536 17.3387 1.32572 17.3064C1.01089 17.2967 0.0223499 18.068 0.000312277 18.4198C-0.0122806 18.6263 0.35921 19.1814 0.532362 19.4525C0.551252 19.4783 1.29109 20.4109 2.69205 22.1762C3.33114 22.9895 4.04893 24.0899 4.94618 25.5034C5.176 25.8552 5.41526 26.2199 5.66082 26.5975C5.86861 26.9137 6.08268 27.2816 6.31565 27.685C6.54862 28.0917 6.80048 28.5402 7.10586 29.0211C7.18456 29.1437 7.26642 29.2663 7.34512 29.389C7.42383 29.5084 7.52142 29.6472 7.65365 29.815C7.78587 29.9828 7.96532 30.1796 8.17625 30.3571C8.38719 30.5346 8.61701 30.6799 8.82794 30.7928C9.6874 31.2478 10.7389 30.9283 11.2237 30.0796L11.2458 30.0441C11.7715 29.1147 12.3508 28.0917 12.9647 27.0073C13.5723 25.9423 14.2208 24.8193 14.8946 23.7059C15.2283 23.1605 15.584 22.5893 15.9051 22.1375L16.0216 21.9761L16.1727 21.7793L16.4781 21.3791L17.092 20.582C17.9137 19.5202 18.7448 18.4714 19.5539 17.4613C21.191 15.4443 22.7116 13.5661 23.9237 12.0719C25.1389 10.568 26.0361 9.43528 26.4108 8.9157C28.5516 5.95637 30.6703 3.06481 30.9883 2.71628C31.0733 2.61946 30.6766 2.24834 30.3964 2.01275Z" fill="#F591AF"/>
                  </svg>
                </figure>

                Deeply technical, preferably leveraging technologies like Machine Learning and AI
              </li>
              <li>
                <figure class="criteria-icon">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M30.3964 2.01275C29.5527 1.306 28.0919 0.0958016 27.8149 0.00221321C27.4402 -0.126874 23.8702 5.43357 23.7253 5.50779C23.719 5.51102 23.6561 5.58525 23.5427 5.72079C23.4326 5.85633 23.2689 6.05642 23.0642 6.30491C22.6549 6.81158 22.0725 7.53124 21.3705 8.40258C19.9727 10.1485 18.0585 12.4947 16.1098 15.0054C15.137 16.2608 14.1547 17.5517 13.2229 18.8167C12.9836 19.1427 12.7758 19.4137 12.4956 19.8301C12.2501 20.198 12.0391 20.54 11.8376 20.8757C11.4378 21.5437 11.0726 22.1859 10.7326 22.7958C10.2195 23.7156 9.75981 24.5676 9.35684 25.3227C9.31276 25.2517 9.27184 25.1807 9.23406 25.1194C8.85627 24.5224 8.71145 24.3417 8.68942 24.3191C8.60126 24.2384 8.26755 23.8737 7.79847 23.3186C7.32938 22.7668 6.72177 22.031 6.07009 21.2984C5.42785 20.5691 4.68172 19.8526 4.1843 19.3879C3.668 18.8974 3.2965 18.5553 3.22095 18.5069C2.91557 18.3133 1.95536 17.3387 1.32572 17.3064C1.01089 17.2967 0.0223499 18.068 0.000312277 18.4198C-0.0122806 18.6263 0.35921 19.1814 0.532362 19.4525C0.551252 19.4783 1.29109 20.4109 2.69205 22.1762C3.33114 22.9895 4.04893 24.0899 4.94618 25.5034C5.176 25.8552 5.41526 26.2199 5.66082 26.5975C5.86861 26.9137 6.08268 27.2816 6.31565 27.685C6.54862 28.0917 6.80048 28.5402 7.10586 29.0211C7.18456 29.1437 7.26642 29.2663 7.34512 29.389C7.42383 29.5084 7.52142 29.6472 7.65365 29.815C7.78587 29.9828 7.96532 30.1796 8.17625 30.3571C8.38719 30.5346 8.61701 30.6799 8.82794 30.7928C9.6874 31.2478 10.7389 30.9283 11.2237 30.0796L11.2458 30.0441C11.7715 29.1147 12.3508 28.0917 12.9647 27.0073C13.5723 25.9423 14.2208 24.8193 14.8946 23.7059C15.2283 23.1605 15.584 22.5893 15.9051 22.1375L16.0216 21.9761L16.1727 21.7793L16.4781 21.3791L17.092 20.582C17.9137 19.5202 18.7448 18.4714 19.5539 17.4613C21.191 15.4443 22.7116 13.5661 23.9237 12.0719C25.1389 10.568 26.0361 9.43528 26.4108 8.9157C28.5516 5.95637 30.6703 3.06481 30.9883 2.71628C31.0733 2.61946 30.6766 2.24834 30.3964 2.01275Z" fill="#F591AF"/>
                  </svg>
                </figure>

                Deeply technical, preferably leveraging technologies like Machine Learning and AI
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-lg-7">
          <div class="parent">

            <div class="child1" >
              <img src="@/assets/images/events/ev6.jpg">
            </div>

            <div class="child2" data-speed="0.15" style="transform: translate3d(0px, 0px, 0px);" >
              <img src="@/assets/images/bg-mobile/feature-mob.png">
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "CriteriaRight",
  mounted() {


/*    gsap.set(".child1", {});

    gsap.utils.toArray("[data-speed]").forEach(el => {

      gsap.to(el, {
        scrollTrigger: {
          trigger:el,
          scrub: true,
          start: 'top center',
        },
        duration: 2,
        y: 70,
        stagger: 0.15,
        animation:"easeInOut"
      });


      gsap.to(el, {
        scrollTrigger: {
          trigger:el,
          scrub: true,
          start: 'bottom center',
        },
        duration: 2,
        y: -70,
        stagger: 0.15,
        animation:"easeInOut"
      });

    });*/

    gsap.set(".child2", {});

    gsap.utils.toArray("[data-speed]").forEach(el => {

      gsap.to(el, {
        scrollTrigger: {
          trigger:el,
          scrub: true,
          start: 'top center',
        },
        duration: 0.1,
        y: -100,
        stagger: 0.1,
        animationTimingFunction:1000,
        animationPlayState:5000,
        animationDelay:1000,
        webkitAnimationDelay:1000,
        webkitAnimation:"easeIn",
        animation:"easeIn"
      });



    });
  }
}
</script>

<style scoped>
#CriteriaRight{
  padding-bottom: 50px;
  padding-top: 50px;
}
#CriteriaRight .parent{
  height: 600px;
  width: 100%;
  position: relative;

}
#CriteriaRight .parent .child1{
  position: absolute;
  right: 0;
  top: 5px;
  width: 75%;
  height: 60%;
}
#CriteriaRight .parent .child2{
  position: absolute;
  left: 0;
  top: 30%;
  width: 50%;
  height: 60%;
}

.criteriaRight-box{
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}
.criteriaRight-box ul{
  color: #5f6368;
  list-style-type: none;
  margin-left: 0;
  margin-top: 20px;

}
.criteriaRight-box ul li {
  -ms-align-items: flex-start;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
  color: #5f6368;
}
.criteriaRight-box ul li figure {
  margin-inline-end: 20px;
}

</style>