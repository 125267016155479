
<template>
  <div id="SwiperRight" class="container text-dark">
    <div class="row">
      <div class="col-md-12">
        <!-- Heading Text  -->
        <div class="section-heading text-center">
          <h2>{{ title }}</h2>
          <div class="line-title-center"></div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-12 col-lg-6">
        <div class="d-flex d-sm-flex justify-content-center parent">
          <div class="child1"></div>
          <div class="swiper mySwiper_iter_Right position-relative">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="child2">
                  <img src="@/assets/images/bg-img/app1.png" width="100%" height="100%">
                </div>
              </div>
              <div class="swiper-slide">

                <div class="child2">
                  <img src="@/assets/images/bg-img/app111.png" width="100%" height="100%">
                </div>
              </div>

            </div>
            <div class=" d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center align-items-lg-center child3_left"><i class="fas fa-angle-left"></i></div>
            <div class="d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center align-items-lg-center child3_right"><i class="fas fa-angle-right"></i></div>
          </div>



        </div>



      </div>
      <div class="col-12 col-lg-6 mb-4 pt-50">
        <h1>Design To Do List app</h1>
        <p>The previous student design project was the To Do List application product factory, The counter examples are the result of the efforts of the students and their teamwork in the first course.</p>
        <ul class="list-unstyled">
          <li class="d-table"><i class="fa fa-check-circle d-table-cell" style="color: var(--main-color);font-size: 20px;"></i><span class="d-table-cell">Experience teamwork in a real product team and a collaborative effort for a common goal.</span></li>
          <li class="d-table"><i class="fa fa-check-circle d-table-cell" style="color: var(--main-color);font-size: 20px;"></i><span class="d-table-cell">Survey real users and needs assessment and find the most suitable solution to their problems.</span></li>
          <li class="d-table"><i class="fa fa-check-circle d-table-cell" style="color: var(--main-color);font-size: 20px;"></i><span class="d-table-cell">Design experience from notebooks to user interface design software.</span></li>
        </ul>
      </div>
    </div>

  </div>

</template>

<script>
import Swiper, { Navigation ,Autoplay } from 'swiper';
Swiper.use([Navigation]);
Swiper.use([Autoplay]);

import 'swiper/css';
import "swiper/css/navigation";
export default {
  name: "SwiperRightApp",
  props:['title'],
  mounted() {
    var mySwiper_iter_Right = new Swiper(".mySwiper_iter_Right", {

      loop:true,
      speed:1000,
      autoplay: {
        delay: 7000,

      },
      navigation: {
        nextEl: ".child3_left",
        prevEl: ".child3_right",
      },

    });
    return mySwiper_iter_Right;
  }
 /* mounted() {
    var mySwiper_iter = new Swiper(".mySwiper_iter", {

      loop:true,
      speed:500,
      autoplay: {
        delay: 7000,

      },
      navigation: {
        nextEl: ".child3_left_left",
        prevEl: ".child3_right_left",
      },
    });
    return mySwiper_iter;
  }*/
}
</script>

<style scoped>


.parent {
  width: 100%;
  height: 100%;
  min-height: 700px;
  position: relative;
  border-radius: 30px;
}

.swiper.mySwiper_iter_Right {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

#SwiperRight p{
  color: #1b1e21;
}
#SwiperRight span{
  color: #1b1e21;
  margin-bottom: 20px;
}

.child1 {
  width: 100%;
  height: 60%;
  border-radius: 30px;
  background-color: #F5F5F5;
  position: absolute;
  top: 0px;
  right: 0px;
}

.child2 {
  width: 75%;
  max-width: 327px;
  height: 89%;
  min-height: 612px;
  border-radius: 30px;
  position: absolute;
  left:20%;
  right:20%;
  top: 50px;
  margin: auto;
}

@media (max-width: 1200px) {
  .child2 {
    width: 75%;
    max-width: 327px;
    height: 89%;
    min-height: 612px;
    border-radius: 30px;
    position: absolute;
    left:12%;
    right:12%;
    margin: auto;
    top: 50px;
  }
}

.child2 img {
  border-radius: 30px;
  width: 100%;
  height: 100%;

}

.child3_left{
  width: 30px;
  height: 30px;
  position: absolute;
  left: 5px;
  border-radius: 10px;
  color: white;
  background-color: rgba(67, 206, 162, 0.95);
  box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
  bottom: 10%;
  font-size: inherit;
  z-index: 1;
  cursor: pointer;
}

@media (min-width:1200px) {
  .child3_left {
    width: 30px;
    height: 30px;
    position: absolute;
    color: white;
    background-color: rgba(67, 206, 162, 0.95);
    box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
    bottom: 20%;
    left: 10%;
    border-radius: 10px;
    border: 0px;
    font-size: inherit;
    z-index: 1;
  }
}

.child3_right {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 10%;
  right: 5px;
  border-radius: 10px;
  color: white;
  background-color: rgba(67, 206, 162, 0.95);
  box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
  font-size: inherit;
  z-index: 1;
  cursor: pointer;
}

@media (min-width:1200px) {
  .child3_right {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20%;
    right: 10%;
    border-radius: 10px;
    color: white;
    background-color: rgba(67, 206, 162, 0.95);
    box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
    font-size: inherit;
    z-index: 1;
    cursor: pointer;
  }
}

.list-unstyled  li i{
  padding-inline-end: 10px;
}
.list-unstyled  li{
  margin-bottom: 20px;
}
/*
.d-inline .number{
  background-color: #F5F5F5;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 12px;
  font-size: 24px;
  font-weight: 400;
}

.parent {
  width: 100%;
  height: 100%;
  min-height: 700px;
  position: relative;
  border-radius: 30px;
}

.swiper.mySwiper_iter {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

#SwiperRight p{
  color: #1b1e21;
}
#SwiperRight span{
  color: #1b1e21;
  margin-bottom: 20px;
}


.child1 {
  width: 100%;
  height: 60%;
  border-radius: 30px;
  background-color: #F5F5F5;
  position: absolute;
  top: 0px;
  right: 0px;
}

.child2 {
  width: 75%;
  max-width: 327px;
  height: 89%;
  min-height: 612px;
  border-radius: 30px;
  !*background-color: white;*!
  position: absolute;
  top: 50px;
  !* box-shadow: 2px 2px 5px #d0d0d0;*!
}

.child2 img {
  border-radius: 30px;
  width: 100%;
  height: 100%;
}


.child3_left_left {
  width: 6%;
  height: 5%;
  position: absolute;
  bottom: 53%;
  left: calc(100% - 99%);
  border-radius: 10px;
  color: white;
  background-color: rgba(67, 206, 162, 0.95);
  box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
  font-size: inherit;
  z-index: 1;
  cursor: pointer;
}

@media (min-width:850px) {
  .child3_left_left {
    width: 6%;
    height: 5%;
    position: absolute;
    left: calc(100% - 99%);
    border-radius: 10px;
    color: white;
    background-color: rgba(67, 206, 162, 0.95);
    box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
    bottom: 20%;
    font-size: inherit;
    z-index: 1;
    cursor: pointer;
  }
}

.child3_right_left {
  width: 6%;
  height: 5%;
  position: absolute;
  bottom: 53%;
  right: 1%;
  border-radius: 10px;
  color: white;
  background-color: rgba(67, 206, 162, 0.95);
  box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
  font-size: inherit;
  z-index: 1;
  cursor: pointer;
}

@media (min-width:850px) {
  .child3_right_left {
    width: 6%;
    height: 5%;
    position: absolute;
    right: 52%;
    border-radius: 10px;
    color: white;
    background-color: rgba(67, 206, 162, 0.95);
    box-shadow: 0px 0px 5px rgba(60, 64, 62, 0.5);
    bottom: 20%;
    font-size: inherit;
    z-index: 1;
    cursor: pointer;
  }
}*/
</style>