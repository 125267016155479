import Vuex from "vuex";

export default new Vuex.Store({
  state: {
    isDark: false
  },
  mutations: {
      setIsDark(state, payload)
      {
          if (payload !== undefined)
              state.isDark=payload
      }
  },
  actions: {
      setIsDarkAction(context, payload)
    {
          context.commit('setIsDark',payload)
      }
  },
  getters: {
    getIsDark(state) {
      const isDark = state.isDark;
          return isDark
    }
  },
});
