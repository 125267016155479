<template>
  <!-- ***** Our Features Section ***** -->
  <section id="features-iter" class="theme-bg feature">

    <div class="container position-relative">
       <div class="animation-circle-inverse">
          <i></i>
          <i></i>
          <i></i>
       </div>
      <div class="row">
        <div class="col-md-12 text-center mb-5">
          <div class="text-center"   data-aos="fade-up">
            <h2 class="title-featuresIter">Chat Loop Feature</h2>
          </div>
        </div>

        <div class="col-lg-4 col-12">
            <div class="future-box">
              <div class="future-timeline">
                <ul>
                  <li class="timeline"  data-aos="fade-up">
                    <h4 class="sub-title">Easy Installation</h4>
                    <p>Lorem Ipsum has been the industry's</p>
                  </li>
                  <li class="timeline"  data-aos="fade-up">
                    <h4 class="sub-title">Multi Platform Chat App</h4>
                    <p>Lorem Ipsum has been the industry's</p>
                  </li>
                  <li class="timeline"  data-aos="fade-up">
                    <h4 class="sub-title">Secure Backup & Recovery</h4>
                    <p>Lorem Ipsum has been the industry's</p>
                  </li>
                  <li class="timeline"  data-aos="fade-up">
                    <h4 class="sub-title">Easy File Sharing</h4>
                    <p>Lorem Ipsum has been the industry's</p>
                  </li>
                </ul>
              </div>
            </div>
        </div>

        <div class="col-md-4 future-mobile"  data-aos="fade-up">
          <img class="img-fluid" src="@/assets/images/bg-mobile/feature-mob.png" >

        </div>

        <div class="col-lg-4 col-12">
          <div class="future-box">
            <div class="future-timeline-right">
              <ul class="text-left">
                <li class="timeline-right"  data-aos="fade-up">
                  <h4 class="sub-title">Easy Installation</h4>
                  <p>Lorem Ipsum has been the industry's</p>
                </li>
                <li class="timeline-right"  data-aos="fade-up">
                  <h4 class="sub-title">Multi Platform Chat App</h4>
                  <p>Lorem Ipsum has been the industry's</p>
                </li>
                <li class="timeline-right"  data-aos="fade-up">
                  <h4 class="sub-title">Secure Backup & Recovery</h4>
                  <p>Lorem Ipsum has been the industry's</p>
                </li>
                <li class="timeline-right"  data-aos="fade-up">
                  <h4 class="sub-title">Easy File Sharing</h4>
                  <p>Lorem Ipsum has been the industry's</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: "FeaturesIter"
}
</script>

<style scoped>
#features-iter{
  background: linear-gradient(90deg,#5f57ea 0,#9647db);
  padding: 50px 0;
}
.landing-page .theme-bg {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
  padding-top: 65px;
}
.theme-bg {
  background: linear-gradient(90deg,#5f57ea 0,#9647db);
}


.title-featuresIter {
  text-align: center;
  padding-bottom: 35px;
  position: relative;
  display: inline-block;
  font-weight: 300;
  color: #fff;
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;

}

.title-featuresIter::before {
  content: "";
  background: #fff;
  width: 70px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 10px;
  margin: auto;
}

.title-featuresIter:after {
  content: "";
  background: #fff;
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}

.feature .animation-circle-inverse i{top:52%;left:0;right:0;margin:0 auto}
.feature .row{
  align-items:center;
  justify-content: center;
}
.future-box{padding:60px 10px}
.future-mobile{margin-bottom:-33px;text-align:center}
.future-timeline{text-align:right}

.future-timeline:after{content:"";
  background-color:hsla(0,0%,100%,.3);
  position:absolute;height:100%;width:1px;right:9px;
  background-size:cover;border-radius:12px;top:0}

.timeline:last-child p{margin-bottom:0}

.timeline h4{color:#fff!important;margin-top:0}

.timeline p{color:#fff;margin-bottom:55px;line-height:1.8em;margin-left:12px;font-weight:300;margin-right:15px}

.timeline:before{content:"";background:#9647db;position:relative;height:12px;width:12px;right:-21px;background-size:cover;top:15px;border-radius:50%;z-index:2;float:right;padding:0;border:3px solid hsla(0,0%,100%,.9)}

.timeline-right h4{color:#fff!important;margin-top:0}

.timeline-right p{color:#fff;margin-bottom:55px;margin-left:12px;line-height:1.8em;font-weight:300}
.timeline-right:last-child p{margin-bottom:0}
.timeline-right:before{content:"";background:#9647db;position:relative;height:12px;width:12px;left:-21px;top:15px;border-radius:50%;z-index:2;float:left;padding:0;border:3px solid hsla(0,0%,100%,.9)}

.future-timeline-right:after{
  content:"";
  background-color:hsla(0,0%,100%,.3);
  position:absolute;
  height:100%;
  width:1px;
  left:9px;
  background-size:cover;
  border-radius:12px;
  top:0}
.text-left {
  text-align: left!important;
}
#features-iter ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}




.animation-circle-inverse{opacity:.3}
.download-bg .display-flex{justify-content:center}
 .animation-circle-inverse{opacity:.25}
 .animation-circle-inverse.absolute i{bottom:280px;left:0}

.feature .animation-circle-inverse i {
  top: 52%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.animation-circle-inverse i {
  background: #eee;
  right: 0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0 , 0.1);
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  opacity: .3;
  transform: scale(1.3);
  -webkit-animation: ripple1 3s linear infinite;
  animation: ripple1 3s linear infinite;
}
.animation-circle-inverse i:nth-child(2) {
  -webkit-animation: ripple2 3s linear infinite;
  animation: ripple2 3s linear infinite;
}
.animation-circle-inverse i:nth-child(3) {
  -webkit-animation: ripple3 3s linear infinite;
  animation: ripple3 3s linear infinite;
}

@keyframes ripple1{0%{transform:scale(4.5);opacity:.3}to{transform:scale(7.5);opacity:0}}
@-webkit-keyframes ripple1{0%{transform:scale(4.5);opacity:.3}to{transform:scale(7.5);opacity:0}}
@keyframes ripple2{0%{transform:scale(2.5)}to{transform:scale(4.5)}}
@-webkit-keyframes ripple2{0%{transform:scale(2.5)}to{transform:scale(4.5)}}
@keyframes ripple3{0%{transform:scale(1.5)}to{transform:scale(2.5)}}
@-webkit-keyframes ripple3{0%{transform:scale(1.5)}to{transform:scale(2.5)}}
@media (max-width:768px) {

  @keyframes ripple1{0%{transform:scale(2.5);opacity:.3}to{transform:scale(4);opacity:0}}
  @-webkit-keyframes ripple1{0%{transform:scale(2.5);opacity:.3}to{transform:scale(4);opacity:0}}

  @keyframes ripple2{0%{transform:scale(1.5)}to{transform:scale(4)}}
  @-webkit-keyframes ripple2{0%{transform:scale(1.5)}to{transform:scale(4)}}

  @keyframes ripple3{0%{transform:scale(1.5)}to{transform:scale(4)}}
  @-webkit-keyframes ripple3{0%{transform:scale(1.5)}to{transform:scale(4)}}
}
@media (max-width:400px) {

  @keyframes ripple1{0%{transform:scale(2.5);opacity:.3}to{transform:scale(3);opacity:0}}
  @-webkit-keyframes ripple1{0%{transform:scale(2.5);opacity:.3}to{transform:scale(3);opacity:0}}

  @keyframes ripple2{0%{transform:scale(1.5)}to{transform:scale(3)}}
  @-webkit-keyframes ripple2{0%{transform:scale(1.5)}to{transform:scale(3)}}
  @keyframes ripple3{0%{transform:scale(1.5)}to{transform:scale(3)}}
  @-webkit-keyframes ripple3{0%{transform:scale(1.5)}to{transform:scale(3)}}
}
</style>