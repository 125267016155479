<template>
  <div class="home">
    <MainContainerAPP/>
    <CoreServices/>
<!--    <ProductsApp/>-->
    <WorksApp/>
    <FeaturesApp/>
    <VideoAPP/>
    <!-- <AboutAPP/> -->
    <ThinkBigApp/>
    <TeamApp/>
    <ClientApp/>
    <StatesApp/>
    <BolgApp/>
    <SolutionsApp/>
    <FAQApp/>
    <ChooseApp/>
    <FrameworksApp/>
    <ContactApp/>
    <ProgramApp/>
  </div>
</template>

<script>
import { nextTick } from 'vue'
// @ is an alias to /src
import MainContainerAPP from '@/components/sections/MainContainer';
/*import ProductsApp from '@/components/sections/Products';*/
import WorksApp from '@/components/sections/Works';
import FeaturesApp from '@/components/sections/Features';
import VideoAPP from '@/components/sections/Video';
// import AboutAPP from '@/components/sections/About';
import TeamApp from '@/components/sections/Team';
import ClientApp from '@/components/sections/Client';
import StatesApp from '@/components/sections/States';
import BolgApp from '@/components/sections/Blog';
import FAQApp from '@/components/sections/FAQ';
import SolutionsApp from '@/components/sections/Solutions';
import ChooseApp from '@/components/sections/Choose';
import FrameworksApp from '@/components/sections/Frameworks';
import ContactApp from '@/components/sections/Contact';
import ProgramApp from '@/components/sections/Program';
import CoreServices from "@/components/sections/CoreServices";
import ThinkBigApp from "@/components/sections/ThinkBig";


export default {
  name: 'HomeView',
  components: {
    ThinkBigApp,
    CoreServices,
    MainContainerAPP,
   /* ProductsApp,*/
    WorksApp,
    FeaturesApp,
    VideoAPP,
    // AboutAPP,
    TeamApp,
    ClientApp,
    StatesApp,
    BolgApp,
    FAQApp,
    SolutionsApp,
    ChooseApp,
    FrameworksApp,
    ContactApp,
    ProgramApp

  }
  ,
  data() {
    return {
    }
  }
  ,methods: {
    async increment() {

      await nextTick()

    }

  }


}
</script>
<style>
.main_banner_area{
  padding: 130px 0 70px;

  min-height: 680px;
  position: relative;
  z-index: 1;
  background-position: bottom center;
  background-size: cover;
}
.main_banner_area:after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: -moz-linear-gradient(0deg, #43cea2 0%, #185a9d 100%);
  background-image: -webkit-linear-gradient(0deg, #43cea2 0%, #185a9d 100%);
  background-image: -ms-linear-gradient(0deg, #43cea2 0%, #185a9d 100%);
  background-image: linear-gradient(0deg, #43cea2 0%, #185a9d 100%);
  opacity: 1;
}

.main_banner_area .info{
  padding: 50px 0;
}

.info > p{
  color: #f1f2f3;
}

.info button{
  color: #fff;
  padding: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 0 !important;
}

.info button:hover {
  background-color: #ffffff;
  color: #43cea2;
  border: 1px solid #ffffff;
  border-radius: 0 !important;
}


</style>