<template>
  <div id="app">
    <!-- Preloader -->
    <div id="preloader">
      <div class="ozient-folding-cube">
        <div class="ozient-cube1 ozient-cube"></div>
        <div class="ozient-cube2 ozient-cube"></div>
        <div class="ozient-cube4 ozient-cube"></div>
        <div class="ozient-cube3 ozient-cube"></div>
      </div>
    </div>

    <div id="home" class="main-wrapper">
      <AppHeader />
      <router-view />
      <FooterApp />
    </div>
    <ProgressWrap />
  </div>
</template>

<script>
import $ from "jquery";
import FooterApp from "@/components/layout/Footer";
import AppHeader from "@/components/layout/AppHeader";
import ProgressWrap from "@/components/sections/ProgressWrap";
import { mapGetters } from "vuex";
export default {
  name: "app",
  components: {
    ProgressWrap,
    AppHeader,
    FooterApp,
  },
  mounted() {
    if (localStorage.getItem("isDark"))
      this.$store.dispatch(
        "setIsDarkAction",
        localStorage.getItem("isDark") === "true"
      );
    else {
      localStorage.setItem("isDark", false);
      this.$store.dispatch("setIsDarkAction", false);
    }
    if (this.$i18n.locale === "ar") {
      document.documentElement.setAttribute(
        "lang",
        localStorage.getItem("lang")
      );
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("lang", this.$i18n.locale);
    }
  },
  created() {
    // :: 9.0 Preloader Active code
    window.addEventListener("load", function () {
      $(".main-wrapper").fadeIn("slow");
      $(".ozient-folding-cube").fadeOut(500, function () {
        $("#preloader").remove();
      });
    });
  },
  computed: {
    ...mapGetters(["getIsDark"]),
  },

  watch: {
    getIsDark(n, o) {
      if (n !== o && n)
        document.documentElement.setAttribute("class", "is-dark");
      if (n !== o && !n) document.documentElement.removeAttribute("class");
    },
  },
};
</script>

<style>
html:lang(ar)* {
  text-align: right;
  direction: rtl;
}
</style>
