<script setup lang="ts">
import { computed, ref, watch, defineProps,onMounted,onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { withDefaults } from '@vue/runtime-core';
import CompanyMegamenu from './megamenu/CompanyMegamenu.vue'
import HamburgerMenu from './megamenu/HamburgerMenu.vue'
import ButtonMenu from './megamenu/I18n/ButtonMenu.vue'
import ResourcesMegamenu from './megamenu/ResourcesMegamenu.vue'
import ProductMegamenu from './megamenu/ProductMegamenu.vue'
import NavbarDropdown from './modules/NavbarDropdown.vue'
import ThemeToggle from './megamenu/ThemeToggle.vue'

export interface NavbarProps {
  inverted?: boolean
  solid?: boolean
  isRTL?: boolean
  dropdownBoxed?: boolean
  languages?: i18nlangs[]
}
export interface i18nlangs {
  label: string
  i18nLang: string
}
export interface i18nlangsProps {
  languages: i18nlangs[]
  dropdownBoxed: false
}

const props = withDefaults(defineProps<NavbarProps>(), {
  isActive: true,
  inverted: false,
  solid: false,
  dropdownBoxed: false,
  privacyPolicy: false,
})
const { locale } = useI18n({ useScope: 'global' })

let dropdownBoxed = true
const isRtl = ref(false)
let isAppRtl = computed({
  get() {
    return isRtl.value
  },
  set(value = isRtl.value) {
    isRtl.value = value
    localStorage.setItem(`isRtl`, value.toString())
    _setAppDir(value ? 'rtl' : 'ltr')
  },
})
const _setAppDir = (dir: 'ltr' | 'rtl') => {
  document.documentElement.setAttribute('dir', dir)
  localStorage.setItem(`Dir`, dir)
}

const i18nCompLanguages = [
  {
    label: 'English',
    i18nLang: 'en',
  },
  {
    label: 'Arabic',
    i18nLang: 'ar',
  },
]

const handleLangChange = (lang: string) => {
  isAppRtl.value = lang === 'ar'
  localStorage.setItem('lang', lang)
  location.reload()
}
const isNavbarOpen = ref(false)
const isMegamenuOpen = ref(false)
const activeDropdown = ref('')
const localRtl = localStorage.getItem(`isRtl`)
const lang =localStorage.getItem('lang')
const route = useRoute()
const navbar = ref(null)

const isScrolled = ref(false)

const handleScroll = () => {
  let scrollY = window.scrollY
  isScrolled.value = scrollY > 60
}
const selectMobileMenu = (menuId = '') => {
  
  isMegamenuOpen.value = !!menuId
  activeDropdown.value = menuId
}
const toggleButton = () => {
  
  isNavbarOpen.value = !isNavbarOpen.value
  isMegamenuOpen.value = false
  activeDropdown.value= ''

}
onMounted(() => {
     window.addEventListener('scroll', handleScroll);
})
onBeforeUnmount(() => {
       window.removeEventListener('scroll', handleScroll);

})
watch(
  () => route.fullPath,
  () => {
    isNavbarOpen.value = false
    selectMobileMenu()
  }
)
</script>

<template :class="[{ active: isActive }, errorClass]">
  <nav
    ref="navbar"
    class="navbar is-transparent is-fixed-top is-fade"
    role="navigation"
    aria-label="main navigation"
    :class="[
      isScrolled && 'is-scrolled',
      isNavbarOpen && 'is-solid',
      isMegamenuOpen && 'is-solid',
      props.inverted && 'is-inverted',
      props.solid && 'is-solid',
      props.privacyPolicy,
      { rtl: localRtl },
    ]"
    @mouseleave="() => selectMobileMenu()"
    @focusout="() => selectMobileMenu()"
  >
    <div class="container">
      <div class="navbar-brand">
        <RouterLink  class="navbar-item" to="/">
          <img
            class="navbar-logo "
            src="@/assets/images/ezar-logo.svg"
            alt="logo"
            width="80"
            height="80"
          />
        </RouterLink>
        <div
          class="navbar-burger"
          @click.prevent="toggleButton()"
          @keydown.space.prevent="() => (isNavbarOpen = !isNavbarOpen)"
        >
          <HamburgerMenu :open="isNavbarOpen" />
        </div>
      </div>
      <div
        class="navbar-menu b-centered-mobile b-centered-tablet-p"
        :class="[isNavbarOpen && 'is-active']"
      >
        <div :class="localRtl === 'true'? 'navbar-end':'navbar-start'">
          <a
            role="button"
            class="navbar-item"
            :class="[activeDropdown === 'product-drop' && 'is-selected']"
            tabindex="0"
            @mouseover="() => selectMobileMenu('product-drop')"
            @focusin="() => selectMobileMenu('product-drop')"
          >
            <span class="has-naver " :class="localRtl==='true'?'ml-1':'mr-1'">{{ $t('nav.header.product') }}</span>
            <i class="iconify fa fa-angle-down" ></i>
          </a>
          <RouterLink
            to="/"
            class="navbar-item"
            tabindex="0"
            @mouseover="() => selectMobileMenu()"
            @focusin="() => selectMobileMenu()"
          >
            <span class="has-naver " :class="localRtl==='true'?'ml-1':'mr-1'">{{ $t('nav.header.price') }}</span>
          </RouterLink>
          <a
            role="button"
            class="navbar-item"
            :class="[activeDropdown === 'company-drop' && 'is-selected']"
            tabindex="0"
            @mouseover="() => selectMobileMenu('company-drop')"
            @focusin="() => selectMobileMenu('company-drop')"
          >
            <span class="has-naver" :class="localRtl==='true'?'ml-1':'mr-1'">{{ $t('nav.header.services') }}</span>
            <i class="iconify fa fa-angle-down" ></i>
          </a>
          <a
            role="button"
            class="navbar-item"
            :class="[activeDropdown === 'resources-drop' && 'is-selected']"
            tabindex="0"
            @mouseover="() => selectMobileMenu('resources-drop')"
            @focusin="() => selectMobileMenu('resources-drop')"
          >
            <span class="has-naver" :class="localRtl==='true'?'ml-1':'mr-1'">
              {{ $t('nav.header.dawnload') }}
            </span>
            <i class="iconify fa fa-angle-down" ></i>
          </a>
        </div>

        <div :class="localRtl === 'true'? 'navbar-start':'navbar-end'">
          <NavbarDropdown label="lang" :boxed="dropdownBoxed" >
            <ButtonMenu
              v-for="(feature, index) in i18nCompLanguages.slice(0, 4)"
              :key="index"
              class="navbar-item has-naver "
              :class="[ localRtl==='true'?'ml-1':'mr-1 ' , lang === feature.i18nLang ? 'choose-lang':'']"
              
              custom
              @change="handleLangChange(feature.i18nLang)"
              @click="
                ;(locale = feature.i18nLang),
                  $emit('change', feature.i18nLang),
                  handleLangChange(feature.i18nLang)
              "
            >
              {{ feature.label }}
            </ButtonMenu>
          </NavbarDropdown>

          <div
            class="navbar-item is-flex is-align-items-center is-justify-content-center"
          >
            <ThemeToggle />
          </div>
        </div>
      </div>
    </div>

    <div class="megamenu" :class="isMegamenuOpen? 'is-active':''" >
      <ProductMegamenu
        v-if="activeDropdown === 'product-drop'"
        @close="() => selectMobileMenu()"
      />

      <CompanyMegamenu
        v-if="activeDropdown === 'company-drop'"
        @close="() => selectMobileMenu()"
      />
      <ResourcesMegamenu
        v-if="activeDropdown === 'resources-drop'"
        @close="() => selectMobileMenu()"
      />
    </div>
  
  </nav>
</template>

<style lang="scss" scoped>
.navbar {
  height: 70px;
  transition: all 0.3s;
  .navbar-brand {
    height: 70px;

    .navbar-logo {
      display: block;
      width: 100%;
      max-width: 120px;
      max-height: 60px;

    }

    .navbar-logo-text {
      font-family: var(--font-alt);
      font-weight: 300;
      font-size: 1.1rem;
      text-transform: uppercase;
      margin-left: 0.75rem;
      margin-right: 1rem;
      color: var(--dark-text);
    }

    .navbar-burger {
      display: none;
      align-items: center;
      justify-content: center;
      height: 70px;
      width: 70px;
      background: none;
    }
  }
  .navbar-menu {
    box-shadow: none;

    &.is-active {
      background: var(--navbar-bg-color-solid);
    }
  }

  .navbar-item {
    font-family: var(--font-alt);
    font-size: 1rem;
    font-weight: 400;
    color: var(--navbar-link-color-inverted);
    transition: color 0.3s;
    background: none;
    border: none;

    &.router-link-exact-active {
      color: var(--main-color);

      &.has-naver {
        &::before {
          background: var(--main-color);
        }
      }
    }

    &.is-selected {
      > .iconify {
        transform: rotate(180deg) !important;
      }
    }
    // &.is-scrolled {
    //   color: var(--navbar-link-color);
    // }

    &:hover {
      color: var(--main-color);

      .has-naver {
        color: var(--main-color);

        &::before {
          transform-origin: left center;
          transform: scale(1, 1);
        }
      }
    }

    &.has-dropdown {
      .navbar-item{
        color: var(--navbar-link-color);
      }
      .navbar-link {
        // color: #f16036;

        &:hover {
          color: var(--main-color);
        }
      }

      .navbar-dropdown {
        width: 240px;

      }
    }

    .has-naver {
      position: relative;
     
      
    
    }
    > .social-item {
      color: var(--medium-text);
      transition: color 0.3s;

      &:hover {
        color: var(--main-color);
      }
    }

    > .button {
      min-height: 48px;
      min-width: 110px;
    }

    > .iconify {
      transition: transform 0.3s;
      vertical-align: middle;
      font-size: 1.1rem;
    }
  }

  &.is-fade {
    background: transparent;

    &.is-scrolled {
      background: var(--navbar-bg-color-solid);
      box-shadow: rgba(0, 0, 0, 0.071) 0 1px 12px;
    }
  }

  &.is-solid {
    background: var(--navbar-bg-color-solid);
    box-shadow: none;

    .navbar-menu {
      border-bottom: 1px solid var(--navbar-border-color);
    }
    .navbar-item{
      color: var(--navbar-link-color);
      &.is-selected   > .iconify{
              color: var(--main-color);

      }
    }
  }

  &.is-scrolled{
     .navbar-item{
          color: var(--navbar-link-color);
     }
  }

  &.is-inverted:not(.is-solid):not(.is-scrolled) {
    .navbar-brand {
      .navbar-logo {
        filter: brightness(0) invert(1);
      }
    }

    .navbar-item {
      color: rgba(255, 255, 255, 0.906);
    }
  }

  &.is-fixed-top {
    z-index: 32;
  }
}

:deep(.rtl) {
  position: fixed;
  top: 57px;
  left: 25%;
  left: auto;
  width: 100%;
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.135);
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  transition: opacity 0.3s, transform 0.3s;

  &.is-active {
    opacity: 1;
    pointer-events: all;
    border: none;
    transform: translateY(0);
  }
}

:deep(.i18n) {
  position: fixed;
  top: 57px;
  right: auto;
  left: 72%;
  width: 100%;
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.142);
  border: none;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  transition: opacity 0.3s, transform 0.3s;

  &.is-active {
    opacity: 1;
    border: none;
    pointer-events: all;
    transform: translateY(0);
  }
}

:deep(.megamenu) {
  position: fixed;
  top: 69px;
  left: 0;
  padding: 0 5vw 0 5vw;
  width: 100%;
  background: var(--navbar-bg-color-solid);
  border-top: 1px solid var(--navbar-border-color);
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.142);
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  transition: opacity 0.3s, transform 0.3s;

  &.is-active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }

  .megamenu-inner {
    position: relative;
    padding: 60px 0 80px;

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      display: none;
      align-items: center;
      justify-content: center;
      height: 38px;
      width: 38px;
      padding: 0;
      border: none;
      background: none;
      outline: none;
      font-size: 1.75rem;
      color: var(--megamenu-close-color);
    }

    .megamenu-block {
      &.is-left-bordered {
        padding-left: 2rem;
        border-left: 1px solid var(--navbar-border-color);
      }

      .media {
        .media-left {
          &.is-bolt {
            color: #ffb921;
          }

          &.is-company {
            color: var(--success);
          }

          &.is-resources {
            color: #f16036;
          }

          .iconify {
            font-size: 2.25rem;
          }
        }

        .media-content {
          .tag {
            margin-bottom: 0.5rem;
          }

          h3 {
            font-family: var(--font-alt);
            font-size: 1.25rem;
            font-weight: 600;
            color: var(--megamenu-heading-color);
            margin-bottom: 1.25rem;
          }

          p {
            font-family: var(--font);
            color: var(--medium-text);
            margin-bottom: 1rem;
          }

          a {
            font-family: var(--font);
            font-weight: 500;
            transition: color 0.3s;

            .iconify {
              vertical-align: middle;
              transition: transform 0.3s;
            }

            &:hover {
              color: var(--main-color3);

              .iconify {
                transform: translateX(0.25rem);
              }
            }
          }
        }
      }

      h4 {
        font-family: var(--font-alt);
        font-size: 0.8rem;
        font-weight: 600;
        text-transform: uppercase;
        color: var(--megamenu-heading-color);
        margin-bottom: 0.75rem;
      }

      li {
        position: relative;
        margin-bottom: 0.25rem;

        &::before {
          content: '';
          position: absolute;
          top: 15%;
          left: 0;
          width: 3px;
          height: 0;
          background: var(--main-color);
          border-radius: 20rem;
          transition: height 0.3s, transform 0.3s;
        }

        &:hover {
          .list-link {
            color: var(--main-color);
            transform: translateX(0.5rem);
          }

          &::before {
            height: 70%;
          }
        }
      }

      .list-link {
        display: block;
        position: relative;
        font-family: var(--font);
        font-size: 0.9rem;
        color: var(--medium-text);
        margin-bottom: 0.5rem;
        transition: color 0.3s, transform 0.3s;
      }

      a {
        .iconify {
          vertical-align: middle;
          margin-left: 0.5rem;
        }
      }

      .image-block {
        position: relative;

        img {
          position: relative;
          display: block;
          border-radius: 0.95rem;
          object-fit: cover;
        }

        .inner-block {
          position: absolute;
          bottom: -1rem;
          left: 0;
          right: 0;
          width: 90%;
          margin: 0 auto;
          padding: 0.75rem 1.25rem;
          border-radius: 0.75rem;
          background: var(--card-bg-color);
          border: 1px solid var(--card-border-color);
          box-shadow: var(--light-box-shadow);
          z-index: 1;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  :deep(.i18n) {
    top: 50%; /* IMPORTANT */
    left: 50%; /* IMPORTANT */
    display: block;
    position: absolute;
    width: 750px;
    height: 417px;
    margin-top: -208.5px; /* HALF OF THE HEIGHT */
    margin-left: -375px; /* HALF OF THE WIDTH */
  }
  :deep(.megamenu){
    
    &.is-active {overflow-y: scroll;
    height: 100vh;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .navbar {
    &.is-centered {
      &:not(.no-nav) {
        .navbar-brand {
          display: flex;
        }

        .navbar-menu {
          .navbar-center {
            display: none !important;
          }
        }
      }

      &.no-nav {
        .navbar-menu {
          display: flex !important;
          padding: 0 !important;
          box-shadow: none !important;
          background: transparent !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .navbar {
    .navbar-brand {
      display: flex !important;
      justify-content: space-between;
      .navbar-burger {
        margin-left: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .navbar {
    .navbar-brand {
      .navbar-burger {
        display: flex;
      }
    }

    .navbar-menu {
      left: 0;
      top: 70;
      position:absolute;
      min-width: 100%;
    overflow-x: hidden !important;
      
      .navbar-item {
        margin: 0;

        &.has-dropdown {
          .navbar-dropdown {
            width: 100%;
          }
        }

        :deep(.custom-toggle) {
          max-width: 44px;
          margin: 0 auto;
          padding: 1rem 0;
        }

        .button {
          min-height: 46px;
          width: 100%;
        }
      }
    }
      .navbar-menu.is-active {
        animation-name: nav_bar;
        animation-duration:.4s ;
      }

    :deep(.i18n) {
      top: 10%; /* IMPORTANT */
      left: 50%; /* IMPORTANT */
      position: absolute;
      margin-top: 300.5px; /* HALF OF THE HEIGHT */
      margin-left: -75px;
      .megamenu-inner {
        .close-button {
          display: flex;
          top: 30px;
          right: 30px;
        }

        .megamenu-block {
          &.is-left-bordered {
            padding-top: 0;
          }

          .media {
            flex-direction: column;
            margin-left: 3rem;
          }
        }
      }

      :deep(.megamenu) {
        top: 0;

        .megamenu-inner {
          .close-button {
            display: flex;
          }

          .megamenu-block {
            &.is-left-bordered {
              padding-left: 0;
              border-left: none;
            }

            .media {
              flex-direction: column;
              margin-left: 3rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .navbar {
    .navbar-brand {
      .navbar-burger {
        display: flex;
      }
    }

    .navbar-menu {
      max-width: 100%;

      .navbar-item {
        margin: 0 auto;

        &.has-dropdown {
          .navbar-dropdown {
            width: 100%;
          }
        }

        :deep(.custom-toggle) {
          max-width: 44px;
          margin: 0 auto;
          padding: 1rem 0;
        }

        .button {
          min-height: 46px;
          width: 100%;
        }
      }
    }

    :deep(.i18n) {
      top: 100;

      .megamenu-inner {
        .close-button {
          display: flex;
          top: 30px;
          right: 30px;
        }

        .megamenu-block {
          &.is-left-bordered {
            padding-top: 0;
          }

          .media {
            flex-direction: column;
            margin-left: 3rem;
          }
        }
      }
    }

    :deep(.megamenu) {
      top: 0;

      .megamenu-inner {
        .close-button {
          display: flex;
          top: 30px;
          right: 30px;
        }

        .megamenu-block {
          &.is-left-bordered {
            padding-left: 0;
            border-left: none;
          }

          .media {
            flex-direction: column;
            margin-left: 3rem;
          }
        }
      }
    }
  }
}

/* stylelint-disable */
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  .navbar {
    .navbar-brand {
      .navbar-burger {
        display: none !important;
      }
    }
  }
}

.choose-lang::before{
 
        content: '';
        position: absolute !important;
        bottom: 3px !important;
        left: 2px !important;
        width: 50% !important;
        transform-origin: right center !important;
        height: 3px !important;
        border-radius: 50px !important;
        background: var(--main-color) !important;
        // transform: scale(0, 1) !important; 
        transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1) !important;
      }

      @keyframes nav_bar {
  from{
    opacity: 0;
    transform: translateY(-20px);
  }
  to{
        opacity: 1;
    transform: translateY(0);
  }

}
/* stylelint-enable */
</style>
