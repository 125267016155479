<template>
  <div>
    <BannerApp/>

    <EventsBox/>
  </div>

</template>

<script>
import BannerApp from "@/components/sections/BannerApp";
import EventsBox from "@/components/sections/EventsBox";

export default {
  name: "EventsView",
  components: {EventsBox, BannerApp}
}
</script>

<style scoped>

</style>