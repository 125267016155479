export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجاتنا"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتنا"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا "])},
  "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسعار"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحميل"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
  "title_ezar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعة ايزر"])},
  "title_IterStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايتر الطالب"])},
  "title_IterTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايتر المدرس"])},
  "title_IterAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايتر المسؤول"])},
  "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يعرض أحد لتدخل تعسفي في حياته الخاصة أو أسرته أو مسكنه أو مراسلاته أو لحملات على شرفه وسمعته۔ ولكل شخص الحق في حماية القانون من مثل هذا التدخل أو تلك الحملات۔\nلكل شخص الحق في حرية التفكير والضمير والدين۔ ويشمل هذا الحق حرية تغيير ديانته أو عقيدته، وحرية الإعراب عنهما بالتعليم والممارسة وإقامة الشعائر ومراعاتها سواء أكان ذلك سرا أم مع الجماعة۔\nلكل شخص الحق في حرية الرأي والتعبير۔ ويشمل هذا الحق حرية اعتناق الآراء دون أي تدخل، واستقاء الأنباء والأفكار وتلقيها وإذاعتها بأية وسيلة كانت دون تقيد بالحدود الجغرافية۔"])},
  "select-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Language"])},
  "nav": {
    "header": {
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
      "dawnload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميلات"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسعار"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
      "production": {
        "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السديم"])},
        "subtitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عندما تتوحد الرؤية والإبداع لخلق مستقبل  لجيل جديد"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدأ الان"])},
        "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
        "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سديم أدمن"])},
        "Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سديم طالب"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
        "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شيك ان"])},
        "learn": {
          "learn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليم"])},
          "learn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم سديم ادمن"])},
          "learn3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم سديم طالب"])},
          "learn4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم لوحة التحكم"])},
          "learn5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم شيك ان"])}
        },
        "about": {
          "aboutSadeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتميز سديم"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هناك العديد من التطبيقات الناجحة التي توضح تميز أسلوب العمل في  منصة سديم والقدرة على تطوير الأفكار لتقديم أفضل الحلول"])}
        }
      },
      "company": {
        "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نبني المستقبل"])},
        "subtitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نعمل على تعظيم إمكانات البائعين ومالكي العلامات التجارية من خلال قوة البيانات والذكاء الاصطناعي وخبرة السوق"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد عنا"])},
        "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
        "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات التقنية"])},
        "Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناهج الاثرائية (قيد التطوير)"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كن شريكاً"])},
        "learn": {
          "learn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصل إلينا"])},
          "learn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصال"])},
          "learn3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المساعدة"])},
          "learn4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدعم"])}
        }
      },
      "download": {
        "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحميل"])},
        "subtitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  لدينا العديد من الخبرات مع المؤسسات الحكومية والخاصة بمهنية وسهولة ودقة"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل التطبيقات"])},
        "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
        "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصدارات"])},
        "Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخر الاصدارات"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
        "learn": {
          "learn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
          "learn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الاستخدام"])}
        }
      }
    }
  },
  "pages": {
    "First": {
      "home": {
        "tittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركة السديم لتقنية المعلومات تقدم أضخم انتاج لها للمؤسسات التعليمية وهي"])},
        "subtittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منصة سديم التعليمية"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" صممت خصيصاً لأصحاب المؤسسات التعليمية  تعمل على إدارة المؤسسة التعليمة وتقديم أفضل الخدمات للمؤسسة مما تجعل لك إدارة مبسطة"])},
        "mobileAppFeatures": {
          "Tecnical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعم فني"])},
          "tex1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على مدار الــ 24 ساعة  تساعدك في أي وقت  وتجيب على أسئلتك واستفساراتك  "])},
          "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيقات للمستخدمين"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سوف تحصل على تطبيق مخصص للطلبة وتطبيق مخصص للمدراء والأساتذة وتطبيق مخصص لتسجيل الدخول ومحدد بصلاحيات"])},
          "Private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحادثات الخاصة"])},
          "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحادثات الخاصة بين الإدارة والطالب والأستاذ بصورة سلسلة مع خصوصية عالية ومتابعة من قبل الادارة وإمكانية مشاركة الوسائط"])},
          "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعة المحادثات"])},
          "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام المجموعات الخاصة والتواصل التفاعلي ضمن التطبيق وإمكانية ارسال بثوث مباشرة  داخل المجموعة"])},
          "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمارين للطلبة"])},
          "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أيقظ روح التحدي بين الطلبة من خلال ارسال مجموعة تمارين تساعد الطالب على فهم المنهج وتمكنه من الحصول على الدرجات العالية"])},
          "Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الصلاحيات"])},
          "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فتح وحد من الصلاحيات كلٌ  حسب مسؤولياته تساعدك عل حصر المسؤولية وتنظيم عملك داخل المؤسسة "])},
          "broadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بث مباشر"])},
          "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البثوث المباشر بين الأستاذ  والطلبة بنقرة زر واحدة ومن داخل التطبيق بالتعاون مع زوم "])},
          "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة تحكم متقدمة"])},
          "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابقى على تواصل دائم مع مؤسستك أينما كنت من خلال لوحة تحكم متقدمة تساعدك على متابعة سير العماية التعليمة وضمان جودتها"])}
        }
      }
    },
    "sadeemTeacher": {
      "tittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سديم مدير"])},
      "subtittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطبيقات"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق مخصص لمدير المؤسسة وجميع المسؤولين عن المؤسسة وتحديداّ الأستاذ وفق صلاحيات تحددها إدارة المؤسسة التعليمة ، الكثير من المزايا يحملها التطبيق لمساعدة المؤسسة بأعطاء كفاءة اكثر بالتعليم بأسلوب اكثر من رائع ومن نافذة واحدة ومن ضمن هذه المميزات التي يقدمها التطبيق هي "])},
      "features": {
        "opinion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استطلاع الراي للطلبة"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ينقلك الى بيئة تفاعلية للطالب من خلال الاستطلاع وراي الطلبة"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الواجبات المتقدمة"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الواجبات البيتية المتقدمة بأنواعها المختلفة تمكنك تحديد مستوى استيعاب الطلبة للدرس مع إمكانية جدولتها او ارشفتها واستخدامها لاحقاً"])},
        "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاختبارات الالكترونية"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنوعيها الاعتيادي والسريع تمنحك مساحة واسعة لاختبار طلبتك وتحديد مستواهم بسهولة وفي أي وقت مع إمكانية ارشفتها واستخدامها لاحقاً "])},
        "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمارين الطلبة"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدولة المنشورات بكافة أنواعها  بالوقت والتاريخ الذي تختاره مع إمكانية الارشفة  "])}
      },
      "featuresB": {
        "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الاساتذة"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحة تظهر فيها جميع تفاعلات الأساتذة مع الطلبة"])},
        "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتبع دقيق"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يستطيع الأستاذ او المدير او المسؤول عند نشر او ارسال  رسالة معرفة من شاهد المنشور او الرسالة التي رسلها "])},
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير مفصل للطالب"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة تظهر جميع نتائج وتفاعلات الطلبة واخر الردود التي قامها بها "])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنظيم عالي المستوى"])},
        "tex4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال هذه الميزة يستطيع الأستاذ او المدير او الطالب او المسؤول الوصل الى المعلومة بسرعة قياسية حتى لو كانت منشورة منذ سنوات "])}
      }
    },
    "success": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذا تختارنا كشركاء لنجاحك"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن في السديم نسعى دائمًا لأن نكون الشركة الوحيدة التي تقدم نوعًا فريدًا من الخدمات والتطبيقات والأنظمة للمختبرات والمؤسسات. مشاريعنا تتحدث عن جودة عملنا وهذا ما نطمح إليه."])},
      "Student1Feature": {
        "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جودة الآداء"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجودة لا تأتي أبدًا عن طريق الصدفة ، فهي نتيجة الجهد الصادق والتوجيه الذكي والمدير المتمرس ، فهي تمثل الاختيار الحكيم للبدائل المتعددة."])},
        "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إِبداع"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا هو الوقت المناسب لمراقبة اتجاه بوصلة إبداعك ، التغيير الإبداعي هو رفيق التميز ، السديم شركائك المبدعين"])},
        "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فهم التعاون"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل مثالي ، وقت أقل ، روح واحدة ، عقول مبدعة مجتمعة لتحليل متطلباتك والوفاء بها ، جنبًا إلى جنب حتى يتمكن العالم من رؤية إنجازنا."])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إستجابة"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في السديم نؤمن بأهمية الوقت لعملائنا وضرورة سرعة الإنجاز بجودة عالية وإبداع مميز"])}
      }
    },
    "offers": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك الآن للحصول على عروضنا الخاصة"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لأننا الأفضل ، لا شيء يقارن بتجربتك معنا."])}
    },
    "Dashboard": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة تحكم متقدمة لإدارة تعليمية ناجحة ومتميزة تتيح لك التحكم بمؤسستك أينما كنت وحفظ وتخزين البيانات الخاصة بالمؤسسة  مع الاتمتة المتقدمة لكافة بيانات المؤسسة بما فيها سجلات الطلبة والأساتذة واجراء الاحصائيات المختلفة التي من خلالها تمنحك تحديد نقاط الضعف والقوة في المؤسسة التعليمية.  "])},
      "dashboardFeature": {
        "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الأدوار"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك  إضافة المسمى الوظيفي المطلوب للموظف وتبعاً له يتم تعريف صلاحيات محددة ."])},
        "Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الطلبة"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك هذا القسم من التحكم  بكامل طلاب المؤسسة وإضافتهم والحصول على يوزرات خاصة بهم وتعديل بياناتهم "])},
        "Employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الموظفين"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمنحك السيطرة الكاملة بجميع صلاحيات الموظفيين وإمكانية إضافتهم وتعديل بياناتهم بكل سهولة"])}
      }
    },
    "registration": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بطريقة بسيطة يمكنك  خوض التجربة"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطوات تسجيل الطالب"])},
      "workoutTimeline": {
        "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمل التطبيق  "])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تحميل التطبيق مباشرة عبر Google play  و App store "])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على اليوزر الخاص بك"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند اشتراك المؤسسة بمنصة سديم يمكنك الحصول على يوزر خاص بك متضمن كلمة مرور واسم مستخدم ومن خلاله تستطيع تسجيل الدخول للتطببق"])},
        "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول عن طريق ال qr"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال رمز ال qr  والذي يتم الحصول عليه من قبل إدارة المؤسسة يمكنك تسجيل الدخول وبكل سهولة للتطبيق  "])},
        "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن طريق الويب"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك فتح حسابك عن طريق الويب براوزر بإدخال كلمة المرور واسم المستخدم والتمتع بكامل المزايا"])}
      }
    },
    "SadeemStudent": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سديم طالب"])},
      "subtitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطبيقات"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يهدف هذا التطبيق إلى بناء جيل جديد من المتعلمين والواعين نحو مستقبل مزدهر يتيح للآباء معرفة ما يفعله أطفالهم في المدرسة عند مغادرتهم المنزل حتى عودتهم بالإضافة إلى توفير التواصل بين إدارة الأسرة والمدرسة بشكل فوري وسريع"])},
      "Student1Feature": {
        "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التمارين اليومية"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرن نفسك على الدروس التي قدمها الأستاذ لك وثبت مراجعة الدرس على شكل تمرين تفاعلي"])},
        "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحادثات الخاصة"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سهولة وسرعة التواصل مع الأساتذة او الإدارة من داخل التطبيق مع إمكانية مشاركة الوسائط"])},
        "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الاسبوعي"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرفة أوقات الدروس أصبح بمتناول يدك وبشكل منظم"])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكتبة الالكترونية"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكتبة الالكترونية بملفاتها المختلفة صوتية او فيديو او ملف  pdf مع إمكانية تنزيلها والاطلاع عليها وتكون خاصة بكل مادة "])}
      },
      "Student2Feature": {
        "broadcasts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البثوث الخاصة والعامة"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البثوث التفاعلية بين الطالب والأستاذ  مع استخدام الأسئلة التفاعلية  من ذات التطبيق  "])},
        "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدروس المتقدمة"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ومتابعتها في أي وقت والعودة متى شئت "])},
        "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاختبارات الالكترونية"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم متخصص لأنشاء أفضل الأسئلة التفاعلية للطلبة من قبل المعلم والتي تجعل الطالب يتفاعل مع الاسئلة"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلترة المتقدمة والبحث عن المنشور"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تستطيع الوصل الى أي درس او واجب بيتي او معلومة تم نشرها من المؤسسة في أسرع طريقة ممكنة "])}
      }
    },
    "sadeemFeature": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يضمن تواصل أفضل بين عناصر المؤسسة التعليمية من جهة ، والطلاب وأولياء أمورهم من جهة أخرى ، مما يعني قدر أكبر من التوافق والرضا ، مما يساهم في تحسين المستوى التعليمي في المؤسسة وضمان المتابعة. إشراف الوالدين."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مع برنامج سديم ستضمن إجراء تقييم دقيق لأداء الطلاب من خلال العديد من الميزات التي ستساعد الطالب على تحسين أدائه ، وتفعيل المنافسة الإيجابية بين الطلاب ، مثل نظام النقاط ، والتقييمات الدورية والتقارير ، والاختبارات الإلكترونية المحمية مع التصحيح."])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منصة سديم تحاكي احتياجات مؤسستك بأكملها ، من نظام مالي ومحاسبي شامل ، إلى نظام إدارة شؤون الطلاب والموظفين ، وإدارة المستودعات والنقل ، ونظام إدارة الهيئة. بالإضافة إلى المتابعة الشاملة للموظفين مع التقارير الدورية."])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مع برنامج سديم ستضمن استمرارية العملية التعليمية في مختلف الظروف ، وذلك بفضل ميزة البث المباشر من داخل التطبيق ودون الحاجة لاستخدام منصات إضافية ، وميزة الدروس المسجلة ، والحقيبة التعليمية ، في بالإضافة إلى المكتبة الإلكترونية الثرية."])}
    },
    "TechnicalServices": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات التقنية للشركة"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال متابعتنا للمؤسسات التعليمية وجدنا حلولاً لجميع المشاكل التي قد تواجهها المؤسسات ، حيث أنها لن تحتاج إلى منصات متعددة وتشتت سير العمل الإداري في المنصات الخارجية ، ومن خلال سديم قمنا بتوفير كل شيء من منصة واحدة"])},
      "posts": {
        "post1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات تصميم وتطوير الأنظمة"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نبني منصات رقمية قوية لمساعدة الشركات والمؤسسات على أن تصبح أكثر مرونة وكفاءة وديناميكية."])},
        "post2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنظمة المحاسبية والإدارية"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعمل مع شركاء مختلفين في الصناعة والتجارة لتشكيل عقليات جديدة ونماذج جديدة لأنظمة ادارية محاسبية فعالة"])},
        "post3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المنصات التعليمية الخدمية"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نزود عملائنا بخدمات وحلول مضمونة الجودة، مرتكزة على خبرة سنوات من التحليل الدقيق لمتطلبات المؤسسات التعليمية"])}
      }
    },
    "sadeemcheckin": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سديم شيك ان"])},
      "subtitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدعم هذا القسم المشاهدة"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق مخصص للمؤسسات التعليمية لتسهيل عملية أخذ الحضور والغياب من خلال قراءة ومسح كود QR للطلاب والمعلمين."])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من أجل استخدام هذا البرنامج ، تحتاج إلى تثبيته على هاتفك أولاً ، ثم تشغيله ومنح الكاميرا إذنًا به ، فيعمل التطبيق مباشرةً على التقاط رموز QR وقراءتها تلقائيًا ، ومن مزايا التطبيق:"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ تسجيل حضور وغياب الطلاب"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ تسجيل خروج الطالب"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ التحقق من الطالب"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ تسجيل حضور المعلمين "])}
    },
    "backet": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السوار الكتروني"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدعم التطبيق مسح رمز الاستجابة السريعة QR Code من السوار الإلكتروني بطريقة سهلة مما يضمن السرعة في أخذ الحضور والغياب والدقة وكذلك حيث يكون الرمز على السوار ، وبمجرد مسحه من خلال التطبيق يتم أخذ الحضور ، الخروج ، أو الاختيار حسب العملية المراد القيام بها."])}
    },
    "id": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهوية الإلكترونية"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدعم التطبيق مسح رمز الاستجابة السريعة QR Code من الهوية الإلكترونية بطريقة سهلة تضمن السرعة في أخذ الحضور والغياب والدقة وكذلك حيث يوجد الرمز على الهوية بمجرد مسحه ضوئيًا من خلال التطبيق يتم تسجيل الحضور أو الخروج أو تحقق وفقًا للعملية المراد تنفيذها."])}
    },
    "important": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أهم ما يميز أنظمتنا"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن في السديم نسعى دائمًا لأن نكون الشركة الوحيدة التي تقدم نوعًا فريدًا من الخدمات والتطبيقات والأنظمة لمعامل والمؤسسات. مشاريعنا تتحدث عن جودة عملنا وهذا ما نطمح إليه."])},
      "Student1Feature": {
        "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضوح"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعطي تجربة فعالة وسلسة دون تعقيد."])},
        "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث دائم"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن ملتزمون بالانفتاح على العالم التكنولوجي السريع."])},
        "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوضع اليلي"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل قطعة واحدة من واجهة المستخدم جاهزة أصلاً للوضع المظلم."])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعم فني"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهمتنا هي توفير التكنولوجيا للجميع ، ونحن متواجدون على مدار الساعة."])},
        "librar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التدريب"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعريف تجربة المستخدم للذكاء الاصطناعي ، وتدريبه بشكل كامل على أنظمتنا."])},
        "libra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استجابة سريعة"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نساعد الشركات على أن تصبح أكثر مرونة وكفاءة."])},
        "libr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دقة التصميم"])},
        "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نحترم القواعد والعمليات ونعمل على تصحيح الأمور من المرة الأولى."])},
        "lib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حماية البيانات"])},
        "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات عملائنا محمية بالكامل من الضياع أو القرصنة."])}
      }
    },
    "choose": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذا تختارنا في العمل؟"])},
      "Student1Feature": {
        "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للخصوصية"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك استخدام خدماتنا على السحابة الخاصة بك بطريقة سهلة في جميع الأوقات ."])},
        "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لخدمة العملاء"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يبقى فريق الدعم الفني معك حتى تتأكد من خدماتنا."])},
        "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للتكنولوجيا الاحترافية"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدام طرق بناء الخوارزميات وتطويرها بشكل مستمر."])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للمدفوعات"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات المقدمة مناسبة حسب حاجتك للخدمة في أسرع وقت ممكن."])},
        "librar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للسلامة"])},
        "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في السديم ، نعمل على فهم احتياجات العملاء وأتمتة تدفقات العمل ."])},
        "libra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للتحديثات المستمرة"])},
        "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسعى جاهدين لبناء ثقة العملاء ستكون معنا دائمًا."])}
      }
    },
    "our": {
      "titel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتنا"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لدينا بعض الخدمات الرائعة لمساعدتك في إعداد مشروعك والبدء في أسرع وقت ممكن."])},
      "Student1Feature": {
        "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مناهج الإثراء"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقدم لكم الأفضل  من خلال مشاركتنا في أفضل الشركات العالمية والمصادر الموثوقة."])},
        "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسابقات عالمية"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال شراكتنا مع الشركات العالمية المسؤولة عن تنظيم المناهج يمكن المشاركة."])},
        "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعتمادات دولية"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوفر لك الاعتمادات العالمية للتعليم من المنظمات المسؤولة عن قياس جودة التعليم."])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جودة التعليم"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوفر لكم فريق عمل متخصص ومشرفين لبناء خطة جودة تعليمية بأفضل تكلفة وأسرع وقت."])}
      }
    },
    "testimonials": {
      "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نايا عبد"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في تاريخ عملي مع منصات التعلم عن بعد ، يمكنني القول بصراحة أنه لا توجد شركة واحدة عملت معها على الإطلاق تقدم خدمة أفضل من خدمة سديم."])},
      "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيان رائد"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا أشعر بالقلق من استخدامي لأي من منتجات السديم. لقد استمتعت بتجربة سديم خلال مسيرتي الأكاديمية. لقد مكنني من متابعة دروسي وواجباتي."])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أماني الوزان"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعتبر نقطة الاتصال الأولى في أي شركة هي الأهم ويجب أن نذكر موظفي الدعم الفني وموظفي الإدارة الفنية الذين كانوا دائمًا على أعلى مستوى."])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرسة آيسن"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السديم هي شركة محترفة وموثوقة للغاية أشعر بالثقة في رعاية مصالح مؤسستي في جميع الأوقات.”"])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دانييل فرنسيس"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“نحن محظوظون جدًا لأننا أقمنا شراكات ممتازة مع العديد من عملائنا ، وقمنا بتكوين أكثر من مجرد علاقات تجارية معهم ؛ لقد شكلنا صداقات حقيقية. هذا ما يقولونه عنا”"])},
      "cha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحمد أبو عدلة"])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنا أكثر من سعيد بالخدمة التي تلقيتها من لوجك ان والفريق ولن أتردد في معاودة التجربة."])},
      "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ديانا عيسى"])},
      "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق عمل واسع المعرفة ومبدع"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاروق قنديل "])},
      "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منظمة مهنية  يتم تنفيذ العمل دائمًا في الوقت المناسب وبطريقة جيدة التنظيم  لا يمكنني  تقدريكم بدرجة كافية "])},
      "chatss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أمير غازي "])},
      "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق متعاون يعمل حقاً على تلبية احتياجاتنا"])},
      "chatsss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أميرحيدر "])},
      "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاهز دائمًا بالحلول - لجميع أنواع المشكلات"])},
      "chatssss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربيع المعلم "])},
      "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد استخدمنا سديم للأعمال على مدار العامين الماضيين ، بعد أن استخدمنا منصات تعليمية أخرى لمنظماتنا ، لقد تأثرنا كثيرًا بالخدمة التي تلقيناها ، والطريقة الودية التي نشعر بها بالثقة في تحميل قاعدة البيانات إلى النظام الأساسي آمن ويمكن التعامل مع أي استفسارات أو مخاوف لدي بشكل احترافي."])}
    }
  },
  "priceing": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسعارنا"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في السديم ، نسعى إلى أن تكون أسعارنا مدروسة جيدًا لتناسب جميع العملاء مع الحفاظ على جودة الخدمات"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assets/teacher/e.png"])},
    "test": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نضمن لك تجربة مثالية معنا ، انضم إلينا واحصل على الخدمات المميزة التي تقدمها شركة السديم ، بالإضافة إلى الخدمات المجانية الأخرى."])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تفوت الفرصة"])}
    },
    "r": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسعير على أساس نجاحك"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسعير قابل للتطوير"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسِّن طريقة عملك ، واكتشف أداة جديدة تمامًا وتخلص من المتاعب مرة واحدة وإلى الأبد."])},
      "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العديد من الجوائز والميزات الغنية"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اجتماعي"])},
      "n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["250 طالبًا أو أكثر"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية"])},
      "n1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500 طالب فأكثر"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طليعة"])},
      "n2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب يتجاوز 1000"])}
    },
    "p": {
      "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عروض أسعار مخصصة"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تملك أكثر من شركة"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'إذا كان لديك أكثر من مؤسسة تعليمية مع عدد متفاوت من الطلاب ، في نفس البلد أو في الخارج. نقدم لكم في السديم عروض أسعار خاصة وخدمات متنوعة'"])},
      "titl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب سعر خاص"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'في السديم نهتم بعملائنا ، ونسعى دائمًا لتحقيق رضاهم. نهتم بتقديم خدمات عالية الجودة بأسعار مناسبة.'"])},
      "tit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'مجموعة شركات في نفس البلد'"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'نقدم لكم في السديم مجموعة من الخدمات المميزة. إذا كنت تمتلك أكثر من منشأة في نفس البلد ، فلدينا الكثير من الخدمات لك.'"])},
      "ti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'خدمات خاصة لك'"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'نسعى دائمًا في السديم لتقديم خدمات عالية الجودة تضمن لك استمرارية أعمالك وتوسعها.'"])}
    },
    "c": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قارن جميع الخطط"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماذا عن المقارنة؟"])}
    },
    "faq": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقرأ الأسئلة الشائعة"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك سؤال؟"])},
      "q": {
        "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يتم الدفع عبر الإنترنت أو بالحضور؟"])},
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن السداد بعدة طرق ، سواء كانت إلكترونية ، أو من خلال الحساب المصرفي لشركتنا ، أو شخصيًا من خلال وكيلنا في بلدك ، أو بأي طريقة أكثر سلاسة لمؤسستك."])},
        "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يمكن تقسيم المبلغ?"])},
        "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم يمكن تقسيمها حسب الاتفاق المبرم بين المؤسسة وشركتنا عند اتمام العقد."])},
        "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يشمل جميع الخدمات الإضافية مثل الدعم الفني الدائم والتدريب؟"])},
        "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم ، يشمل المبلغ المتفق عليه جميع هذه الخدمات التي تقدمها شركتنا لمؤسستك."])},
        "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا أردنا إضافة ميزات ، فهل سيكون ذلك بتكلفة إضافية؟"])},
        "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا يعتمد على فئة مؤسستك. يتم تقديم هذه الخدمة مجانًا لبعض المؤسسات ، بينما تكون بتكلفة محدودة للآخرين."])},
        "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي مدة الاشتراك بالمبلغ المتفق عليه؟"])},
        "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراك معنا لمدة عام كامل ، مما يعني أن تكلفة الاشتراك لمدة 12 شهرًا ، وبالتالي يمكن للمنصة خدمتك في أي دورات صيفية في مؤسستك."])}
      }
    }
  },
  "admin": {
    "1": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سديم أستاذ"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العقول وراء"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام يعمل على تقديم خدمات عالية الجودة لإدارة المدرسة وتحويل نظامها الإداري التقليدي إلى نظام إلكتروني متكامل يساهم في تطوير وتزويد الوحدات التعليمية بقنوات معرفية حديثة جديدة"])}
    },
    "2": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نافذة الواحدة"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضمن هذه النافذة البقاء على إطلاع دائم بكل مايجري بالمؤسسة ، مع إمكانية التمتع بكافة الصلاحيات الممنوحة من قبل إدارة المؤسسة .  ومن خلالها يمكن الوصول بشكل أسرع إلى جميع المنشورات والتفاعل بشكل أسرع مع الطلاب.  ومن خلالها يتم التنظيم والأرشفة والفلترة حسب المنشور أو الصف ."])}
    },
    "3": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مميزات التطبيق"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام المحادثة متكامل"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إنشاؤه لضمان بيئة اتصال آمنة بين المعلم والطالب وتحت إشراف الإدارة. يدعم هذا القسم إرسال واستقبال جميع أنواع الوسائط المختلفة (العلامات الصوتية ، ملفات pdf ، الصور ، الروابط المختلفة ، المطبوعات من سديم ، إلخ ..). يدعم هذا القسم إنشاء:"])},
      "f": {
        "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بث مباشر"])},
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن إنشاء درس مباشر وتفاعلي عبر الإنترنت لطالب معين من خلال نظام المحادثة"])},
        "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء مجموعات"])},
        "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم المحادثة في منصة سديم يدعم نظام المجموعات حيث يسمح بإنشاء مجموعات خاصة تتسع لأكثر من 200 عضو."])},
        "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترشيح متقدم"])},
        "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عبر سديم ، يمكنك تصفية الرسائل إلى محادثة جماعية وفردية مقروءة وغير مقروءة ، ويمكن للمدرس أيضًا تصفية الفصول والأشخاص لسهولة الوصول إلى الطلاب"])},
        "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال الوسائط"])},
        "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتيح التطبيق إرسال واستقبال جميع الوسائط مثل ملفات الصوت وملفات pdf وبصمات الأصابع الصوتية والصور"])}
      }
    },
    "4": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام الاختبارات الإلكترونية الذكي"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مميزات التطبيق"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد قمنا بتطوير نظام اختبار داخل سديم ، وهو متوفر بعدة أنواع (سريع ، منتظم). من خلالها يمكن التحكم بنوع الإجابة التي يطلبها الطالب حيث أنها متوفرة بأكثر من نوع ليتمكن الطالب من الإجابة بطرق مختلفة حسب السؤال المطلوب. يمكنه تحميل إجابة على شكل فيديو - صورة - السبورة (السبورة الذكية) - بصمة الصوت."])},
      "f": {
        "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختبار سريع"])},
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بهذا النوع يكون التصحيح آليًا دون تدخل من المدرس ، حيث يقوم المعلم بسرد الأسئلة والدرجة المستحقة لكل سؤال ، والتي تكون في شكل اختيار متعدد ، ولا يشترط أن تكون جميعها من نفس النوع. يتيح هذا النوع للطالب الحصول على النتيجة بسرعة وتحديد نقاط الفشل إن وجدت."])},
        "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختبار اعتيادي"])},
        "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في هذا النوع يكون التصحيح يدويًا من قبل المعلم ، حيث يقوم بسرد الأسئلة والدرجات المستحقة لكل سؤال ، مع تحديد نوع الإجابة المطلوبة من الطالب ، بحيث يمكن للطالب مشاركة الوسائط المختلفة مع الإجابة ، مثل الإجابة هي الصوت - النص - الفيديو - أو الاختيار من متعدد."])}
      }
    },
    "5": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أشعارات"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صمم سديم هذا القسم ليوفر لك معرفة كاملة بكل ما يتعلق بك ونشاطك ونشاط طلابك ، مع ميزة التصفية المتقدمة حيث يمكنك إظهار جميع الإشعارات أو الإخطارات الخاصة بقسم أو نشاط معين وهناك نوعان من إشعارات:"])},
      "f": {
        "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشعارات مباشرة"])},
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في هذا النوع ، يصل إخطارك إلى خارج التطبيق ، ويكون الإخطار إما مرتبطًا بإشعار من قبل إدارة المؤسسة ، أو إشعار متعلق بنشاط الطالب (تسليم مهمة - الإعجاب بمنشور - التعليق على منشور - رسائل. .)"])},
        "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشعارات داخل التطبيق"])},
        "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد قسم مخصص ، مع تصفية متقدمة ، لتمكين الوصول السريع إلى الإخطارات ولضمان الاستجابة السريعة لتفاعلات الطلاب ، وتنقسم الإخطارات إلى إشعارات خاصة ، أو إشعارات بث مباشر ، أو واجبات منزلية ، مع التنقل السلس بين الأقسام."])}
      }
    },
    "6": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داخل سديم"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقيبة تعليمية متكاملة"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتمكين المعلم من إنشاء دروس فيديو احترافية بعلامة صوتية والعديد من الوسائط الداعمة الأخرى مع ميزة الأرشفة أيضًا:"])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرشيف"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تصميم هذا القسم ليكون بمثابة مسودة للمعلم أو المسؤول ، حيث يمكنه جمع عدد لا حصر له من المنشورات حتى يتمكن من نشرها وتعديلها لاحقًا. كما يسمح التطبيق له بأرشفة أي منشور . أو إنشاء وظيفة جديدة."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدولة المنشورات"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تضمن ميزة الجدولة نشر المنشورات المختلفة في وقت محدد مسبقًا عند الجدولة ، مع إمكانية الأرشفة والتحرير. بمجرد جدولته ، يتم حفظ المنشور المجدول في قسم خاص يمكن للمدرس تحريره ومشاركته عبر الرسائل."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاختبارات والتمارين"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا القسم مخصص لضمان الحفاظ على جميع الاختبارات والتمارين المنشورة سابقاً والتأكد من استخدامها كحزمة تعليمية متكاملة ليستفيد منها المعلم أو المسؤول في السنوات القادمة ، مع إمكانية التحرير والتعديل."])}
    },
    "7": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم  الطلبة"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعمل هذا القسم بفلترة متقدمة جداً يستطيع التربوي التحكم بإظهار تقارير طلبة صف معين وشعبة معينة ليتمكن من متابعة الطلبة عن كثب ومن نقرة زر يستحصل تقرير شامل عن الطالب متضمن :"])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج الواجبات المنزلية "])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تظهر جميع نتائج الواجب المنزلي للطلاب ، مع سهولة الوصول إلى الواجب المنزلي وعرض الواجب مع إجابات الطلاب. في هذا القسم يتم تقييم مستوى الطلاب من خلال النسبة المئوية."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الحضور والغياب"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوضح هذا القسم جميع غيابات الطالب المحددة بالتاريخ والوقت بالتفصيل مع ملاحظات خاصة لكل غياب ترسله الإدارة. كما يوضح عدد الإجازات المبررة وغير المبررة وحضوره كذلك."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إبلاغ إدارة أي طالب"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا خيار يسمح للمعلم بإبلاغ إدارة المؤسسة عن طالب في حالة سوء المعاملة أو الإهمال ، حيث تم تصميمه لضمان المتابعة عن كثب للطالب والتأكد من متابعته سلوكياً وتعليمياً."])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج الاختبارات والتمارين"])},
      "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تظهر جميع نتائج الطلاب في الاختبارات والتمارين حسب التسلسل الزمني ، وبتصنيف متقدم يضمن الوصول السريع إلى أي اختبار ومعرفة نجاح الطلاب في موضوع اختبار معين."])}
    },
    "8": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيارات متقدمة للمنشورات"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نضمن بمنصة سديم أن يكون هناك سلالة بالاستخدام وسهولة بالنشر وتحقيق الوصول الأسرع والفعال لكافة الطلبة ، حيث هيئنا للمعلم صلاحيات عديدة  للتحكم بالمنشورات المنشورة:"])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة نشر المنشورات"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين المعلم أو الإداري من إعادة نشر مختلف المنشورات المنشورة سابقا لصف آخر وشعبة آخرى مختلفة, مع ضمان نشر كامل محتوى المنشور من صور ونصوص بالكامل وإمكانية التحرير والتعديل عليه ."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير المنشور"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من الممكن تعديل جميع المنشورات المنشورة بسهولة ، مع ميزة تلقي إشعار للطلاب بأن المنشور قد تم تعديله ، وظهور كلمة معدّل في أعلى المنشور يشير إلى أن المنشور قد تم تعديله."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميزة مشاركة المنشورات"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال منصة سديم ، يمكن مشاركة أي منشور موجود إما من خلال الرسائل داخل التطبيق ، أو مشاركته من خلال تطبيقات أخرى ، أو مشاركته عبر ميزة Qr code لضمان الوصول إلى جميع الطلاب."])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف منشور"])},
      "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن للتربوي حذف أي منشور موجود على المنصة من خلال الضغط على الثلاث نقاط الخاصة بالمنشور ."])}
    },
    "9": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصول سريع"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنشأنا هذا القسم لتسهيل الوصول إلى:"])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوسائط والصور"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتيح لنا سهولة الوصول إلى جميع الوسائط التي يتم تحميلها على النظام مع سهولة تصنيفها حسب نوعها (ملفات صوتية ، صور ، روابط ، فيديوهات ، ملفات بي دي إف)."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أهم الأحداث"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في هذا القسم ، تظهر جميع أحداث المنظمات بأسلوب حديث وحديث ، حيث تظهر في تصنيف ووفقًا لتسلسل زمني ، لضمان بقائك على اطلاع بجميع الأحداث المهمة للمنظمة."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص المنشورات"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال هذا القسم يمكن اختصار أهم وأحدث المنشورات الخاصة بآخر الأحداثفيما إذا كان هناك  :  بثوث مباشرة الآن  ,الواجبات المنزلية الحالية ، الاختبارات الإلكترونية الحالية عند النقر على أي منها يتم التوجيه المباشر للمنشور"])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العرض الشبكي "])},
      "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم استحداثه لضمان سهولة الوصول للمنشورات بأسلوب عصري وحديث ، حيث تعرض جميع المنشورات بتفاصيلها الكاملة بشكل شبكة وعند النقر على أي منشور يتم الانتقال إليه بكل بسلاسة  .."])}
    },
    "10": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المواد (التصنيف الذكي)"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمنح هذا القسم التصفح الذكي حيث تظهر قائمة بالصفوف عند النقر تظهر جميع مواد هذا الصف متضمنة ما تم نشره من منشورات مختلفة ( درس ، واجب بيتي ، بث مباشر، تبليغ ، اختبارات ) مع إمكانية الوصول و عرض فقط الوسائط الخاصة بالمنشورات ."])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف حسب الصف والمادة"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تظهر جميع الصفوف الخاصة بالمعلم والمواد التعليمية المسندة إليه ، مع مؤشر يوضح ماقد تم نشره من، وعند النقر عليه ، يتسم هذا القسم بالانسيابية بالتنقل حيث مجرد السحب يمكننا من الوصول إلى المنشورات وهي مصنفة تبعاً للنوع ( دروس اختبارات _ واجبات بيتية _ بثوث مباشرة ) ."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف حسب نوع المنشور "])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بهذا القسم وبعد اختيار الصف والشعبة والمادة تظهر واجهة تحوي جميع المنشورات الخاصة بالصف الذي تم اختياره سابقاً ومصنفة بتصنيف ذكي يوضح نوع المنشور مع سهولة الوصول إليه من خلال ضغطة زر واحدة .  كما مكنا الوصول للوسائط فقط الخاصة بالمنشورات من خلال أختصار عند النقر عليه يتم التوجه للوسائط."])}
    },
    "11": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلترة المتقدمة "])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قمنا بتمكين المعلم من تصفية أنواع المنشورات المختلفة حسب موضوعاته أو حسب الفصل أو القسم ، حتى يتمكن من عرضها بسلاسة على صفحة آخر الأخبار. حيث يمكّنه المرشح من الوصول إلى المطبوعات بشكل أسرع ووفقًا لتسلسل زمني."])}
    },
    "12": {
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الواجبات البيتية المتقدمة"])},
      "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ويمكن للمعلم اختيار وقت لتسليم الواجب ، فور انتهاءه لا يمكن للطلبة رفع أي إجابة "])},
      "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يكون التصحيح يدوياً بجميع الأنواع عدا نوع تأكيد على الحل ونوع بدون  فوجدوا للتأكيد على الطالب بضرورة بأمر معين أو تأكيد على أداء الواجب دون رفع إجابة من قبل الطالب "])},
      "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بجميع الأنواع يوجد تحكم كامل بالصلاحيات الممنوحة للطلبة حيث يمكن :"])},
      "c4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•حجب خاصية التعليقات"])},
      "c5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•عرض إجابات الطلبة بعضهم البعض أو منع ذلك "])},
      "c6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نتيح للمعلم إعادة نشر الواجب المنشور سابقاً  ، لصف آخر وشعبة آخرى  بكافة التفاصيل مع إمكانية التعديل عليه . كما يمكن للمعلم الجدولة أو الأرشفة ، مع إمكانية التحرير بعد النشر  لاستخدامها لوقت لاحق "])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استحدثنا هذا القسم لتحديد مستوى استيعاب الطلبة للدرس والمتابعة الدقيقة لهم .فهي بعدة أنواع ( بدون - تأكيد على الحل- إرفاق ملف - إدخال نص - اختيار من عدة خيارات ) تمكن المعلم من التواصل الفعال مع الطلبة ، حيث يمكن تحديد نوع إجابة الطالب ."])}
    }
  },
  "student": {
    "1": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سديم طالب"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العقول وراء"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام يعمل على تقديم خدمات عالية الجودة وتوفير مكتبات الكترونية وتفاعلية مرئية وصوتية ومقروءة وادوات توضيحية وتعليمية ومناهج متطورة تعمل على تطوير مستوى الطالب العلمي والتربوي وتنمية مداركه العقلية والفكرية إضافة إلى التقارير الخاصة بالطالب كتقارير الحضور والغياب والتقييمات والدرجات اليومية والشهرية."])}
    },
    "2": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدعم هذا القسم المشاهدة"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقساط"])},
      "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ إجمالي القسط"])},
      "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ القسط المتبقي"])},
      "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ القسط مع تفاصيل التاريخ"])},
      "c4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["√ اقساط الباص"])},
      "c5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراقبة الحضور والغياب والإجازات داخل الشعبة بتقارير إلكترونية عن حالة كل طالب يمكن تقديمها للمعلم عند الطلب."])}
    },
    "3": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات اختيارية"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم الحضور والغياب"])},
      "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتمكين ولي الأمر والطالب من متابعة الفحص اليومي عن كثب والغيابات المعذرة وغير المبررة"])},
      "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حيث تظهر تفاصيل حضور الطالب كاملاً سواء كان في إجازة أو حاضرًا أو غائبًا"])}
    },
    "4": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سديم طالب"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحداث"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هي واجهة يتم فيها عرض جميع شهور السنة ، وتعرض بالأيام جميع الأنشطة المهمة للطالب التي أنجزها أم لا."])}
    },
    "5": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام دردشة متكامل"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدعم هذا القسم مشاركة الوسائط المتعددة مع موظفي المؤسسة ، كما يدعم التصفية المتقدمة ، حيث يمكن تصنيف المحادثات المقروءة وغير المقروءة والمجموعات والمحادثات الفردية. على منصة سديم ، يمكن للطالب أيضًا إرسال ومشاركة جميع أنواع الوسائط المتعددة (صور _ لوحة إلكترونية _ روابط متنوعة _ بصمات صوتية). يمكن عمل المشاركات المنشورة في سديم في المحادثات."])}
    },
    "6": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الواجبات البيتية المتقدمة"])},
      "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ويمكن للمعلم اختيار وقت لتسليم الواجب ، فور انتهاءه لا يمكن للطلبة رفع أي إجابة "])},
      "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يكون التصحيح يدوياً بجميع الأنواع عدا نوع تأكيد على الحل ونوع بدون  فوجدوا للتأكيد على الطالب بضرورة بأمر معين أو تأكيد على أداء الواجب دون رفع إجابة من قبل الطالب "])},
      "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بجميع الأنواع يوجد تحكم كامل بالصلاحيات الممنوحة للطلبة حيث يمكن :"])},
      "c4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•حجب خاصية التعليقات"])},
      "c5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["•عرض إجابات الطلبة بعضهم البعض أو منع ذلك "])},
      "c6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نتيح للمعلم إعادة نشر الواجب المنشور سابقاً  ، لصف آخر وشعبة آخرى  بكافة التفاصيل مع إمكانية التعديل عليه . كما يمكن للمعلم الجدولة أو الأرشفة ، مع إمكانية التحرير بعد النشر  لاستخدامها لوقت لاحق "])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استحدثنا هذا القسم لتحديد مستوى استيعاب الطلبة للدرس والمتابعة الدقيقة لهم .فهي بعدة أنواع ( بدون - تأكيد على الحل- إرفاق ملف - إدخال نص - اختيار من عدة خيارات ) تمكن المعلم من التواصل الفعال مع الطلبة ، حيث يمكن تحديد نوع إجابة الطالب ."])}
    },
    "7": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتبة الكترونية"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتبة إلكترونية ثرية وتفاعلية ومرئية ومسموعة ومقروءة وأدوات توضيحية وتعليمية ومناهج متطورة تعمل على تطوير المستوى العلمي والتعليمي للطالب. لتمكين الطالب من مراسلة إدارة المؤسسة وجميع المعلمين مع إمكانية إرسال مختلف الوسائط المرئية والمسموعة وملفات pdf."])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليقات تفاعلية"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتيح نظام سديم التفاعل المرئي والصوتي من خلال التعليقات وإمكانية الرد والحذف والتحرير أيضًا."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدولة المشاركات"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تضمن ميزة الجدولة نشر المنشورات المختلفة في وقت محدد مسبقًا عند الجدولة ، مع إمكانية الأرشفة والتحرير. بمجرد جدولته ، يتم حفظ المنشور المجدول في قسم خاص يمكن للمدرس تحريره ومشاركته عبر الرسائل"])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات يومية وشهرية"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتابعة بسلاسة وفورية على النتائج المرسلة من قبل الإدارة والمعلمين."])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الطلاب"])},
      "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن للمدرس متابعة الطلاب عن كثب ، ويحصل على تقرير شامل عن الطالب متضمنًا نتائج الاختبارات والتمارين والواجبات المنزلية والحضور والغياب."])}
    },
    "8": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليقات تفاعلية"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتيح نظام سديم التفاعل المرئي والصوتي من خلال التعليقات وإمكانية الرد والحذف والتحرير أيضًا. يظهر اسم وصورة صاحب كل تعليق."])}
    },
    "9": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام الاختبارات الإلكترونية الذكي"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للتأكد من تحديد مستوى الطالب فهو متوفر بنوعين ، السريع والعادي ، ونضمن من خلاله إمكانية حلها من خلال الوسائط المتعددة (صور _ فيديو _ سبورة بيضاء _ اختيارات متعددة _ بصمات صوتية) . كما يمكن الوصول إلى جميع الاختبارات والتمارين المكتملة والمصححة من خلال قسم خاص يحتوي على جميع الاختبارات."])}
    },
    "10": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجات الیومیة والشھریة"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للمتابعة بشكل سلس و فوري للنتائج المرسلة من قبل الإدارة والمدرسين  حيث يوجد قسم مخصص لكلاً من الدرجات اليومية والشهرية مع ضمان سهولة التنقل بينهما .  يمكن اختيار وتحديد الشهر ويتم عرض جميع الدرجات مع تحديد مستوى الطالب بشكل دقيق ونسبه نجاحه أو نسبه الأخفاق"])}
    },
    "11": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصى"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم مخصص لتقارير الطلاب والمتابعة على المستوى الأكاديمي من خلال تقرير خاص يحتوي على نتائج جميع الاختبارات والواجبات والتمارين وحضور الطالب وغيابه مع تحديد نسبة النجاح في كل مادة. يتضمن هذا القسم:"])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج الاختبارات والتمارين"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرفة نسبة نجاح الطالب في مادة اختبار معينة."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج الواجبات المنزلية"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تظهر جميع نتائج الواجب المنزلي للطالب ، مع سهولة الوصول إلى الواجب المنزلي ومعاينة المهمة مع حجب إمكانية التعديل عليها. كما يتم في هذا القسم تقييم مستوى الطلاب من خلال نسبة مئوية توضح مدى تقدمه ويتم رصد مستواه."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الحضور والغياب"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوضح هذا القسم جميع حالات الغياب للطالب ، المحددة بالتاريخ والوقت ، بالتفصيل ، مع ملاحظات عن كل غياب ترسله الإدارة. كما يبين عدد الإجازات المبررة وغير المبررة وحضوره كذلك."])}
    },
    "12": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الأسبوعي"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للاطلاع على برنامج الحصص وتحديد وقت الحصة التفصيلي وفيما إذا كانت إلكترونية أو حضورية مع ذكر الترتيب الخاص بالحصة"])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج الاختبارات والتمارين"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرفة نسبة نجاح الطالب في مادة اختبار معينة."])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج الواجبات المنزلية"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تظهر جميع نتائج الواجب المنزلي للطالب ، مع سهولة الوصول إلى الواجب المنزلي ومعاينة المهمة مع حجب إمكانية التعديل عليها. كما يتم في هذا القسم تقييم مستوى الطلاب من خلال نسبة مئوية توضح مدى تقدمه ويتم رصد مستواه."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الحضور والغياب"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوضح هذا القسم جميع حالات الغياب للطالب ، المحددة بالتاريخ والوقت ، بالتفصيل ، مع ملاحظات عن كل غياب ترسله الإدارة. كما يبين عدد الإجازات المبررة وغير المبررة وحضوره كذلك."])}
    },
    "13": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكثر من حساب على جهاز واحد"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدعم كلا التطبيقين (مدرس _ طالب) إضافة أكثر من مستخدم على جهاز واحد ، لضمان تحقيق الفائدة المرجوة."])}
    }
  },
  "dashboard": {
    "1": {
      
    },
    "2": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصاءات شاملة ودورية"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للطلاب والموظفين في المؤسسة"])}
    },
    "3": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات دورية"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوضح هذا القسم بالتفصيل أنشطة المعلمين ومدى تفاعلهم من خلال المنصة مع الطلاب ، حيث يعرض إحصائيات لكل معلم توضح عدد المنشورات وعدد الرسائل المرسلة. من خلال هذه الإحصائية ، يتم تقييم المعلمين وتصنيفهم حسب من هم أفضل المعلمين والذين يحتاجون إلى المتابعة."])}
    },
    "4": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام دردشة متكامل"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في منصة سديم ، لا نعتمد الطرق التقليدية للمراسلة. نعتمد على التواصل الفعال بين أعضاء المؤسسة وولي أمر الطالب. عند استخدام قسم الرسائل في سديم ، ستكون الإدارة قادرة على إرسال المستندات ومقاطع الفيديو والوسائط المختلفة ، ويمكنها أيضًا إنشاء مجموعات تتسع لأكثر من 200 عضو بموجب صلاحيات واسعة تمنحها الإدارة لمن يستطيع الإرسال. رسائل."])}
    },
    "5": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم المؤسسة"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" خاص بكل مايتعلق بإدارة المؤسسة"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال هذا القسم تستطيع إضافة وتعريف الصفوف والشعب والمواد الدراسية ، ومنح إذن الوصول للأساتذة إليها.  كما بإمكان مدخل البيانات أو الإدارة توزيع الحصص للأساتذة ، وإدراج الجدوال الامتحانية والأسبوعية أيضاً، وتعريف خطوط النقل على الخارطة أو أرشفة بيانات المؤسسة بالكامل."])}
    },
    "6": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكاملات"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام المحاسبة والمالية"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يشمل نظام المحاسبة والفوترة الإلكتروني: أقساط الطلاب مع إمكانية تصفيتها وطباعتها. قسائم الموظفين. رسوم الحافلة. الواردات والنفقات. تم تصميم هذا القسم ليكون آمنًا ومناسبًا للقطاع التعليمي بكل متطلباته الدقيقة وكل ما تحتاجه إدارة المؤسسة."])}
    },
    "7": {
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واحد لواحد"])},
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتابعة والإشراف"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بكل سهولة وبساطة يقوم المشرف التربوي أو مدير المؤسسة بمتابعة فصول الأساتذة تباعا وحضور الطلاب والأساتذة في الدرس ، كما يمكن معرفة التقدم العلمي للمؤسسة ومستوى توعية المعلمين للطلبة من خلال إحصائيات ذكية ودقيقة ، والنظام مزود بالعديد من الإحصائيات ، حتى تتمكن الإدارة من اتخاذ القرار المناسب."])}
    }
  },
  "check": {
    "1": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سديم شيك ان"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العقول وراء"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق مخصص للمؤسسات التعليمية لتسهيل عملية أخذ الحضور والغياب من خلال قراءة ومسح كود QR للطلاب والمعلمين. يقوم التطبيق بأداء مهامه على أكمل وجه ، حيث يمكنك من خلال الاستبيان أن تأخذ حضور وغياب طلاب فصل معين وقسم محدد في غضون دقائق قليلة ، مع إمكانية معرفة ما إذا كان الطالب ينتمي إلى المؤسسة أم لا."])}
    }
  },
  "partner": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كن شرًيك"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شارك معنا النجاح والتميز. نعزز علاقاتنا القوية في جميع الأسواق التي ستساعدنا في الوصول إلى عملائنا في كل مكان. نرحب بكم كشريك معنا."])},
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسباب لماذا نحن الأفضل"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملاء والشركاء"])},
    "s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعاون"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقيق نتائج سريعة ومستدامة"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال التركيز على العميل واحتياجاته في السديم ، يتيح نهجنا المرن لاستراتيجية البيانات وهندسة البيانات وإدارة البيانات وعلوم البيانات وإدارة برنامج البيانات لعملائنا تحقيق مكاسب سريعة في تحويل رؤية الأعمال إلى ابتكارات تتمحور حول البيانات."])},
    "s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق ديناميكي من خبراء البيانات"])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في السديم ، نحن ملتزمون بتحقيق النجاح لعملائنا وموظفينا - ولهذا السبب لدينا أفضل الخبرات ، يتمتع فريقنا بسنوات من الخبرة ، مما يجعلنا الشريك المثالي لجميع احتياجات البيانات الخاصة بك."])},
    "s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النجاح"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إطار عملنا الاستشاري"])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طور السديم إطار عمل تشاوري فريد من نوعه يسمح لنا بتحقيق مكاسب سريعة لعملائنا وتقليل تكلفة ووقت تسييل البيانات."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نرحب بك كشريك معنا واستمتع بوقتك :)"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البدء"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بريدك الإلكتروني"])}
  },
  "events": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر أخبارنا"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدونة الشركة"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بتاريخ 11  ديسمبر من عام 2017"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في مديرية التربية الخاصة بمنطقة الرصافة الأولى _ بغداد"])},
    "v1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وبالشراكة مع شركة زين العراق وبحضور  السيد فلاح محمود القيسي ممثلاً عن مديرية تربية الرصافة سابقاً و وكيا وزارة التربية حالياً والسيد حيدر الخفاجي و السيد أسعد سليم ممثلان عن إدارة شركة السديم  حيث تم بمباركة أولياء الأمور وعدسات الإعلام إطلاق منصة سديم التعليمية الأولى من نوعها بالخدمات والمزايا .سعت مديرية تربية الرصافة الأولى إلى تطبيقه حيث كانت أولى المديرات في العراق والتي تسعى أن تمتلك تقنيات متطورة لرفع سوية التعليم كما اعتدنا دائماً في السديم لتكنولوجيا المعلومات أن نكون الشركة السباقة بإصدار تقنيات تعنى برفع جودة التعليم في المؤسسات التعليمية "])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في أكتوبر  من عام 2017"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ضمن إطلاق المشاريع الرقمية الحديثة وتحت رعاية شركة السديم ممثلة ب السيد حيدر الخفاجي و السيد أسعد سليم "])},
    "v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["و السيد أسعد سليم ومديرية تربية الرصافة الأولى ممثلة بالدكتور فلاح محمود القيسي و شركة زين للاتصالات الممثلة ب السيد  درغام رعد. تم تبني مشروع تدريب أكثر من 70 مشرف تربوي ، و33 مؤسسة تعليمية بإشراف السيد لؤي شاكر رئيس لجنة الإشراف على تطبيق سديم بلس حيث تم إنشاء أكثر من ورشة لتدريب  المؤسسات على التطبيق لضمان تطبيقه  بالشكل المطلوب بمايلبي الغرض الأسمى الذي سعينا من أجله بالسديم  لاستثمار الوقت والجهد في قطاع التعليم وتطوير إنتاجهوعليه تم الاتفاق بداية على تطبيق منصة سديم بلس في سبعة مدارس حكومية ، والتحقق في ما إذا لاقت القبول لاعتمادها رسمياً لكافة المدارس التابعة للمديرية ."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احتفال السديم بلس"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حياء حفل تكريم واختتام دورة سديم بلس للمشرفين التربويين والمؤسسات حيث أقيمت في مدرسة الحريري في بغداد"])},
    "v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بتاريخ 12 ربيع الأول من عام 2017  بحضور كلاً من السيد محمود فلاح القيسي ممثلاً عن مديرية تربية الرصافة الأولى والسيد درغام رعد ممثلاً عن شركة زين للاتصالات . تم الاتفاق على تطبيق نظام سديم على كافة المدارس  الحكومية التابعة لمديرية تربية الرصافة الأولى لعام ٢٠١٧_٢٠١٨ وذلك بعد نجاح تجربة تطبيق منصة سديم بلس في أكثر من 7مدارس حيث لاقت رواجاً وحققت الهدف الأسمى للمنصة إلا وهو ضمان التواصل الفعال بين ثلاثية إدارة معلم طالب _ولي أمر وفق تقنيات تفاعلية متطورة. تم تكريم المعنين وإلقاء كلمة من قبل لؤي شاكر رئيس لجنة الإشراف على تطبيق سديم حيث تم توضيح آلية عمل المنصة بالكامل ومايمكن أن تقدموه شركة السديم بالتعاون مع زين العراق للمؤسسة حيث تم شرح التفاصيل كافة.."])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21 يونيو 2018"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زين العراق تحتفل بنجاح تطبيق سديم بلس من خلال تقديم الحلول التكنولوجية والرقمية"])},
    "v4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوفر إمكانية متابعة المستوى التعليمي لأولياء الأمور ، والحضور اليومي ، والواجب المنزلي ، والتواصل مع إدارة المدرسة من خلال الاشعارات الإلكترونية."])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسابقة الخليجية الرابعة في دولة الإمارات "])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقامت شركة السديم لتكونولجيا المعلومات وبالتعاون مع شركة سمارت برين للحساب الذهني المسابقة الخليجية الرابعة في دولة الامارات"])},
    "v5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غرفة تجارة وصناعة الشارقة شاركت فيها دول الخليج ( الكويت - السعودية - البحرين - قطر الامارات - العراق ) بتاريخ 27/8/2022 عدد الطلاب المشاركون من العراق 14 طالب عدد الطلاب الذين حققوا مراكز 10 على مستوى الخليج.ولأننا بالسديم نؤمن بأن المنافسة هي فتيل الإبداع والإبتكار ودوماً كنا حريصين على تمثيل أسم دولة العراق عملنا جاهدين لكي نحقق المزيد من التقدم وتوفير سبل التميز والمثابرة وخلق فرص لأبناء العراق لرفع اسم بلدهم في المحافل الدولية .كان لمنصة السديم الفضل في إتاحة فرصة لتدريب الطلبة  المشاركين في المسابقة  في دورة الحساب الذهني سمارت براين لضمان مشاركتهم في المسابقة الخليجية الرابعة، حيث حققوا فيها ماسعوا إليه وطمحنا من أجله. وثابروا لنيل المراكز الأولى وفق تصنيفات مختلفة."])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17 نوفمبر 2022"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قامت شركة السديم بالتعاون مع  إدارة سمارت براين  برعاية مدرسة  ماما أيسر في بغداد حفل تكريم طلاب دورة الحساب الذهبي الفائزين بالمسابقة الخليجية التي أقيمت في الشارقة "])},
    "v6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["والحائزين على المراكز الأولى من كل محافظات العراق ، وقد حضر حفل التكريم وكرم كلاً من :-- السيد صدام القيسي (مدير مكتب الاعلام للسيد الدكتور فلاح القيسي وكيل الوزارة )- السيد سلمان الشمري (أختصاص تربوي أقدم في الوزارة ممثلاً عن السيد مدير عام الاشراف التربوي في الوزارة)- السيد باهر الزعبي (مسؤول smart brain في الشرق الاوسط وكندا)-  السيد مصطفى ناجي (نيابةً عن السيد أحمد جواد ادارة smart brain في العراق)- السيد حسنين علي يحيى (ممثل smart brain في الفرات الاوسط)- السيد طيار عبدالعظيم النعمه (ممثل smart brain في الشمال)- السيد حسين فاهم (مركز أيسن في محافظة القادسية)- السيد عقيل الحاج عبد الخطابي (مركز السفير في محافظة كربلاء)- السيد مقداد طالب( مركز سما الريان في محافظة كربلاء)- السيدة رواء حسين(ممثله عن مدرسة ماما ايسر)."])}
  },
  "learns": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم سديم طالب"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On December 11, 2017"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالذهاب إلى المحادثات والنقر على زر إضافة (+) الموجود في أسفل يسار الشاشة ، ثم النقر فوق"])},
    "v1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على الاسم الذي تريد إجراء محادثة معه ، ستظهر قائمة تحتوي على جميع الموظفين ، بما في ذلك المدرسون والمسؤولون."])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يمكنني الوصول إلى الدروس والمنشورات المنشورة بطريقة منظمة وسريعة؟"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال القائمة الموجودة في شريط التنقل ، نختار القائمة ، حيث تسمح لنا واجهة الدروس بذلك  "])},
    "v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنظيم جميع الدروس التي تم إنشاؤها مسبقًا. مصنفة حسب نوع الاختبارات - البث المباشر - الدروس يوجد اختصار في أعلى يسار الواجهة للوصول إلى الوسائط فقط."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يمكنني عرض تقرير عن نتائجي وجميع أنشطتي في المؤسسة؟"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك الحصول على تقريرك الخاص من خلال النقر على قسم الملف الشخصي في شريط التنقل ، ثم الانتقال"])},
    "v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["والنقر على ملف التعريف الخاص بك. ستظهر قائمة تحتوي على جميع نتائج الواجب المنزلي ونتائج الاختبارات والتمارين. مع تقرير مفصل عن حضورك وغيابك. ويمكن إضافة الميزات"])}
  },
  "learna": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم  سديم استاذ"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي قائمة الدروس؟ وما فائدة ذلك؟"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تضمن لك قائمة الدروس الوصول السريع إلى جميع فصولك مع تصنيف المواد لكل فصل."])},
    "v1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد اختيار الفصل الذي تريد الوصول إلى جميع منشوراته ، تظهر جميع المواد ، وعند النقر فوق أي مادة ، جميع المنشورات من الدروس _ الامتحانات _ الواجبات المنزلية ، مع إمكانية نشر درس احترافي مرة أخرى من خلال زر الإضافة الموجود على يسار الشاشة"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يمكنني التحكم في المحتوى في صفحةأخر الأخبار !؟"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نختار زر التصفية في الجزء العلوي الأيسر من واجهة صفحة آخر الأخبار. إنه موجود في ثلاثة أسطر. عندما تضغط عليها ،"])},
    "v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبثق قائمة تحتوي على ثلاثة حقول فيها rows_article - ونوع المنشور. نختار ما نريد الوصول إليه من المنشورات المتعلقة بفئة للخروج من وضع الفلتر ، يوجد زر x موجود على يسار الواجهة ، اضغط عليه ، وسيخرج من وضع الفلتر .."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي قائمة الدروس؟ وما فائدة ذلك؟"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تضمن لك قائمة الدروس الوصول السريع إلى جميع فصولك مع تصنيف المواد لكل فصل."])},
    "v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد اختيار الفصل الذي تريد الوصول إلى جميع منشوراته ، تظهر جميع المواد ، وعند النقر فوق أي مادة ، جميع المنشورات من الدروس _ الامتحانات _ الواجبات المنزلية ، مع إمكانية نشر درس احترافي مرة أخرى من خلال زر الإضافة الموجود على يسار الشاشة."])}
  },
  "learnd": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم لوحة التحكم"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يمكنني إدارة الصلاحيات ومنح الأذونات للموظفين !؟"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الدور: من خلال قسم إدارة الأدوار الموجود في الصفحة الرئيسية التحكم على اليمين ،"])},
    "v1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نختاره في البداية. ستظهر واجهة تحتوي على زر إضافة دور. بعد النقر عليه ، ستظهر قائمة تحتوي على اسم الدور وحقل مخصص للملاحظات. يتم ملء الحقل ، ثم أنواع الأذونات."])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يمكنني إضافة مدرس أو موظف جديد!"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم الموظفين: من داخل منصة سديم يمكنك بسهولة وأمان إنشاء حسابات للأساتذة أو الموظفين والتحكم في أذوناتهم ،"])},
    "v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال قسم الموظفين ثم اختر زر الإضافة. ستظهر واجهة تحتوي على حقول مخصصة لإدخال البيانات لكل موظف. بعد الدخول واختيار تم ، ستتم إضافة الموظف باسم المستخدم الخاص به (اسم user_password)."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي طريقة إنشاء صف على السبورة؟"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد جمعنا لك على منصة السديم كل ما هو مطلوب للسيطرة على المؤسسة ، مثل متابعة الفصول ، والمواد ، والمعلمين ،"])},
    "v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بكل سهولة ووضوح. يوجد قسم يسمى فصول ندخله ونختار زر إضافة ونضيف فصلاً جديدًا وقسمًا جديدًا ، ومن أجل إضافة مواد ، نبقى في قسم المؤسسة ، يتم عمل المواد بطريقة بسيطة وسهلة عن طريق النقر."])}
  },
  "about": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق من النخبة عالي الأداء"])},
    "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في السديم ، لدينا فريق من ذوي المهارات العالية انضم إلينا وقابل مجموعة من خبراء التكنولوجيا لتوفير الوقت وتعزيز الأداء وتحقيق النتائج في رحلة النمو الخاصة بك."])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيم شركتنا"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق متخصص"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احترام"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نقدر الجميع ونعامل الناس بكرامة واحترام."])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نزاهة"])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في السديم ، نبني الثقة من خلال الإجراءات المسؤولة والعلاقات الصادقة."])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نؤمن بالمرونة"])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نتفهم أن العمل والحياة يمتزجان معًا ، لذلك نحن نقدر مكان العمل غير الرسمي والمرن."])},
    "t8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العميل صعب"])},
    "t9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نؤمن بقوة الإرادة. نحن لا نبحث عن الطريق السهل ، بل نبحث عن أصعب التحديات. صمودنا الجماعي هو سلاحنا السري."])},
    "1": {
      "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركاء النجاح"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتحدثون عنا"])},
      "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تقدم خدمات استشارية للمؤسسات التعليمية للحصول على اعتمادات في جودة التعليم وتهتم بتطوير وإنشاء مناهج تعليمية وتدريبية وتحويلها إلى كتب تفاعلية ومحتوى إلكتروني رقمي. كما تمتلك دار نشر للكتب المدرسية بالإضافة إلى ضبط جودة التعليم وتأهيل وتدريب وتطوير الكوادر التعليمية في المؤسسات التعليمية.ولها مشاريعها على صعيد لبنان والعالم العربي ل صالح وزارت التربية ومنظمات دولية ومؤسسات تربوية عديدة. وهي شركة رائدة عالمياً بيع معتمد Authorized reseller لشركة CERTIPORT في منح شهادات "])},
      "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هي شركة عالمية رائدة في مجال تقديم شهادات الاختبارات القائمة على الأداء وحلول الاختبار للمؤسسات الأكاديمية والقوى العاملة وأسواق تكنولوجيا الشركات. يتم تقديم اختبارات Certiport من خلال شبكة واسعة تضم أكثر من 15000 مركز اختبار معتمد من Certiport في جميع أنحاء العالم. تقدم Certiport ملايين الاختبارات كل عام من خلال أسواق تكنولوجيا  الشركات تعمل Certiport مع مزودي الشهادات الرائدين في الصناعة لتقديم برامجهم بنجاح إلى السوق.من خلال خبرة خاصة في الأوساط الأكاديمية ."])},
      "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هو برنامج للرياضيات الذهنية يحتوي على مجموعة من المستويات التي تمكن الطالب بعد استكماله من إجراء عمليات حسابية (جمع وطرح وضرب وقسمة الأعداد الصحيحة والكسور) ذهنياً دون الحاجة لاستخدامها. أي أداة رياضية. حول المنصة المستخدمة المنصة التي يستخدمها طلاب Smart Brain لمنصة المستخدمة لطلبة سمارت برين وهي ممارسة الطلبة على تمارين مخصص بالمنهج  وهذا المنصة لا تستخدم فقط الا من قبل طلبة سمارت برين في العالم . ممارسة الطلاب على تمارين خاصة بالمنهج ، وهذه التمارين مصنوعة حسب الطلب"])},
      "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" شركة في سان خوسيه بولاية كاليفورنيا توفر خدمات الاتصال الهاتفي عبر الفيديو والدردشة عبر الإنترنت من خلال منصة برمجيات سحابية من نظير إلى نظير. يوفر Zoom برامج مكالمات الفيديو المستخدمة في المؤتمرات والاجتماعات عن بُعد ، بالإضافة إلى العمل والتعليم والعلاقات الاجتماعية عن بُعد."])},
      "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إن فريق Make Group ، الموجود في الكويت ، هي شركة رائدة في مجال التكنولوجيا التي تعزز استخدام التكنولوجيا الحديثة والابتكار من خلال تطوير المنتجات والإنشاءات ذاتية التشغيل. كما نعمل على توريد الأدوات والآلات.وإدارة المراكز والتدريب في المجالات التالية : التصميم الإلكترني ،البرمجة..  "])},
      "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هي شركة أمريكية متعددة الجنسيات تركز على التجارة الإلكترونية ، والحوسبة السحابية ، والإعلان عبر الإنترنت ، والبث المباشر ، وقد تمت الإشارة إليها على أنها واحدة من أكثر الاقتصاديات تأثيرًا في العالم ، إلى جانب Alphabet (Google) و Apple و Meta (Facebook) ، ومايكروسوفت.ومن افضل الشركات من حيث تعامل واحترام الزبون"])}
    }
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عندما تتوحد الرؤية والإبداع لخلق مستقبل  لجيل جديد"])},
    "Discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف"])},
    "ts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات التقنية"])},
    "ec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناهج التعليمية"])},
    "bp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كن شريك"])},
    "Q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جودة التعليم(قيدالتطوير)"])},
    "ALSadeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السديم"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول الشركة"])},
    "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملاء"])},
    "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحداث وأخبار"])},
    "j": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرصة عمل"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدعم الفني "])},
    "sc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مركز الدعم الفني"])},
    "tu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الاستخدام"])},
    "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
    "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة الشائعة"])}
  },
  "tabel": {
    "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مميزات"])},
    "fu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تدريب كامل"])},
    "ts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعم تقني"])},
    "cu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديثات مستمرة"])},
    "us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذاكرة لامتناهية"])},
    "ea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمتع في جميع الخدمات"])},
    "an": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد من الحسابات المجانية"])},
    "ie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مناهج اثرائية عالمية"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضفاء الطابع الشخصي"])},
    "d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة الميزات التي ترغبها المؤسسة إلى المنصة مجانًا"])}
  },
  "l": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خذ الوقت الكافي لقراءة الأسئلة الشائعة"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسئلة متداولة"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك فكرة عن تطبيق أو موقع إلكتروني؟"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشرح لنا ونقوم بتنفيذ أنسب الأسعار واحترافية عالية ليس فقط الاحتراف في عملنا ولكن نوفر السهولة والبساطة في تصفح موقعك لإعطاء تجربة فريدة لعملائك. نقدم خدمة الاستضافة على أحدث الخوادم مع خدمات الأمن الرقمي لحماية المواقع والتطبيقات يعمل فريقنا على لغات برمجة مختلفة تلبي احتياجات مشروعك. لتحويلها من فكرة إلى نظام عمل متكامل"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لست بحاجة إلى كل هذه الميزات ، فلماذا أدفع مقابلها؟"])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلما زاد استخدامك لما لديك ، زادت حاجتك إلى استخدامه ، أي كلما زادت الميزات التي تستخدمها الميزة التي بين يديك ، كنت بحاجة إلى المزيد من الميزات لاستخدامها ، وعلى الرغم من ذلك ، فإن اهتمام مؤسستك ورضاك هما من أولوياتنا ، لذلك يمكننا توفير الميزات بقدر ما تحتاجه مؤسستك ، بتكلفة أقل وميزات مستخدمة بالكامل ، دعنا نبتعد عن أي إرباك لموظفيك المتفوقين ، وأي إهدار لمواردك المالية"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما الذي يميزك عن المنافسين الآخرين؟"])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقدم لك منصة مبنية على سنوات من التحليل الدقيق لكل مشاكل وصعوبات قد تكون عقبة أمام نجاح وتميز أي مؤسسة تعليمية ، بميزات وخيارات جديدة تمامًا ، بالإضافة إلى أن شركتنا تمنحك إمكانية التعامل شخصيًا واختبار جميع ميزاته لمدة شهرين كاملين ، نضمن لك تتويجها لاحقًا بتعاون كامل ، لأنك ستختبر سهولة العمليات والتنظيم والدقة."])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اين توجد أماكن السرفرات؟؟"])},
    "c4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موزعة على عدة مواقع مثل ألمانيا وأمريكا ومع جوجل. أي أنه رابط نسخ احتياطي كامل وآمن وغير قابل للاختراق لتجنب فقدان أي بيانات .."])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الحضور عن طريق السوار أو الهوية؟"])},
    "c5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم ، بالطبع ، كل من بطاقة الهوية والسوار موجودان. إنها شخصية للطالب وتحمل رمز الاستجابة السريعة QR والذي يمكن من خلاله تسجيل الغياب والحضور. نحن نفضل CARD ID حيث أن الهوية المرئية أفضل وسهلة الاستبدال في حالة فقدها من قبل الطالب وبتكلفة أقل ، تتحمل المؤسسة ذلك بالكامل من حيث تسجيل الغياب والحضور. لذلك في سديم الاحتمالية إنه موجود وعليك فقط أن تختار."])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل ستحل المنصة مشاكل الورق والفقد؟"])},
    "c6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن أرشفة المعلومات بشكل كامل ومنهجي وأتمتة عبر المنصة الرجوع إليها في أي وقت وبدقة وبدون ضياع أو فقدان للمعلومات ، وإمكانية سيطرة الإدارة على المؤسسة بشكل كامل من خلال المنصة يمكنك من خلال المنصة متابعة أداء الموظفين و الطلاب وتفاعلهم على المنصة."])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم عدد الأشخاص الذين يحتاج مجلس الإدارة لإدارتهم؟"])},
    "c7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شخص واحد فقط ، مشغل إدخال البيانات ، قادر على إدارة نعومة لوحة العدادات بشكل كامل."])}
  },
  "r": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتدريب الموظفين الكامل وإدخال البيانات؟"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن مسؤولون مسؤولية كاملة عن تدريب إدخال البيانات ، وجميع الموظفين حتى التأكيد من إتقان كامل للعمل على المنصة ، وقد يكون التدريب شخصيًا أو عبر الإنترنت عبر برنامج Zoom. بالإضافة إلى وجود مقاطع فيديو إرشادية توضح الميزات وكيفية استخدامها."])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هناك فترة مجانية لاختبار المنصة؟"])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقدم لك فترة تجريبية مجانية تصل إلى شهرين ، للتأكد من جميع الميزات والخدمات التي نقدمها ، وللتحقق من مدى استفادة مؤسستك من منصتنا بحيث يعتمد قرارك على دراسة كاملة و نتائج واقعية .."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالنسبة للدعم الفني ، كم هو متوفر؟"])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في شركتنا ، نقدم فرق الدعم الفني المتوفرة على مدار الساعة وعلى مدار العام وبإستجابة سريعة ، فهي موجودة لمساعدة الموظفين وأولياء الأمور في حالة مواجهة أي مشكلة في كيفية التعامل مع المنصة."])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هناك إمكانية لإضافة بعض الميزات؟"])},
    "c4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالطبع فريقنا الفني مع مبرمجيه قادر على إضافة أي ميزة أو خدمة إضافية للمنصة مع ما تراه المؤسسة مطلوبًا وضروريًا لعملها والاستفادة من منصتنا على أكمل وجه"])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في حال وجود أكثر من طالب من عائلة؟"])},
    "c5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم بالطبع شركتنا قادرة على بناء منصة لمؤسستك ، بخوادم خاصة ومنفصلة ولكن بتكلفة أعلى."])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل من الممكن إنشاء منصة خاصة بالمدرسة؟"])},
    "c6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يحتاج كل طالب إلى مستخدم خاص به ، لكن من الممكن فتح أكثر من حساب على نفس الهاتف المحمول."])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك التحكم بشكل كامل من لوحة القيادة؟"])},
    "c7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم بالطبع بالإمكان التحكم في حسابات وصلاحيات مستخدم الحساب. وتجميدها وأجزاء أخرى من اللوح بالكامل حسب رغبة الإدارة أو حسب الطلب."])}
  },
  "contact": {
    "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل"])},
    "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بريد إلكتروني"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعم مخصص الشركات"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع"])},
    "i": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متواجدون في الدول التالية: "])},
    "v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإمارات - لبنان - سوريا - العراق - مصر"])},
    "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعم مخصص لاقراد"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التميز في خدمة العملاء"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نقدم أفضل رعاية ، ونحسِّن الكفاءة ونحافظ على تحديث الحلول"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم انسيابي"])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصميم الاحترافي والمبتكر لمنتجاتنا هو الخطوة الأولى لجعلها مناسبة لجميع بيئات العمل واستخدامها بأكثر الطرق جاذبية."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقنية عالية الجودة"])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نحب ما نقوم به ، ونؤمن بتزويد عملائنا بخدمة عالية الجودة وبأسعار تنافسية"])}
  },
  "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشورات الاخيرة"])},
  "ShowMorebtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المزيد"])},
  "moreinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مزيد من المعلومات"])},
  "ReadMorebtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قراءة المزيد"])},
  "TryMorebtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جربه الان"])},
  "getStartbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبدا الأن"])},
  "contacusbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجربة مجانية"])},
  "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لأننا الأفضل ، لا شيء يقارن بتجربتك معنا"])},
  "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمزيد من التفاصيل يمكنكم التواصل معنا من خلال الارقام"])},
  "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم لعائلتنا"])},
  "t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مركز الدعم الفني"])},
  "ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت من عملاء السديم ، يمكنك طلب المساعدة مباشرة من فريق الدعم فيما يتعلق بالمشكلات الفنية والتكنولوجية ، واحصل على الدعم الذي تحتاجه مباشرة من فريقنا المتوفر على مدار الساعة لمساعدتك والإجابة على استفساراتك."])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعم مخصص: +963967594390"])},
  "ss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعم الشركات: +9647810003954"])},
  "nestedd": {
    "subtittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعمل على إدارة المؤسسة التعليمة وتقديم أفضل الخدمات للمؤسسة مما تجعل لك إدارة مبسطة"])}
  },
  "offline-ready": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("appName")), " is ready to work offline"])},
  "need-refresh": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A new version of ", _interpolate(_named("appName")), " is available, click on reload button to update."])},
  "reload-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
  "close-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال متابعتنا للمؤسسات التعليمية وجدنا حلولاً لجميع المشاكل التي قد تواجهها المؤسسات ، حيث أنها لن تحتاج إلى منصات متعددة وتشتت سير العمل الإداري في المنصات الخارجية ، ومن خلال سديم قمنا بتوفير كل شيء من منصة واحدة"])},
  "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذا سديم مهم لمؤسستك"])},
  "m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة تحكم إدارية"])},
  "m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام يعمل على تقديم خدمات عالية الجودة لإدارة المنظمة وتحويل نظامها الورقي التقليدي إلى نظام إلكتروني لسهولة التحكم."])},
  "m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إدارة الأدوار  "])},
  "m4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الطلاب     "])},
  "m5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة شؤون الموظفين"])},
  "m6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإدارة المالية"])},
  "m7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة المخزن"])},
  "m8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إكتشف أكثر"])}
}