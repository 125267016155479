<template>
  <div class="engz">
    <WaitApp :title="title" :description="description"/>
    <SoonApp/>
  </div>
</template>

<script>
import WaitApp from "@/components/sections/Wait";
import SoonApp from "@/components/sections/Soon";
export default {
  name: "EngzView",
  components: {SoonApp, WaitApp},
  data() {
    return {
      title: 'Engz',description:"Soon the service will be launched, wait for us."
    }
  }

}
</script>

<style scoped>

</style>