<template>
  <!-- ***** FAQ Area Start ***** -->
  <section id="faq">
    <div class="container">
      <div class="row">
        <div class="col-md-12" data-aos="fade-up">
          <!-- Heading Text  -->
          <div class="section-heading text-center">
            <h2>FREQUENTLY ASKED QUESTIONS</h2>
            <div class="line-title-center"></div>
            <p>
              Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor coli incididunt ut labore Lorem ipsum madolor sit
              amet, consectetur adipisicing incididunt.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div id="accordion" role="tablist">
            <div class="card" data-aos="fade-up">
              <div class="card-header heading" role="tab">
                <h6 class="mb-0">
                  <a
                    href="#collapse1"
                    data-parent="#accordion"
                    data-toggle="collapse"
                  >
                    1. Sedeiusmod tempor inccsetetur aliquatraiy?
                    <i class="icon-arrow-down"></i>
                  </a>
                </h6>
              </div>

              <div id="collapse1" class="collapse show">
                <div class="card-body">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum
                  architecto aut, recusandae totam ullam aperiam. Nesciunt
                  expedita officiis animi quam corporis optio inventore facilis
                  sint et nulla in, repellat debitis dolor at nisi quo, unde
                  temporibus. Quos nisi nostrum officia, illo.
                </div>
              </div>
            </div>

            <div class="card" data-aos="fade-up">
              <div class="card-header heading" role="tab">
                <h6 class="mb-0">
                  <a
                    href="#collapse2"
                    data-parent="#accordion"
                    data-toggle="collapse"
                  >
                    2. Lorem ipsum dolor, consectetur pisicing elit?
                    <i class="icon-arrow-down"></i>
                  </a>
                </h6>
              </div>

              <div id="collapse2" class="collapse">
                <div class="card-body">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum
                  architecto aut, recusandae totam ullam aperiam. Nesciunt
                  expedita officiis animi quam corporis optio inventore facilis
                  sint et nulla in, repellat debitis dolor at nisi quo, unde
                  temporibus. Quos nisi nostrum officia, illo.
                </div>
              </div>
            </div>

            <div class="card" data-aos="fade-up">
              <div class="card-header heading" role="tab">
                <h6 class="mb-0">
                  <a
                    href="#collapse3"
                    data-parent="#accordion"
                    data-toggle="collapse"
                  >
                    3. Tempor inccsetetur aliquatraiy?
                    <i class="icon-arrow-down"></i>
                  </a>
                </h6>
              </div>

              <div id="collapse3" class="collapse">
                <div class="card-body">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum
                  architecto aut, recusandae totam ullam aperiam. Nesciunt
                  expedita officiis animi quam corporis optio inventore facilis
                  sint et nulla in, repellat debitis dolor at nisi quo, unde
                  temporibus. Quos nisi nostrum officia, illo.
                </div>
              </div>
            </div>

            <div class="card" data-aos="fade-up">
              <div class="card-header heading" role="tab">
                <h6 class="mb-0">
                  <a
                    href="#collapse4"
                    data-parent="#accordion"
                    data-toggle="collapse"
                  >
                    4. Consectetur adipisicing elit,eiusmod tempor?
                    <i class="icon-arrow-down"></i>
                  </a>
                </h6>
              </div>

              <div id="collapse4" class="collapse">
                <div class="card-body">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum
                  architecto aut, recusandae totam ullam aperiam. Nesciunt
                  expedita officiis animi quam corporis optio inventore facilis
                  sint et nulla in, repellat debitis dolor at nisi quo, unde
                  temporibus. Quos nisi nostrum officia, illo.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 ml-auto" data-aos="fade-up" data-aos-delay="300">
          <div class="faq-img">
            <img src="@/assets/images/bg-img/app2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ***** FAQ Area End ***** -->
</template>

<script>
export default {
  name: "FAQApp",
};
</script>

<style scoped>
#faq {
  background-color: var(--section-color);
}
#faq .card-header {
  background-color: var(--section-color2);
}
#faq .card-body {
  background-color: var(--section-color2);
}

#faq a {
  color: #726a84;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#faq a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: #00c2a5;
}

#faq .card {
  margin-bottom: 30px;
  border-width: 0 0 0 0;
  border-inline-start-width: 3px;
  border-style: solid;
  -webkit-border-image: -webkit-linear-gradient(
      #00c5a5,
      rgba(0, 120, 192, 0.93)
    )
    1 100%;
  -o-border-image: -o-linear-gradient(#00c5a5, rgba(0, 120, 192, 0.93)) 1 100%;
  border-image: linear-gradient(#00c5a5, rgba(0, 120, 192, 0.93)) 1 100%;
}
#faq .card:lang(ar) {
  margin-bottom: 30px;
  border-width: 0 0 0 0;
  border-inline-start-width: 3px;
  border-style: solid;
  -webkit-border-image: -webkit-linear-gradient(
      #00c5a5,
      rgba(0, 120, 192, 0.93)
    )
    1 100%;
  -o-border-image: -o-linear-gradient(#00c5a5, rgba(0, 120, 192, 0.93)) 1 100%;
  border-image: linear-gradient(#00c5a5, rgba(0, 120, 192, 0.93)) 1 100%;
}
#faq .card i {
  float: right;
}

#faq .card i:lang(ar) {
  float: left;
}

.faq-img {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .card-header.heading {
    min-height: 70px;
    background: #f00;
  }
}
</style>
