<template>
  <!--================Feature Area =================-->
  <section class="feature_area purple_feature" id="feature">
    <div class="container">
      <div class="main_title purple_title">
        <h2 class="title-feature">Amazing Features</h2>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="feature_mobile">
            <img src="@/assets/images/feature-m-slider/feature-m-slider-shap.png" alt="">
            <swiper
                :centeredSlides="true"
                :loop="true"
                :autoplay="true"
                :slidesPerView="1"
                :spaceBetween="25"
                :modules="modules"
                class="feature_mobile_slider"
            >

              <swiper-slide class="item">
                <img src="@/assets/images/feature-m-slider/feature-m-slider-5.jpg">
              </swiper-slide>
              <swiper-slide class="item"><img src="@/assets/images/feature-m-slider/feature-m-slider-5.jpg"></swiper-slide>
              <swiper-slide class="item"><img src="@/assets/images/feature-m-slider/feature-m-slider-5.jpg"></swiper-slide>

            </swiper>
          </div>
        </div>
        <div class="col-md-4">
          <div class="right_side_feature">
            <div class="media">
              <div class="media-left">
                <i class="fa fa-desktop"></i>
              </div>
              <div class="media-body">
                <a href="#"><h4>Beautiful Design</h4></a>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
              </div>
            </div>
          </div>
          <div class="right_side_feature">
            <div class="media">
              <div class="media-left">
                <i class="fa fa-laptop"></i>
              </div>
              <div class="media-body">
                <a href="#"><h4>Well Documented</h4></a>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
              </div>
            </div>
          </div>
          <div class="right_side_feature">
            <div class="media">
              <div class="media-left">
                <i class="fa fa-film"></i>
              </div>
              <div class="media-body">
                <a href="#"><h4>24/7 Support</h4></a>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="right_side_feature">
            <div class="media">
              <div class="media-left">
                <i class="fa fa-dice"></i>
              </div>
              <div class="media-body">
                <a href="#"><h4>Beautiful Design</h4></a>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
              </div>
            </div>
          </div>
          <div class="right_side_feature">
            <div class="media">
              <div class="media-left">
                <i class="fas fa-mouse-pointer"></i>
              </div>
              <div class="media-body">
                <a href="#"><h4>Well Documented</h4></a>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
              </div>
            </div>
          </div>
          <div class="right_side_feature">
            <div class="media">
              <div class="media-left">
                <i class="fa fa-phone"></i>
              </div>
              <div class="media-body">
                <a href="#"><h4>24/7 Support</h4></a>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--================End Feature Area =================-->
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";


export default {
  name: "FeatureArea",
  components: {
    Swiper,
    SwiperSlide,
  },

}
</script>

<style scoped>
/* Feature area css
============================================================================================ */
.feature_area {
  background: #f1f1f1;
  padding: 50px 0px;
}

.main_title {
  text-align: center;
  padding-bottom: 50px;
}

.title-feature {
  text-align: center;
  padding-bottom: 35px;
  position: relative;
  display: inline-block;
  font-weight: 300;
  color: #636d75;
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;
}

.title-feature::before {
  content: "";
  background: red;
  width: 70px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 10px;
  margin: auto;
}

.title-feature:after {
  content: "";
  background: var(--main-color);
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}

.purple_feature .right_side_feature .media .media-left i {
  color: var(--main-color);
}


.purple_feature .right_side_feature:last-child {
  margin-bottom: 0px;
}

.purple_feature .right_side_feature:first-child {
  margin-top: 75px;
}

.purple_feature .right_side_feature:hover .media .media-body h4 {
  color: var(--main-color);
}

.left_side_feature {
  margin-bottom: 70px;
}

.left_side_feature .media .media-body h4 {
  text-align: right;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #222222;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  padding-bottom: 20px;
}

.left_side_feature .media .media-body p {
  text-align: right;
  font-size: 16px;
  line-height: 28px;
  font-family: "Lato", sans-serif;
  color: #696969;
}

.left_side_feature:hover .media .media-body h4 {
  color: #f8395d;
}

.right_side_feature {
  margin-bottom: 70px;
}

.right_side_feature .media .media-body h4 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #222222;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  padding-bottom: 20px;
}

.right_side_feature .media .media-body p {
  font-size: 16px;
  line-height: 28px;
  font-family: "Lato", sans-serif;
  color: #696969;
}

.right_side_feature .media .media-left {
  padding-right: 15px;
  padding-left: 15px;
}

.right_side_feature .media .media-left i {
  font-size: 48px;
  color: #f8395d;
}

.right_side_feature:last-child {
  margin-bottom: 0px;
}

.right_side_feature:first-child {
  margin-top: 75px;
}

.right_side_feature:hover .media .media-body h4 {
  color: #f8395d;
}

.feature_mobile {
  text-align: center;
  position: relative;
}

.feature_mobile img {
  max-width: 100%;
}

.feature_mobile .feature_mobile_slider {
  height: 438px;
  width: 246px;
  position: absolute;
  left: 49.6%;
  top: 49.8%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

/* End Feature area css
============================================================================================ */

</style>