<template>

  <section id="about1"  >
    <div class="about-chat">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="section-heading text-center"   data-aos="fade-up">
              <h2 class="title-about1">About Chat Loop</h2>
            </div>
          </div>

          <div class="col-md-12 about-box" data-aos="fade-up">
            <div class="row">

              <div class="col-lg-3 col-sm-6 mb-3 about-border">
                  <div class="chat-box">
                    <img src="@/assets/images/about-img/conn.png" />
                    <h3 class="sub-title">Stay Connected</h3>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3 about-border">
                <div class="chat-box">
                  <img src="@/assets/images/about-img/notified.png" />
                  <h3 class="sub-title">Get Notified</h3>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3 about-border">
                <div class="chat-box">
                  <img src="@/assets/images/about-img/updated.png" />
                  <h3 class="sub-title">Stay Updated</h3>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <div class="chat-box">
                  <img src="@/assets/images/about-img/world.png" />
                  <h3 class="sub-title">Mini World</h3>
                </div>
              </div>

            </div>

          </div>

          <div class="col-md-12" data-aos="fade-up">
            <div class="chat-slide">
              <h3> Connect with People all over the world , with your small device </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid text-center" data-aos="fade-up">
      <img class="img-fluid" src="@/assets/images/bg-mobile/banner.png" />

    </div>


  </section>


</template>

<script>
export default {
  name: "About1App"
}
</script>

<style scoped>
#about1{
  padding-top: 50px;
  position: relative;
  overflow: hidden;
}
.title-about1 {
  text-align: center;
  padding-bottom: 35px;
  position: relative;
  display: inline-block;
  font-weight: 300;
  color: #636d75;
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;

}

.title-about1::before {
  content: "";
  background: red;
  width: 70px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 10px;
  margin: auto;
}

.title-about1:after {
  content: "";
  background: var(--main-color);
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}
.chat-slide {
  text-align: center;
  padding-top: 58px;
}
.chat-slide h3 {
  margin-top: 0;
  margin-bottom: 5px;
}

.about-box {
  border-bottom: 1px solid #ddd;
  padding-bottom: 58px;
}
.about-border {
  border-right: 1px solid #dddddd;
  border-left:0;

}
.about-border:lang(ar) {
  border-left: 1px solid #dddddd;
  border-right:0;

}

.chat-box {
  text-align: center;
}
.chat-box img {
  transition: all .5s;
  margin-bottom: 10px;
}
.chat-box:hover img{
  transform:scale(1.1);
  transition:all .5s;
}
.sub-title {
  font-size: 20px;
  margin-bottom: 0;
}
.text-center {
  text-align: center!important;
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.img-fluid{
  max-width: 100%;
  height: auto;
}
</style>