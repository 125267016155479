<script setup lang="ts">
import { defineEmits } from "vue";
import DarkImage from "@/components/layout/megamenu/image/DarkImage.vue";
const emit = defineEmits(["close"]);
</script>

<template>
  <div class="megamenu-inner">
    <button class="close-button" @click="emit('close')">
      <i class="iconify" data-icon="feather:arrow-left"></i>
    </button>
    <div class="columns">
      <div class="column is-4">
        <div class="megamenu-block">
          <div class="media">
            <div class="media-left is-company">
              <span
                class="iconify"
                data-icon="heroicons-solid:office-building"
                data-inline="false"
              ></span>
            </div>
            <div class="media-content">
              <h3>{{ $t("nav.header.company.titel") }}</h3>
              <p>
                {{ $t("nav.header.company.subtitel") }}
              </p>
              <RouterLink to="/about">
                <span>{{ $t("nav.header.company.content") }}</span>
                <i
                  class="iconify"
                  data-icon="feather:arrow-right"
                  data-inline="false"
                ></i>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="megamenu-block ml-6">
          <h4>{{ $t("nav.header.company.PRODUCT") }}</h4>
          <ul>
            <li>
              <RouterLink to="/" class="list-link">
                {{ $t("nav.header.company.Admin") }}
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/" class="list-link">
                {{ $t("nav.header.company.Student") }}
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/" class="list-link">
                {{ $t("nav.header.company.dashboard") }}
              </RouterLink>
            </li>
          </ul>
        </div>
      </div>
      <div class="column is-2">
        <div class="megamenu-block ml-6">
          <h4>{{ $t("nav.header.company.learn.learn1") }}</h4>
          <ul>
            <li>
              <RouterLink to="/contact" class="list-link">
                {{ $t("nav.header.company.learn.learn2") }}
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/" class="list-link">
                {{ $t("nav.header.company.learn.learn3") }}
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/" class="list-link">
                {{ $t("nav.header.company.learn.learn4") }}
              </RouterLink>
            </li>
          </ul>
        </div>
      </div>
      <div class="column is-4 b-hidden-mobile b-hidden-tablet-p">
        <div class="megamenu-block is-left-bordered">
          <div class="image-block">
            <DarkImage
              :src="require('@/assets/images/header2.png')"
              alt="Megamenu image"
              width="500"
              height="309"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
html[dir="rtl"] svg.iconify {
  transform: scaleX(-1) !important;
}
  .megamenu-inner .megamenu-block{


     height: fit-content;
     
    }
  
</style>
