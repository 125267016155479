<template>
  <!-- ***** Our Features Section ***** -->
  <section id="features">
    <div class="container">
      <div class="row" data-aos="fade-up">
        <div class="col-12">
          <!-- Section Heading Area -->
          <div class="section-heading text-center">
            <h2>Advance Features</h2>
            <div class="line-title-center"></div>
            <p>Lorem ipsum dolor sit amet, consectetur Proin gravida nibh vel velit auctor aliquet.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-4 col-sm-12  " data-aos="fade-right" data-aos-delay="300">
        <div class="feature-left text-right">
          <div class="feature-single">
            <h4>Advance Security <i class="icon-lock"></i></h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis rhoncus ante, eget molestie
              dolor. </p>
          </div>
          <div class="feature-single">
            <h4>Retina Ready <i class="icon-eye"></i></h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis rhoncus ante, eget molestie
              dolor.</p>
          </div>
          <div class="feature-single">
            <h4>Easy to Customize <i class="icon-equalizer"></i></h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis rhoncus ante, eget molestie
              dolor.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4 col-sm-12 " data-aos="fade-up" data-aos-delay="900">
      <div class="feature-img">
        <img src="@/assets/images/bg-img/app1.png" alt="Aonomy Feature">
      </div>
    </div>

    <div class="col-12 col-lg-4 col-sm-12  " data-aos="fade-left"   data-aos-delay="300">
    <div class="feature-right">
      <div class="feature-single">
        <h4><i class="icon-rocket"></i> Unlimited Features</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis rhoncus ante, eget molestie dolor.</p>
      </div>
      <div class="feature-single">
        <h4><i class="icon-pencil"></i> Responsive Design</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis rhoncus ante, eget molestie dolor.</p>
      </div>
      <div class="feature-single">
        <h4><i class="icon-anchor"></i> Clean Design</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis rhoncus ante, eget molestie dolor.</p>
      </div>
    </div>
    </div>
    </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeaturesAPP"
}
</script>

<style scoped>
#features{
  background: #F1EFEB;
}
.feature-single{
  margin-top: 30px;
  margin-bottom: 30px;
}

.feature-single h4{
  padding-top: 1rem;
  color: var(--main-color3);
}
.feature-single p{
  color: var(--main-color2);
}

.feature-left .feature-single h4 i{
  float: right;
  margin-left: 15px;
  font-size: 30px;
  color: #43cea2;
}

.feature-right .feature-single h4 i {
  float: left;
  margin-right: 15px;
  font-size: 30px;
  color: #43cea2;
}

.feature-img {
  text-align: center;
  margin-bottom: 30px;
}

/*.feature-single h4:lang(ar){
  padding-top: 1rem;
  color: #636d75;

}
.feature-right .feature-single h4 i:lang(ar) {
  float: right;
  margin-left: 15px;
  font-size: 30px;
  color: #43cea2;
}
.feature-left .feature-single h4 i:lang(ar) {
  float: left;
  margin-right: 15px;
  font-size: 30px;
  color: #43cea2;
}
.feature-left .feature-single h4:lang(ar) {
  text-align: left;
}*/
.feature-right .feature-single h4:lang(ar) {
  text-align: left;
}
.feature-right .feature-single p:lang(ar) {
  text-align: left;
}
#features{background-color: var(--section-color);}
</style>