<template>
  <div>
    <BannerApp/>
    <CriteriaLeft/>
  </div>
</template>

<script>

import BannerApp from "@/components/sections/BannerApp";
import CriteriaLeft from "@/components/sections/CriteriaLeft";
export default {
  name: "AboutView",
  components: {CriteriaLeft, BannerApp},
}
</script>

<style scoped>
/*.maintenance {
  height: 800px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}*/
</style>