<template>
  <!-- Contact Section Start -->
  <section id="contact" class="">
    <div class="container">
      <div class="row justify-content-md-start">
        <div class="col-12"  data-aos="fade-up">
          <!-- Heading Text -->
          <div class="section-heading text-center">
            <h2>Join Us Now</h2>
            <div class="line-title-center-white"></div>
          </div>
        </div>
        <div class="col-12 col-md-6 align-self-center"  data-aos="fade-up">
          <h1 class="title-about "><strong>100% Satisfaction Guaranteed</strong><br></h1>
          <p class="mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam alias optio minima, tempore architecto sint ipsam dolore tempora facere laboriosam corrupti!<br></p>
          <h1 class="title-about mt-5">What will be the next step?<br></h1>
          <p class="mt-2">The pain itself is the love of the main, the adipisic Receive the pwned enhanced, accepted they take care of their necessities by reason, and when they are chosen for the advantageous flight of the happy present. complete or similar he hates to open up to the pains and hardships of the body.<br></p>
        </div>
        <div class="col-12 col-md-6"  data-aos="fade-up">
          <form method="post">
            <div class="input_group"><label class="form-label">Full name</label><input class="form-control form-control-sm" type="text"></div>
            <div class="row input_group">
              <div class="col-md-6"><label class="form-label">Company name</label><input class="form-control form-control-sm" type="text"></div>
              <div class="col-md-6"><label class="form-label">Fonction</label><input class="form-control form-control-sm" type="text"></div>
            </div>
            <div class="row input_group">
              <div class="col-md-6"><label class="form-label">Mail</label><input class="form-control form-control-sm" type="email"></div>
              <div class="col-md-6"><label class="form-label">Phone</label><input class="form-control form-control-sm" type="tel"></div>
            </div>
            <div class="input_group"><label class="form-label">About your project</label><textarea class="form-control"></textarea></div>
            <div class="input-group mt-3">
              <button class="btn btn-light btn-sm" id="btn-contact" type="submit">Start a free meeting<i class="fas fa-paper-plane"></i></button></div>
          </form>
        </div>
      </div>


    </div>
  </section>
  <!-- Contact Section End -->
</template>

<script>
export default {
  name: "ContactApp"
}
</script>

<style scoped>
#contact {
  background-color: #0060AF;
  padding-top: 0px;
  padding-bottom: 0px;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}

.line-title-center-white{
  width: 80px;
  height: 2px;
  background-color: white;
  margin: 15px calc(50% - 40px) 10px;
}

#btn-contact {
  border-radius: 5px;
  color: #0060AF;
}

#btn-contact i {
  margin-left: 5px;
  margin-right: 5px;
  font-size: initial;
}

.input_group {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#contact .form-control {
  opacity: 100%;
  border-width: 1px;
  border-style: solid;
  background-color: #5398d04d;
  border-color: #5398d0;
  color: white;
}

#contact .form-label {
  font-size: 15px;
}

.title-contact {
  font-size: 24px;
}

#contact p {
  font-size: 16px;
  color: white;
}
#contact h2 {
  color: white;
}

#contact .form-control {
  z-index: 2;
}


</style>