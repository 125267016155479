<template>
  <div class="iter">
    <IntroSlider/>
    <BenefitsApp/>
    <CriteriaLeft/>
    <CriteriaRight/>
    <CriteriaLeft/>
    <CriteriaRight/>
    <CriteriaLeft/>
    <CriteriaRight/>
    <ProductIter/>
    <About1App/>
    <FeaturesIter/>
    <WorkIter/>
    <Screenshots2App/>
<!--    <PricingPlans/>-->

    <FeatureArea/>
  <ScreenshotsAreaApp/>
   <PricingApp/>
       <FAQIterApp/>
    <WhyisbestApp/>
  </div>
</template>

<script>
import ScreenshotsAreaApp from "@/components/sections/ScreenshotsArea";
import PricingApp from "@/components/sections/Pricing";
import WhyisbestApp from "@/components/sections/Whyisbest";
import FeatureArea from "@/components/sections/FeatureArea";
import IntroSlider from "@/components/sections/IntroSlider";
import ProductIter from "@/components/sections/ProductIter";
import FAQIterApp from "@/components/sections/FAQIter";
import About1App from "@/components/sections/About1";
import FeaturesIter from "@/components/sections/FeaturesIter";
import WorkIter from "@/components/sections/WorkIter";
import Screenshots2App from "@/components/sections/Screenshots2";
import BenefitsApp from "@/components/sections/Benefits";
import CriteriaLeft from "@/components/sections/CriteriaLeft";
import CriteriaRight from "@/components/sections/CriteriaRight";
/*import PricingPlans from "@/components/sections/PricingPlans";*/
export default {
  name: "IterView",
  components: {
    CriteriaRight,
    CriteriaLeft,
    BenefitsApp,
   /* PricingPlans,*/
    Screenshots2App,
    WorkIter,
    FeaturesIter,
    About1App,
    ProductIter,
    IntroSlider,
    FeatureArea,
    ScreenshotsAreaApp,
    PricingApp,
    FAQIterApp,
    WhyisbestApp,

  },

}
</script>

<style scoped>

</style>