<template>
  <!-- wraper-home-services -->
  <section class="wraper-home-services">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Section Heading Area -->
          <div class="section-heading text-center"   data-aos="fade-up">
            <h2 class="title-products">Products</h2>

            <p class="mt-3"> Lorem ipsum dolor sit amet, consectetur Proin gravida nibh vel velit auctor aliquet.</p>
          </div>

        </div>
      </div>
      <!-- home-services-box -->
      <div class="home-services-box row">

        <!-- home-services-box-item -->
        <div class="home-services-box-item col-12  col-md-4 mx-auto" data-aos="fade-up">
          <div class="holder position-relative h-100 commontext">
            <div class="icon">
              <div class="icon-placeholder">
                <div class="icon-layer layer-one">
                  <img class=" ls-is-cached lazyloaded" src="@/assets/images/svg/New-Web-Dev-Icon.svg" alt="Web Development" width="40" height="40">
                </div>
                <div class="icon-layer layer-two">
                  <img class=" ls-is-cached lazyloaded" src="@/assets/images/svg/New-Web-Dev-Icon.svg" alt="Web Development" width="40" height="40">
                </div>
              </div>
            </div>
            <div class="data">
              <h3 class="h4">Iter Student</h3>
              <p>For 10+ years now, we’ve mastered the top web technologies to deliver projects that have raked in investments. A trusted market leader in developing high performing web fronts for every business.</p>
            </div>
            <div class="more position-absolute">
              <router-link  :to="{ name: 'iter_student'}"   class="btn" >Explore <i class="fa fa-angle-right"></i></router-link>
            </div>
          </div>
        </div>
        <!-- home-services-box-item -->

        <!-- home-services-box-item -->
        <div class="home-services-box-item col-12 col-md-4 mx-auto" data-aos="fade-up">
          <div class="holder position-relative h-100 commontext">
            <div class="icon">
              <div class="icon-placeholder">
                <div class="icon-layer layer-one">
                  <img class=" ls-is-cached lazyloaded" src="@/assets/images/svg/New-App-Dev-Icon.svg" alt="Mobile App Development" width="40" height="40">
                </div>
                <div class="icon-layer layer-two">
                  <img class=" ls-is-cached lazyloaded" src="@/assets/images/svg/New-App-Dev-Icon.svg" alt="Mobile App Development" width="40" height="40">
                </div>
              </div>
            </div>
            <div class="data">
<!--              <h3 class="h4">ITER <br> LEARNING</h3>-->
              <h3 class="h4">ITER Teacher</h3>
              <p>We build mobile apps that are loved by users. Taking the benefits of Artificial intelligence, ML, IoT and other high-end technologies to create app masterpiece that sustains.</p>
            </div>
            <div class="more position-absolute">
              <router-link :to="{ name: 'iter_teacher'}"  class="btn" >Explore <i class="fa fa-angle-right"></i></router-link>
            </div>
          </div>
        </div>
        <!-- home-services-box-item -->



        <!-- home-services-box-item -->
        <div class="home-services-box-item col-12 col-md-4 mx-auto" data-aos="fade-up">
          <div class="holder position-relative h-100 commontext">
            <div class="icon">
              <div class="icon-placeholder">
                <div class="icon-layer layer-one">
                  <img class=" ls-is-cached lazyloaded" src="@/assets/images/svg/New-App-Dev-Icon.svg" alt="Mobile App Development" width="40" height="40">
                </div>
                <div class="icon-layer layer-two">
                  <img class=" ls-is-cached lazyloaded" src="@/assets/images/svg/New-App-Dev-Icon.svg" alt="Mobile App Development" width="40" height="40">
                </div>
              </div>
            </div>
            <div class="data">
              <h3 class="h4">Iter Admin</h3>
              <p>We build mobile apps that are loved by users. Taking the benefits of Artificial intelligence, ML, IoT and other high-end technologies to create app masterpiece that sustains.</p>
            </div>
            <div class="more position-absolute">
              <router-link :to="{ name: 'iter_admin'}"   class="btn">Explore <i class="fa fa-angle-right"></i></router-link>
            </div>
          </div>
        </div>
        <!-- home-services-box-item -->


      </div>

    </div>
  </section>
  <!-- wraper-home-services -->
</template>

<script>
export default {
  name: "ProductIter"
}
</script>

<style scoped>

.wraper-home-services{background-color:#fafafa}
.wraper-home-services>.container{padding-top:5px;padding-bottom:5px}
.wraper-home-services .home-services-box{margin-right:-25px;margin-left:-25px}

.wraper-home-services .home-services-box .home-services-box-item{padding-right:25px;padding-left:25px;margin-bottom:50px}
.wraper-home-services .home-services-box .home-services-box-item>.holder{padding:40px 40px 90px 40px;background-color:#fff;box-shadow:0 0 15px rgba(0,0,0,0.02);-webkit-transition:all 0.4s ease-in-out;transition:all 0.4s ease-in-out}
.wraper-home-services .home-services-box .home-services-box-item>.holder>.icon{margin-bottom:20px}
.wraper-home-services .home-services-box .home-services-box-item>.holder>.icon>.icon-placeholder{position:relative;display:inline-block;vertical-align:top;max-width:40px;height:40px}
.wraper-home-services .home-services-box .home-services-box-item>.holder>.icon>.icon-placeholder>.icon-layer{width:100%;height:100%;-webkit-transition:all 0.4s ease-in-out;transition:all 0.4s ease-in-out}
.wraper-home-services .home-services-box .home-services-box-item>.holder>.icon>.icon-placeholder>.icon-layer.layer-two{position:absolute;top:0;left:0;opacity:0;visibility:hidden;-webkit-transform:scale(0);transform:scale(0)}
.wraper-home-services .home-services-box .home-services-box-item>.holder>.icon>.icon-placeholder>.icon-layer img{width:100%;height:auto}
.wraper-home-services .home-services-box .home-services-box-item>.holder>.data>*:last-child{margin-bottom:0 !important}
.wraper-home-services .home-services-box .home-services-box-item>.holder>.data h4,.wraper-home-services .home-services-box .home-services-box-item>.holder>.data .h4{margin-top:0;margin-bottom:30px;font-family:"CircularStd";font-weight:400;font-size:28px;line-height:1.4}
.wraper-home-services .home-services-box .home-services-box-item>.holder>.data h4 a,.wraper-home-services .home-services-box .home-services-box-item>.holder>.data .h4 a{color:inherit}
.wraper-home-services .home-services-box .home-services-box-item>.holder>.more{

  bottom:40px;
  left:15px;
  right:10px;
}
.wraper-home-services .home-services-box .home-services-box-item>.holder>.more .btn{
  padding:0;text-transform:uppercase;font-weight:700;font-size:12px;line-height:1.4;letter-spacing:4.8px;
  margin: 0;
  border: none;
  box-shadow: none;
}
.wraper-home-services .home-services-box .home-services-box-item>.holder>.more .btn a{color:black;}
.wraper-home-services .home-services-box .home-services-box-item>.holder:hover{box-shadow:0 20px 45px rgba(0,0,0,0.09)}
.wraper-home-services .home-services-box .home-services-box-item>.holder:hover>.icon>.icon-placeholder>.icon-layer.layer-one{opacity:0;visibility:hidden;-webkit-transform:scale(1.5);transform:scale(1.5)}
.wraper-home-services .home-services-box .home-services-box-item>.holder:hover>.icon>.icon-placeholder>.icon-layer.layer-two{opacity:1;visibility:visible;-webkit-transform:scale(1);transform:scale(1)}
.wraper-home-services .home-services-more .btn{position:relative;display:inline-block;vertical-align:middle;padding:13px 33px 14px 33px;background-color:transparent;border:2px solid #000;border-radius:0;text-transform:uppercase;font-weight:700;transition:all 0.3s ease-in-out}
.wraper-home-services .home-services-more .btn:before{position:absolute;top:0;left:0;right:0;z-index:0;content:" ";height:0;background-color:#000;transition:all 0.3s ease-in-out}
.wraper-home-services .home-services-more .btn:hover{color:#fff}
.wraper-home-services .home-services-more .btn:hover:before{top:auto;bottom:0;height:100%}
.wraper-home-services .home-services-more .btn>span{position:relative}
.wraper-home-services a{
  color: #212529;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 4.8px;
}
.more i{
  float: right;
  margin-left: 5px;
}
.more i:lang(ar){
  margin-top: 4px;
  float: left;
  margin-right: 5px;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.title-products {
  text-align: center;
  padding-bottom: 35px;
  position: relative;
  display: inline-block;
  font-weight: 300;
  color: #636d75;
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;

}

.title-products::before {
  content: "";
  background: red;
  width: 70px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 10px;
  margin: auto;
}

.title-products:after {
  content: "";
  background: var(--main-color);
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}
@media (max-width:768px) {
  .wraper-home-services .home-services-box{margin-right:-10px;margin-left:-10px;}
}
</style>