<template>
  <section class="main_banner_area_Wait text-white">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5 col-md-6 mr-md-auto">
          <div class="info">
            <h1 class="display-4 mb-3 bold">{{title}}</h1>
            <p class="mb-5">{{description}}</p>

          </div>
        </div>
        <div class="col-12 col-lg-5 col-md-6">

          <div class=" wow bounceInUp " data-wow-duration="1.7s" data-wow-delay="1s" >
          <div >
            <img src="@/assets/images/wait.png"  alt="image">
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WaitApp",
  props:['title','description']
}
</script>

<style scoped>
.main_banner_area_Wait{
  padding: 100px 0 0;
  min-height: 640px;
  position: relative;
  z-index: 1;
  background-position: bottom center;
  background-size: cover;
}
.main_banner_area_Wait:after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  /* background-color: #f8f9fa!important;*/
  background: #F1EFEB;
  /*  background-image: -linear-gradient(0deg, #43cea2 0%, #185a9d 100%);*/
  opacity: 1;
}

.info{
  padding: 50px 0px;
}

.info > p{
  color: #636d75;
}
.info > h1{
    color: black;
}
</style>