<template>
  <div>
    <BannerApp/>
    <BranchesTreeApp/>
  </div>
</template>

<script>
import BannerApp from "@/components/sections/BannerApp";
import BranchesTreeApp from "@/components/sections/BranchesTreeApp";
export default {
  name: "BranchesView",
  components: {BranchesTreeApp, BannerApp}
}
</script>

<style scoped>

</style>