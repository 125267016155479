<template>
  <section id="EventsBox">
    <section class="blog-page">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="row blog-list">

              <div class="col-lg-6 col-md-12 mb-30" data-aos="fade-up">
                <div class="item news-slid">
                  <a href="#" class="">
                    <div class="news-box">
                      <img src="@/assets/images/events/ev1.jpg" alt="news-1" class="img-fluid">
                    </div>
                  </a>
                  <div class="news-text">
                    <div class="blog-hover"><h4>lorem ipsum dolor sit amet</h4>
                      <ul class="list-inline blog-details-list">
                        <li><a href="#">John Doe</a></li>
                        <li><a href="#">1 Oct</a></li>
                        <li><a href="#">25 comments</a></li>
                        <li><a href="#">3 View</a></li>
                      </ul>
                    </div>
                    <p> lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem
                      ipsum dolor sit amet. </p><a href="#" class="btn-theme">View
                    more</a></div>
                </div>
              </div>

              <div class="col-lg-6 col-md-12 mb-30" data-aos="fade-up">
                <div class="item news-slid">
                  <a href="#" class="">
                    <div class="news-box">
                      <img src="@/assets/images/events/ev2.jpg" alt="news-1" class="img-fluid">
                    </div>
                  </a>
                  <div class="news-text">
                    <div class="blog-hover"><h4>lorem ipsum dolor sit amet</h4>
                      <ul class="list-inline blog-details-list">
                        <li><a href="#">John Doe</a></li>
                        <li><a href="#">2 Oct</a></li>
                        <li><a href="#">25 comments</a></li>
                        <li><a href="#">3 View</a></li>
                      </ul>
                    </div>
                    <p> lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem
                      ipsum dolor sit amet. </p><a href="#" class="btn-theme">View
                    more</a></div>
                </div>
              </div>

              <div class="col-lg-6 col-md-12 mb-30" data-aos="fade-up">
                <div class="item news-slid"><a href="#" class="">
                  <div class="news-box">
                    <img src="@/assets/images/events/ev3.jpg" alt="news-1" class="img-fluid">
                  </div>
                </a>
                  <div class="news-text">
                    <div class="blog-hover"><h4>lorem ipsum dolor sit amet</h4>
                      <ul class="list-inline blog-details-list">
                        <li><a href="#">John Doe</a></li>
                        <li><a href="#">3 Oct</a></li>
                        <li><a href="#">25 comments</a></li>
                        <li><a href="#">3 View</a></li>
                      </ul>
                    </div>
                    <p> lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem
                      ipsum dolor sit amet. </p><a href="#" class="btn-theme">View
                    more</a></div>
                </div>
              </div>

              <div class="col-lg-6 col-md-12 mb-30" data-aos="fade-up">
                <div class="item news-slid"><a href="#" class="">
                  <div class="news-box">
                    <img src="@/assets/images/events/ev4.jpg" alt="news-1" class="img-fluid">
                  </div>
                </a>
                  <div class="news-text">
                    <div class="blog-hover"><h4>lorem ipsum dolor sit amet</h4>
                      <ul class="list-inline blog-details-list">
                        <li><a href="#">John Doe</a></li>
                        <li><a href="#">1 Oct</a></li>
                        <li><a href="#">25 comments</a></li>
                        <li><a href="#">4 View</a></li>
                      </ul>
                    </div>
                    <p> lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem
                      ipsum dolor sit amet. </p>
                    <a href="#" class="btn-theme">View
                      more</a></div>
                </div>
              </div>

              <div class="col-lg-6 col-md-12 mb-30" data-aos="fade-up">
                <div class="item news-slid">
                  <a href="#" class="">
                    <div class="news-box">
                      <img src="@/assets/images/events/ev5.jpg" alt="news-1" class="img-fluid">
                    </div>
                  </a>
                  <div class="news-text">
                    <div class="blog-hover"><h4>lorem ipsum dolor sit amet</h4>
                      <ul class="list-inline blog-details-list">
                        <li><a href="#">John Doe</a></li>
                        <li><a href="#">5 Oct</a></li>
                        <li><a href="#">25 comments</a></li>
                        <li><a href="#">3 View</a></li>
                      </ul>
                    </div>
                    <p> lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem
                      ipsum dolor sit amet. </p>
                    <a href="#" class="btn-theme">View
                      more</a></div>
                </div>
              </div>

              <div class="col-lg-6 col-md-12 mb-30" data-aos="fade-up">
                <div class="item news-slid">
                  <a href="#" class="">
                  <div class="news-box">
                    <img src="@/assets/images/events/ev6.jpg" alt="news-1" class="img-fluid">
                  </div>
                </a>
                  <div class="news-text">
                    <div class="blog-hover"><h4>lorem ipsum dolor sit amet</h4>
                      <ul class="list-inline blog-details-list">
                        <li><a href="#">John Doe</a></li>
                        <li><a href="#">6 Oct</a></li>
                        <li><a href="#">25 comments</a></li>
                        <li><a href="#">3 View</a></li>
                      </ul>
                    </div>
                    <p> lorem ipsum dolor sit amet, consecteturamet adipisicing elit, tempor incididunt ut labore. lorem
                      ipsum dolor sit amet. </p>
                    <a href="#" class="btn-theme">View
                      more</a></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <nav aria-label="Page navigation" class="blog-pagination">
              <ul class="pagination justify-content-center blog-pagin">
                <li class="page-item"><a class="page-link" href="#" aria-label="Previous"><i class="fa fa-angle-left"
                                                                                             aria-hidden="true"></i></a>
                </li>
                <li class="page-item active"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#" aria-label="Next">
                  <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

    </section>
  </section>
</template>

<script>
export default {
  name: "EventsBox"
}
</script>


<style scoped>
#EventsBox {
  padding-top: 30px;
  padding-bottom: 30px;
}

.blog-list, .blog-page {
  margin-bottom: -40px
}

.blog-list .news-slid .blog-hover, .blog-page .news-slid .blog-hover {
  position: relative;
  transform: translateY(0);
  padding: 0
}

.blog-list .news-slid .blog-hover .blog-details-list li, .blog-page .news-slid .blog-hover .blog-details-list li {
  border-color: #586082
}

.blog-list .news-slid .blog-hover .blog-details-list li a, .blog-page .news-slid .blog-hover .blog-details-list li a {
  color: #586082
}

.blog-list .news-slid .blog-hover h4, .blog-page .news-slid .blog-hover h4 {
  color: #5f57ea;
  margin-top: 5px;
  margin-bottom: 10px
}

.blog-list .news-slid .blog-hover ul, .blog-page .news-slid .blog-hover ul {
  margin-bottom: 10px
}

.blog-list .btn-theme, .blog-page .btn-theme {
  padding: 5px 14px;
  font-size: 13px;
  border-radius: 5px;
}

.blog-list > div + div + div .blog-item, .blog-page > div + div + div .blog-item {
  margin-top: 50px
}

.blog-page, .blog-page .blog-details.news-slid {
  margin-bottom: 0
}

.blog-page .animation-circle i {
  background: #eee;
  z-index: -1
}

.blog-page .news-slid .news-box:before {
  display: none
}


.theme-breadcrumb li a {
  color: #5f57ea;
  text-decoration: none
}

.page-item.active .page-link{
  color: white;
  background-image:linear-gradient(90deg, #5f57ea 0, #9647db);
  border-color:#5f57ea
}



.blog-pagination {
  margin-top: 80px
}

.blog-box img {
  opacity: 1;
  transition: .3s ease-in-out;
  overflow: hidden
}

.blog-pagin {
  margin: 0
}

.blog-pagin a.page-link {
  color: #5f57ea;
  font-size: 16px;
  border: 1px solid #eee;
  transition: all .3s ease;
  border-radius: 5px;
  margin: 0 5px;
  padding: .5rem .9rem
}

.blog-pagin a.page-link:hover {
  text-decoration: none;
  background-image: linear-gradient(90deg, #5f57ea 0, #9647db);
  border-color: #9647db;
  color: #fff !important;
  transition: all .3s ease
}


.news-box {
  overflow: hidden;
  position: relative
}

.news-slid .news-box:before {
  content: "";
  height: 100%;
  background: linear-gradient(90deg, #5f57ea 0, #9647db);
  opacity: 0
}

.news-slid .blog-hover, .news-slid .news-box:before {
  position: absolute;
  width: 100%;
  transition: all .3s ease
}

.news-slid .blog-hover {
  z-index: 9;
  bottom: 0;
  left: 0;
  padding: 15px;
  transform: translateY(40px)
}

.news-slid .blog-hover h4 {
  margin-bottom: 15px
}

.news-slid .blog-hover .blog-details-list li {
  display: inline-block
}

.news-slid .blog-hover .blog-details-list li a {
  color: hsla(0, 0%, 100%, .75);
  font-weight: 200
}

.news-slid .blog-hover .blog-details-list li + li {
  padding-left: 7px;
  margin-left: 7px;
  line-height: 1;
  border-left: 1px solid #fff
}

.news-slid:hover img {
  transform: rotate(5deg) scale(1.22);
  transition: all .3s ease
}

.news-slid:hover .blog-hover {
  transform: translateY(0);
  transition: all .3s ease
}

.news-slid:hover .news-box:before {
  opacity: .7;
  transition: all .3s ease;
  z-index: 9;
  left: 0
}

.news-slid img {
  transition: all .3s ease
}

.blog-details .news-text {
  padding: 30px 0 20px
}

.blog-details .news-text .btn-theme {
  margin-bottom: 40px;
  margin-top: 25px
}

.blog-details .news-text h4 {
  margin-top: 20px
}

.news-text {
  padding-top: 15px
}

.news-text h5 {
  color: #999;
  font-size: 13px;
  margin-top: 0;
  line-height: 1
}

.news-slid .btn-theme {
  padding: 5px 14px;
  font-size: 13px;
  background: #fff;
  border: 1px solid #5f57ea;
  color: #5f57ea !important
}

.news-slider .news-slid {
  margin-bottom: 0
}

.blog-block:hover img {
  transition: all .3s ease
}








@media (max-width: 600px) {
  .blog-page .animation-circle-inverse {
    display: none
  }


  .testimonil-box .media {
    display: block
  }


  .blog-text p {
    margin-top: 0;
    font-size: 14px
  }



  .reply-comment .media img {
    width: 40px
  }

}

@media (max-width: 575px) {


  .process-box img {
    height: 45px
  }

  .process-box h3 {
    margin-top: 15px;
    margin-bottom: 2px
  }






  .blog-details .news-text .btn-theme {
    margin-bottom: 20px
  }

  .news-text .btn-theme {
    padding: 3px 14px;
    font-size: 13px
  }


  .about-border + div + div .chat-box {
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd
  }


  .load i {
    width: 300px;
    height: 300px;
    right: -150px;
    top: 0
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row !important
  }

  .blog-list > div + div .blog-item {
    margin-top: 40px
  }



}

@media (max-width: 480px) {
  .d-flex-1 {
    min-height: calc(100vh - 100px);
    margin-top: -15px
  }



  .req-form {
    font-size: 28px
  }



  .slid-btn img {
    margin-bottom: 15px
  }



  .blog-text p {
    line-height: 20px;
    letter-spacing: 1px
  }

  .breadcrumb-bg h2 {
    font-size: 18px
  }

  .blog-sec .blog-list > div + div .blog-item {
    margin-top: 30px
  }

  .hover-text h3 {
    margin-top: 20px
  }
}

@media (max-width: 420px) {

  .blog-text h3 {
    font-size: 15px
  }


}

@media (max-width: 400px) {

  .mt-35 > div + div {
    margin-top: 50px
  }
}

@media (max-width: 360px) {
  .news-slid .blog-hover {
    padding: 10px
  }


  .blog-text p {
    font-size: 14px
  }

  .blog-text h6 {
    margin-top: 10px
  }

  .blog-text h5, .blog-text h6 {
    font-size: 11px
  }

  .blog-text h3.blog-head {
    font-size: 14px
  }




  .news-text h4 {
    font-size: 16px
  }





}

</style>