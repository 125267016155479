<template>
<div>
  <BannerApp/>
  <section>
    <div class="maintenance">
      <h1 >Maintenance Underway</h1>
    </div>

  </section>
</div>
</template>

<script>
import BannerApp from "@/components/sections/BannerApp";
export default {
  name: "AgentsView",
  components: {BannerApp}
}
</script>

<style scoped>
.maintenance {
  height: 800px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

</style>