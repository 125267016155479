<template>
  <PricingApp/>
</template>

<script>
import PricingApp from "@/components/sections/Pricing";
export default {
  name: "IterPriceView",
  components: {PricingApp}
}
</script>

<style scoped>

</style>