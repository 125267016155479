<script setup lang="ts">
import { defineEmits } from "vue";
const localRtl = localStorage.getItem(`isRtl`);

const emit = defineEmits(["close"]);
</script>

<template>
  <div class="megamenu-inner">
    <button class="close-button" @click="emit('close')">
      <i class="iconify" data-icon="feather:arrow-left"></i>
    </button>
      <div class="columns">
        <div class="column is-4">
          <div class="megamenu-block">
            <div class="media">
              <div class="media-left is-bolt">
                <span
                  class="iconify"
                  data-icon="heroicons-solid:lightning-bolt"
                  data-inline="false"
                ></span>
              </div>
              <div class="media-content">
                <h3>{{ $t("nav.header.production.titel") }}</h3>
                <p>
                  {{ $t("nav.header.production.subtitel") }}
                </p>
                <RouterLink to="/Sadeem/landing-34">
                  <span>{{ $t("nav.header.production.content") }}</span>
                  <i
                    class="iconify fa"
                    :class="
                      localRtl === 'true'
                        ? 'fa-arrow-left mx-1'
                        : 'fa-arrow-right'
                    "
                    data-inline="false"
                  ></i>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="megamenu-block ml-6">
            <h4>{{ $t("nav.header.production.PRODUCT") }}</h4>
            <ul>
              <li>
                <RouterLink to="/Sadeem/Admin" class="list-link">
                  {{ $t("nav.header.production.Admin") }}
                </RouterLink>
              </li>
              <li>
                <RouterLink to="/Sadeem/Student" class="list-link">
                  {{ $t("nav.header.production.Student") }}
                </RouterLink>
              </li>
              <li>
                <RouterLink to="/Sadeem/Checkin" class="list-link">
                  {{ $t("nav.header.production.checkin") }}
                </RouterLink>
              </li>
              <li>
                <RouterLink to="/Sadeem/Dashboard" class="list-link">
                  {{ $t("nav.header.production.dashboard") }}
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>
        <div class="column is-2">
          <div class="megamenu-block ml-6">
            <h4>{{ $t("nav.header.production.learn.learn1") }}</h4>
            <ul>
              <li>
                <RouterLink to="/blog/learnTeacher" class="list-link">
                  {{ $t("nav.header.production.learn.learn2") }}
                </RouterLink>
              </li>
              <li>
                <RouterLink to="/blog/learnStudent" class="list-link">
                  {{ $t("nav.header.production.learn.learn3") }}
                </RouterLink>
              </li>
              <li>
                <RouterLink to="/subpages/Dev" class="list-link">
                  {{ $t("nav.header.production.learn.learn5") }}
                </RouterLink>
              </li>
              <li>
                <RouterLink to="/blog/learnDashboard" class="list-link">
                  {{ $t("nav.header.production.learn.learn4") }}
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>
        <div class="column is-4">
          <div class="megamenu-block is-left-bordered">
            <div class="media">
              <div class="media-content">
                <h3>{{ $t("nav.header.production.about.aboutSadeem") }}</h3>
                <p>
                  {{ $t("nav.header.production.about.content") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<style></style>
