<template>
<section class="news">

  <BannerApp/>
  <BlogListApp/>
</section>
</template>

<script>

import BannerApp from "@/components/sections/BannerApp";
import BlogListApp from "@/components/sections/BlogList";
export default {
  name: "NewsView",
  components: {BlogListApp, BannerApp}
}
</script>

<style scoped>

</style>