<template>
  <div >
    <section class="iter-Student" >
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-5 col-md-6 mr-md-auto pt-50">
              <h1 class="display-4 mb-3 bold">ITER STUDENTS</h1>
              <p class="mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo.</p>

          </div>
          <div class="col-12 col-lg-5 col-md-6">

            <div class="video-my wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.5s" >
            <div class="app-img">
              <img src="@/assets/images/scr-img/app-5.png"  alt="image">
            </div>
            <div class="video-play-btn">
              <a class="video_btn glightbox"><i class="icon-control-play" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
  </div>
  </section>
    <ScreenshotsAreaApp/>
    <SwiperLeftApp :title="title"/>
    <SwiperRightApp :title="title"/>
<!--    <SwiperLeftApp :title="title"/>
    <SwiperRightApp :title="title"/>-->
    <PricingApp/>
    <FAQApp/>
    <WhyisbestApp/>
  </div>
</template>

<script>
import  "glightbox/dist/css/glightbox.css";
import  "glightbox/dist/js/glightbox.js";
import GLightbox from 'glightbox';
import ScreenshotsAreaApp from "@/components/sections/ScreenshotsArea";
import PricingApp from "@/components/sections/Pricing";
import FAQApp from "@/components/sections/FAQ";
import WhyisbestApp from "@/components/sections/Whyisbest";
import SwiperLeftApp from "@/components/sections/SwiperLeft";
import SwiperRightApp from "@/components/sections/SwiperRight";
export default {
  name: "IterStudentView",
  components: {SwiperRightApp, SwiperLeftApp, WhyisbestApp, FAQApp, PricingApp, ScreenshotsAreaApp},
  data() {
    return {
      title: 'ITER Student',
      description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ea iste a doloremque, cumque, debitis eum vel ipsum architecto aut, recusandae totam ullam aperiam. Nesciunt expedita officiis animi quam corporis optio inventore facilis sint et nulla in, repellat debitis dolor at nisi quo, unde temporibus. Quos nisi nostrum officia, illo."
    }
  }
  ,
  mounted(){
    //lightbox settings
    this.lightbox = GLightbox({
      selector: ".glightbox",
      'touchNavigation': true,
      'href': 'https://www.youtube.com/watch?v=DbSPTfPUyoE',
      'type': 'video',
      'source': 'youtube', //vimeo, youtube or local
      'width': 900,
      'autoPlayVideos': 'true',
    });


  }
}
</script>

<style scoped>
.iter-Student{
  background-color: #f8f9fa!important;
  padding-top: 50px;
  padding-bottom: 50px;
}
.video-my {

  position: relative;
  z-index: 1;
  height: 500px;
  width: 100%;
  border-radius: 2px;

}

.main_banner_area .video-my {
  margin-top: 0px;
  position: relative;
  z-index: 1;
  height: 500px;
  width: 100%;
  border-radius: 2px;

}

.video-my:after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  /* background-color: rgba(67, 206, 162, 0.50); */
  opacity: .6;
  border-radius: 30px;
}

.video-my img {
  height: 500px;
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

}
.video-my .video-play-btn a {
  width: 80px;
  height: 80px;
  background-color: #41c9a2;
  color: #fff;
  text-align: center;
  display: block;
  font-size: 36px;
  line-height: 86px;
  padding-left: 5px;
  border-radius: 50%;
  top: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
  cursor: pointer;
}

.video-my .video-play-btn a:hover {
  background-color: #fff;
  color: #41c9a2;
  cursor: pointer;
}



.video-area {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  height: 460px;
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 50px rgba(0, 0, 0, 0.08);
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.08);
}

.video-area:after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-image: -moz-linear-gradient(0deg, rgba(67, 206, 162, 0.95) 0%, rgba(24, 90, 157, 0.9) 100%);
  background-image: -webkit-linear-gradient(0deg, rgba(67, 206, 162, 0.95) 0%, rgba(24, 90, 157, 0.9) 100%);
  background-image: -ms-linear-gradient(0deg, rgba(67, 206, 162, 0.95) 0%, rgba(24, 90, 157, 0.9) 100%);
  background-image: linear-gradient(0deg, rgba(67, 206, 162, 0.95) 0%, rgba(24, 90, 157, 0.9) 100%);
  opacity: .6;
}

.video-area .video-play-btn a {
  width: 80px;
  height: 80px;
  background-color: #fff;
  color: #41c9a2;
  text-align: center;
  display: block;
  font-size: 36px;
  line-height: 86px;
  padding-left: 5px;
  border-radius: 50%;
  top: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
}

.video-area .video-play-btn a:hover {
  background-color: #41c9a2;
  color: #fff;
  cursor: pointer;
}




.box-icon-1 {
  margin-bottom: 0px;
  padding: 25px;
}

.box-icon-top {
  display: flex !important;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

.box-icon-1 i {
  margin-right: 15px;
  font-size: 30px;
  color: #43cea2;
}

.box-icon-1 h4 {
  font-size: 25px;
  font-weight: 600;
  color: #346da7;
}

.box-icon-1 p {
  margin-bottom: 0px;
}
</style>