<template>
  <section class="pricing_plane_area bg-light" id="pricing">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Heading Text  -->
          <div class="section-heading text-center">
            <h2 class="title-pricing">Pricing Plan</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4 col-lg-4">
          <!-- Package Price  -->
          <div class="plan-item">
            <!-- Package Text  -->

            <div class="pricing-detail text-center">
              <h5>Gold Plan</h5>
            </div>
            <div class="package-price text-center">
              <h4><span>$</span> 299</h4>
            </div>
            <div class="package-description">
              <p>Total Users <label class="myFloat">20</label></p>
              <p>Unlimited updates <i class="fas fa-times myFloat"></i></p>
              <p>Free host &amp; domain <i class="fas fa-times myFloat"></i></p>
              <p>24/7 Support <i class="fas fa-check myFloat"></i></p>
              <p>10 Unique Users <i class="fas fa-check myFloat"></i></p>
            </div>
            <!-- Plan Button  -->
            <div class="text-center  pricing-btn">
              <button class="btn btn-outline-primary my-4" type="button">Purchase</button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4">
          <!-- Package Price  -->
          <div class="plan-item">
            <!-- Package Text  -->

            <div class="pricing-detail text-center">
              <h5>Platinum Plan</h5>
            </div>
            <div class="package-price text-center">
              <h4><span>$</span> 299</h4>
            </div>
            <div class="package-description">
              <p>Total Users <label class="myFloat">35</label></p>
              <p>Unlimited updates <i class="fas fa-times myFloat"></i></p>
              <p>Free host &amp; domain <i class="fas fa-check myFloat"></i></p>
              <p>24/7 Support <i class="fas fa-check myFloat"></i></p>
              <p>10 Unique Users <i class="fas fa-check myFloat"></i></p>
            </div>
            <!-- Plan Button  -->
            <div class="text-center pricing-btn">
              <button class="btn btn-outline-primary my-4" type="button">Purchase</button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4">
          <!-- Package Price  -->
          <div class="plan-item">
            <!-- Package Text  -->

            <div class="pricing-detail text-center">
              <h5>Diamond Plan</h5>
            </div>
            <div class="package-price text-center">
              <h4><span>$</span> 299</h4>
            </div>
            <div class="package-description">
              <p>Total Users <label class="myFloat">35</label></p>
              <p>Unlimited updates <i class="fas fa-check myFloat"></i></p>
              <p>Free host &amp; domain <i class="fas fa-check myFloat"></i></p>
              <p>24/7 Support <i class="fas fa-check myFloat"></i></p>
              <p>10 Unique Users <i class="fas fa-check myFloat"></i></p>
            </div>
            <!-- Plan Button  -->
            <div class="text-center pricing-btn">
              <button class="btn btn-outline-primary my-4" type="button">Purchase</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ***** Pricing Plane Area End ***** -->
</template>

<script>
export default {
  name: "PricingApp"
}
</script>

<style scoped>

.pricing-plane-area {
  background: #f5f9fc;
}

.plan-item {
  background: #ffffff;
  border: 1px solid #ebebeb;
  margin-bottom: 30px;
}

.plan-item .package-price h4 {
  font-size: 30px;
  font-weight: bold;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  line-height: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #4bcca7;
}

.plan-item .plan-button > a {
  background: #636d75;
  padding: 10px 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  display: block;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
}

.plan-item .package-description > p {
  margin-bottom: 0;
  line-height: 1;
  padding: 20px 20px;
  border-bottom: 1px solid #ebebeb;
}

.plan-item .package-description > p:first-child {
  border-top: 1px solid #ebebeb;
  padding: 20px 20px;
  font-weight: 700;
}

.plan-item:hover .plan-button > a {
  background-color: white;
  color: #636d75;
}

.pricing-detail{
  background-image:-webkit-linear-gradient(0deg, #43cea2 0%, #185a9d 100%);
  color: white;
  padding : 30px 30px;
}

.pricing-detail > h5{
  font-size: 35px;
}

.pricing-btn button {
  color: #43cea2;
  background: transparent;
  border: 1px solid #43cea2;
  border-radius: 0 !important;
}

.pricing-btn button:hover{
  background-color: #43cea2;
  color: #fff;
  border: 1px solid #43cea2;
  border-radius: 0 !important;
}
.title-pricing {
  text-align: center;
  padding-bottom: 35px;
  position: relative;
  display: inline-block;
  font-weight: 300;
  color: #636d75;
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;

}

.title-pricing::before {
  content: "";
  background: red;
  width: 70px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 10px;
  margin: auto;
}

.title-pricing:after {
  content: "";
  background: var(--main-color);
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}
.myFloat{
  float: right;
}
.myFloat:lang(ar){
  float: left;
}
</style>